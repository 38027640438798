import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/images/admin.jpg'


const _hoisted_1 = { class: "row align-items-center" }
const _hoisted_2 = { class: "col-xl-4 col-lg-5 col-md-6" }
const _hoisted_3 = { class: "header-left-side justify-content-center justify-content-md-start d-flex align-items-center" }
const _hoisted_4 = { class: "col-xl-8 col-lg-7 col-md-6" }
const _hoisted_5 = { class: "header-right-side d-flex align-items-center justify-content-center justify-content-md-end" }
const _hoisted_6 = { class: "dropdown profile-dropdown" }
const _hoisted_7 = {
  class: "dropdown-toggle text-start fs-14 text-black-emphasis d-flex align-items-center p-0 position-relative bg-transparent border-0 transition lh-1",
  type: "button",
  "aria-expanded": "false"
}
const _hoisted_8 = { class: "title d-none d-lg-block ms-10 ms-lg-15" }
const _hoisted_9 = { class: "d-block fw-bold mb-5 mb-md-8" }
const _hoisted_10 = { class: "dropdown profile-dropdown" }
const _hoisted_11 = {
  class: "dropdown-toggle text-start fs-14 text-black-emphasis d-flex align-items-center p-0 position-relative bg-transparent border-0 transition lh-1",
  type: "button",
  "aria-expanded": "false"
}
const _hoisted_12 = { class: "title d-none d-lg-block ms-10 ms-lg-15" }
const _hoisted_13 = { class: "d-block fw-bold mb-5 mb-md-8" }
const _hoisted_14 = { class: "dropdown profile-dropdown" }
const _hoisted_15 = {
  class: "dropdown-toggle text-start fs-14 text-black-emphasis d-flex align-items-center p-0 position-relative bg-transparent border-0 transition lh-1",
  type: "button",
  "aria-expanded": "false"
}
const _hoisted_16 = { class: "title d-none d-lg-block ms-10 ms-lg-15" }
const _hoisted_17 = { class: "d-block fw-bold mb-5 mb-md-8" }
const _hoisted_18 = { class: "dropdown profile-dropdown" }
const _hoisted_19 = {
  class: "dropdown-toggle text-start fs-14 text-black-emphasis d-flex align-items-center p-0 position-relative bg-transparent border-0 transition lh-1",
  type: "button",
  "aria-expanded": "false"
}
const _hoisted_20 = { class: "title d-none d-lg-block ms-10 ms-lg-15" }
const _hoisted_21 = {
  class: "d-block fw-bold mb-5 mb-md-8",
  style: {"font-size":"12px"}
}
const _hoisted_22 = {
  class: "text-body-emphasis fw-semibold fs-13",
  style: {"font-size":"6px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.stateStoreInstance.show==true)
    ? (_openBlock(), _createElementBlock("header", {
        key: 0,
        class: _normalizeClass([
      'header-area bg-white text-center text-md-start pt-15 pb-15 ps-15 pe-15 ps-md-20 pe-md-20 pe-lg-30 transition mb-25 position-fixed',
      { sticky: _ctx.isSticky },
    ]),
        id: "header"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                class: "header-burger-menu transition position-relative lh-1 bg-transparent p-0 border-0",
                id: "header-burger-menu",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.stateStoreInstance.onChange && _ctx.stateStoreInstance.onChange(...args)))
              }, _cache[2] || (_cache[2] = [
                _createElementVNode("i", { class: "flaticon-menu-3" }, null, -1)
              ])),
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.getFile && _ctx.getFile(...args))),
                class: "header-burger-menu transition position-relative lh-1 bg-transparent p-0 border-0"
              }, _cache[3] || (_cache[3] = [
                _createElementVNode("i", { class: "flaticon-idea" }, null, -1)
              ]))
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("button", _hoisted_7, [
                  _cache[5] || (_cache[5] = _createElementVNode("div", {
                    class: "icon text-primary rounded-circle text-center",
                    style: {"border":"1px solid blueviolet","height":"50px","border-radius":"50%","-moz-border-radius":"50%","-webkit-border-radius":"50%","width":"50px","padding-top":"10%"}
                  }, [
                    _createElementVNode("i", {
                      class: "flaticon-money fs-2x",
                      style: {"height":"550px"}
                    })
                  ], -1)),
                  _createElementVNode("span", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.transfert) + " XOF", 1),
                    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-body-emphasis fw-semibold fs-13" }, "SOLDE TRANSFERT", -1))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("button", _hoisted_11, [
                  _cache[7] || (_cache[7] = _createElementVNode("div", {
                    class: "icon text-success rounded-circle text-center",
                    style: {"border":"1px solid green","height":"50px","border-radius":"50%","-moz-border-radius":"50%","-webkit-border-radius":"50%","width":"50px","padding-top":"10%"}
                  }, [
                    _createElementVNode("i", {
                      class: "flaticon-money fs-2x",
                      style: {"height":"550px"}
                    })
                  ], -1)),
                  _createElementVNode("span", _hoisted_12, [
                    _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.collect) + " XOF", 1),
                    _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-body-emphasis fw-semibold fs-13" }, "SOLDE ENCAISSÉ", -1))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("button", _hoisted_15, [
                  _cache[9] || (_cache[9] = _createElementVNode("div", {
                    class: "icon text-danger rounded-circle text-center",
                    style: {"border":"1px solid red","height":"50px","border-radius":"50%","-moz-border-radius":"50%","-webkit-border-radius":"50%","width":"50px","padding-top":"10%"}
                  }, [
                    _createElementVNode("i", {
                      class: "ph-duotone ph-shield-plus",
                      style: {"height":"550px"}
                    })
                  ], -1)),
                  _createElementVNode("span", _hoisted_16, [
                    _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.balance) + " XOF", 1),
                    _cache[8] || (_cache[8] = _createElementVNode("span", { class: "text-body-emphasis fw-semibold fs-13" }, "SOLDE DISPONIBLE", -1))
                  ])
                ])
              ]),
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "dropdown email-dropdown" }, null, -1)),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_router_link, {
                  to: "/account-settings",
                  class: "forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("button", _hoisted_19, [
                      _cache[10] || (_cache[10] = _createElementVNode("img", {
                        src: _imports_0,
                        class: "rounded",
                        width: "44",
                        height: "44",
                        alt: "admin"
                      }, null, -1)),
                      _createElementVNode("span", _hoisted_20, [
                        _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.organization_name), 1),
                        _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.email), 1)
                      ])
                    ])
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}