import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "clearfix"
}
const _hoisted_2 = {
  key: 1,
  class: "card mb-25 border-0 rounded-0 bg-white letter-spacing"
}
const _hoisted_3 = { class: "card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25" }
const _hoisted_4 = { class: "d-sm-flex align-items-center" }
const _hoisted_5 = { class: "d-sm-flex align-items-center" }
const _hoisted_6 = { class: "select-calendar position-relative me-sm-10 ms-sm-15 mt-10 mt-sm-0" }
const _hoisted_7 = { class: "card-body p-15 p-sm-20 p-md-25" }
const _hoisted_8 = { class: "table-responsive" }
const _hoisted_9 = {
  id: "",
  class: "table text-nowrap align-middle mb-0"
}
const _hoisted_10 = { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }
const _hoisted_11 = { class: "d-flex align-items-center" }
const _hoisted_12 = { class: "d-flex align-items-center ms-5 fs-md-15 fs-lg-16" }
const _hoisted_13 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_14 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_15 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_16 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_17 = { class: "shadow-none lh-1 fw-medium" }
const _hoisted_18 = {
  key: 0,
  class: "badge text-outline-success"
}
const _hoisted_19 = {
  key: 1,
  class: "badge text-outline-danger"
}
const _hoisted_20 = {
  key: 2,
  class: "badge text-outline-info"
}
const _hoisted_21 = { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }
const _hoisted_22 = { class: "dropdown" }
const _hoisted_23 = { class: "dropdown-menu" }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = { id: "transactionsTable" }
const _hoisted_26 = {
  id: "",
  class: "table text-nowrap align-middle mb-0"
}
const _hoisted_27 = { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }
const _hoisted_28 = { class: "d-flex align-items-center" }
const _hoisted_29 = { class: "d-flex align-items-center ms-5 fs-md-15 fs-lg-16" }
const _hoisted_30 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_31 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_32 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_33 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_34 = { class: "shadow-none lh-1 fw-medium" }
const _hoisted_35 = { key: 0 }
const _hoisted_36 = { key: 1 }
const _hoisted_37 = { key: 2 }
const _hoisted_38 = {
  key: 0,
  class: "pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center"
}
const _hoisted_39 = {
  key: 0,
  class: "mb-0 text-paragraph"
}
const _hoisted_40 = { class: "fw-bold" }
const _hoisted_41 = { class: "fw-bold" }
const _hoisted_42 = {
  key: 1,
  class: "mb-0 text-paragraph"
}
const _hoisted_43 = { class: "fw-bold" }
const _hoisted_44 = { class: "fw-bold" }
const _hoisted_45 = {
  key: 2,
  class: "mt-15 mt-md-0"
}
const _hoisted_46 = { class: "pagination mb-0" }
const _hoisted_47 = {
  key: 0,
  class: "page-item"
}
const _hoisted_48 = {
  key: 0,
  class: "page-link active",
  href: "#"
}
const _hoisted_49 = ["onClick"]
const _hoisted_50 = {
  key: 1,
  class: "page-item"
}
const _hoisted_51 = {
  key: 3,
  class: "mt-15 mt-md-0"
}
const _hoisted_52 = { class: "pagination mb-0" }
const _hoisted_53 = {
  key: 0,
  class: "page-item"
}
const _hoisted_54 = {
  key: 0,
  class: "page-link active",
  href: "#"
}
const _hoisted_55 = ["onClick"]
const _hoisted_56 = {
  key: 1,
  class: "page-item"
}
const _hoisted_57 = {
  key: 4,
  class: "mt-15 mt-md-0"
}
const _hoisted_58 = { class: "pagination mb-0" }
const _hoisted_59 = {
  key: 0,
  class: "page-item"
}
const _hoisted_60 = {
  key: 0,
  class: "page-link active",
  href: "#"
}
const _hoisted_61 = ["onClick"]
const _hoisted_62 = {
  key: 1,
  class: "page-item"
}
const _hoisted_63 = {
  class: "modal fade",
  id: "sendMoneyModal",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_64 = { class: "modal-dialog modal-lg modal-dialog-centered" }
const _hoisted_65 = { class: "modal-content" }
const _hoisted_66 = { class: "modal-body" }
const _hoisted_67 = {
  key: 0,
  style: {"margin-top":"5%"}
}
const _hoisted_68 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"red"}
}
const _hoisted_69 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"red"}
}
const _hoisted_70 = {
  key: 1,
  style: {"margin-top":"5%"}
}
const _hoisted_71 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"green"}
}
const _hoisted_72 = { class: "form-group mb-15 mb-sm-20 mb-md-25" }
const _hoisted_73 = { class: "row" }
const _hoisted_74 = {
  key: 0,
  class: "col-md-12"
}
const _hoisted_75 = { class: "row" }
const _hoisted_76 = {
  key: 0,
  class: "col-md-6"
}
const _hoisted_77 = ["value"]
const _hoisted_78 = {
  key: 1,
  class: "col-md-6"
}
const _hoisted_79 = { class: "row" }
const _hoisted_80 = { class: "col-md-6" }
const _hoisted_81 = ["value"]
const _hoisted_82 = { class: "col-md-6" }
const _hoisted_83 = { class: "invalid-feedback" }
const _hoisted_84 = { class: "row" }
const _hoisted_85 = { class: "col-md-12" }
const _hoisted_86 = { class: "invalid-feedback" }
const _hoisted_87 = {
  class: "modal fade",
  id: "filterLeadModal",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_88 = { class: "modal-dialog modal-lg modal-dialog-centered" }
const _hoisted_89 = { class: "modal-content" }
const _hoisted_90 = { class: "modal-header" }
const _hoisted_91 = {
  key: 0,
  class: "modal-title fs-5"
}
const _hoisted_92 = {
  key: 1,
  class: "modal-title fs-5"
}
const _hoisted_93 = { class: "modal-body" }
const _hoisted_94 = {
  key: 0,
  style: {"margin-top":"5%"}
}
const _hoisted_95 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"red"}
}
const _hoisted_96 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"red"}
}
const _hoisted_97 = { class: "form-group mb-15 mb-sm-20 mb-md-25" }
const _hoisted_98 = { class: "row" }
const _hoisted_99 = {
  key: 0,
  class: "col-md-12"
}
const _hoisted_100 = { class: "row" }
const _hoisted_101 = {
  key: 0,
  class: "col-md-6"
}
const _hoisted_102 = ["value"]
const _hoisted_103 = {
  key: 1,
  class: "col-md-6"
}
const _hoisted_104 = { class: "row" }
const _hoisted_105 = { class: "col-md-6" }
const _hoisted_106 = ["value"]
const _hoisted_107 = { class: "col-md-6" }
const _hoisted_108 = { class: "row" }
const _hoisted_109 = { class: "col-md-6" }
const _hoisted_110 = { class: "col-md-6" }
const _hoisted_111 = { class: "modal-footer" }
const _hoisted_112 = ["disabled"]
const _hoisted_113 = {
  key: 0,
  class: "spinner-grow spinner-grow-sm",
  role: "status",
  "aria-hidden": "true",
  style: {"color":"#FFF"}
}
const _hoisted_114 = {
  class: "modal fade",
  id: "companyModal",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_115 = { class: "modal-dialog modal-lg modal-dialog-centered" }
const _hoisted_116 = { class: "modal-content" }
const _hoisted_117 = { class: "modal-body" }
const _hoisted_118 = { class: "form-group mb-15 mb-sm-20 mb-md-25" }
const _hoisted_119 = { class: "row" }
const _hoisted_120 = { class: "col-md-6" }
const _hoisted_121 = { class: "col-md-6" }
const _hoisted_122 = { class: "row" }
const _hoisted_123 = { class: "col-md-6" }
const _hoisted_124 = { class: "col-md-6" }
const _hoisted_125 = { class: "row" }
const _hoisted_126 = { class: "col-md-6" }
const _hoisted_127 = { class: "col-md-6" }

import {ref, onMounted,onBeforeMount,reactive,inject } from "vue";
import * as yup from 'yup';
import axios from 'axios';
import flatpickr from "flatpickr";
import html2pdf from "html2pdf.js";
import LeadscontentPdf from "./LeadsContentPdf.vue";
import router from '../../../router/index'
import conf from '../../../../env.json'
import {current_permissions} from '../../../utils/permission';
import { read, utils } from 'xlsx';
import {redirect} from '../../../utils/services';
// import {SERVER} from "../../../utils/const"
// import {export_json_to_excel} "../../../utils/Export2Excel";
declare interface Transaction {
    id: number,
    payoutId: string,
    operator: string,
    contact: string,
    amount: string,    
    transactionDate: string,
    paymentStatus: string
  }

  declare interface Organization {
  id : string,
  code: string,
  name: string,
  description: string,    
  email: string,
  created_at: string
  }

  declare interface Operator {
  id : string,
  code: string,
  mnoName: string,
  status: string,    
  countryCode: string  
  }

const leadPlaceholder = "Select date";

export default /*@__PURE__*/_defineComponent({
  __name: 'TransfertsContent',
  setup(__props) {

const backApi=inject('back');

const errors = ref('');  
const errorId = ref('');  
const success = ref('');
const errorsBis = {'contact':'','amount':''};
const formValues = reactive({    
    contact: "",
    amount:""
  });
const phoneRegExp = /^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const schema = yup.object({     
     contact: yup.string().required('Le numéro de téléphone est requis'),//.matches(phoneRegExp,"Veuillez saisir un numéro de telephone valide").test('len', "Le numero de téléphone doit faire exactement 10 chiffres", val => val.length === 10),     
     amount: yup.number().required('Le montant est requis'),
  });
let data = [];
let beginDate = ref('');
let endDate = ref('');
let today = ref('');
const selectedOrganization = ref('');
const selectedOperator = ref('');
const selectedStatus = ref('');
const selectedCountry = ref('');
const loader = ref(false);
const page = ref(1);
const total = ref(0);
//const pageServ = ref('');
const pagesLeft = ref(0);
const currentPage = ref(0);
const numPages = ref(0);
const gettran = ref(false);
const search = ref(false);
const search1 = ref(false);
const amount = ref(0);
const operator = ref('');
const paymentStatus = ref(''); 
let importArray = [];
const screenSelected = ref(''); 
const btnLoad = ref(true);

    
const transactions =ref(new Array<Transaction>());
const organizations =ref(new Array<Organization>());
const operators =ref(new Array<Operator>());
let operatorsTemp =new Array<Operator>();
const transaction =ref({} as Transaction);

  function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}

function formatDateEn(value,d=0){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate()+d;  
        day = day<10?'0'+day:day;      
        return year+'-'+month+'-'+day;
        
    }
}

function formatDateEnBis(value,d=0){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate()+d;  
        day = day<10?'0'+day:day;      
        return year+'-'+month+'-'+day;
        
    }
}

function handleOrganizationChange(event) {
      selectedOrganization.value = event.target.value;
    }

function handleOperatorChange(event) {
  selectedOperator.value = event.target.value;
}

function handleStatusChange(event) {
  selectedStatus.value = event.target.value;
}

function handleCountryChange(event) {
   selectedCountry.value = event.target.value;
  // operators
  var j = 0;
  var operatorArr = new Array<Operator>();
  while(j<operatorsTemp.length){    
      if(operatorsTemp[j].countryCode == event.target.value){         
          operatorArr.push(operatorsTemp[j]);
      }      
      j++;
  }
   operators.value = operatorArr;
}

function handleAmountChange(event) {    
      let withdrawAmount = event.target.value;
      if(parseFloat(withdrawAmount)<=0){
        formValues.amount=''
      }
}
 

onMounted(() => {
 // loader.value=false; 
//  alert(window.history.back())


  getTransactions();
  getOrganizations();
  getOperators();
  //loader.value=true; 
  setTimeout(function () {
    //loader.value=true; 
        flatpickr("#lead-calendar", {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        defaultDate: "today",
        enableTime: true,
      });
        },1000);

 

  flatpickr("#basic-calendar", {
    dateFormat: "Y-m-d H:i",
  });

});
const token =  localStorage.getItem('login_token');
const loadTransaction = (code)=>{
  
  axios.get(`${backApi}/api/v1/secure/transactions/${code}/payout`,{//SERVER+ //https://test.zivwallet.com/api/countries//dateD=${today}&
        headers: {
            Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
        // users.value = response.data;
        transaction.value = response.data;  //.data.users   
        data  = response.data.transactions;  
        operator.value=  response.data.operator.mnoName;
        amount.value= response.data.amount.toLocaleString();
       
         if(response.data.paymentStatus==='SUCCEEDED'){
          paymentStatus.value = "SUCCESS"
         }else if(response.data.paymentStatus==='PROCESSING'){
          paymentStatus.value = "EN COURS"
         }else{
          paymentStatus.value = "ECHEC"
         }   
         
        loader.value=true;
        
    }).catch((error) => {
      loader.value=true;
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                //window.location.href = data1.proxy+'/';
                //window.location.href = '/';
                router.push('/');
              }   
              
          }
  });
}
const getTransactions = (arg=1) =>{
    loader.value=false;
    gettran.value = true;
    search.value = false;
    search1.value = false;
    
    const todayB =  formatDateEn(new Date()); //'2024/01/01'
    const todayE =  formatDateEn(new Date(),1);   
    //conf.proxy
    axios.get(`${backApi}/api/v1/secure/transactions?page=${arg}&dateD=${todayB}&dateF=${todayE}&operationType=${process.env.VUE_APP_PAYOUT_OPTTYPE}`,{//SERVER+ //https://test.zivwallet.com/api/countries//dateD=${today}&
        headers: {
            Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
        // users.value = response.data;
        transactions.value = response.data.transactions;  //.data.users   
        data  = response.data.transactions;  
        total.value=parseInt(response.data.pagination.total);
        page.value=parseInt(response.data.pagination.page);
        pagesLeft.value=parseInt(response.data.pagination.pagesLeft);
        currentPage.value=parseInt(response.data.pagination.currentPage);
        numPages.value=parseInt(response.data.pagination.numPages);
        
        for (let pas = 0; pas < data.length; pas++) {
          data[pas].operator_name = data[pas].operator.mnoName;
        }    
         //console.log(pagination.value);
        loader.value=true;
        
    }).catch((error) => {
      loader.value=true;
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                //window.location.href = data1.proxy+'/';
                //window.location.href = '/';
                router.push('/');
              }   
              
          }
  });

}
const getOrganizations = () =>{

    axios.get(backApi+'/api/v1/secure/organizations/',{  
      headers: {
           
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {
        // users.value = response.data;
        organizations.value = response.data;  //.data.users          
        console.log(response.data);
        
        
    }).catch((error) => {
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                //window.location.href = '/';
                //router.push('/');
              }   
              
          }
   });
  //  loader.value=true;
}
const getOperators = () =>{

axios.get(backApi+'/api/v1/public/operators/',{  //secure
  headers: {
       
        Authorization: `Bearer ${token}`
  } 
})
.then((response) => {
    // users.value = response.data;
    operatorsTemp = response.data;  //.data.users          
    console.log(response.data);
    
    
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            router.push('/')
            
          }   
          
      }
});
//  loader.value=true;
}

const exportExcel = () =>{
  
  import("../../../utils/Export2Excel").then((excel)=>{
    // const data = transactions.value;
    const Header =["CODE TRANSACTION","OPERATEUR","TELEPHONE","MONTANT","DATE","STATUT"];
    const Fields = ["payoutId","operator_name","contact","amount","transactionDate","paymentStatus"];//mnoName
    // console.log(data);
    const dataForExcel= formatJSon(Fields,data);
    // console.log(dataForExcel);
    excel.export_json_to_excel({     
      header:Header,
      data:dataForExcel,
      sheetName:"Feuil1",
      filename:"transactions",      
      autoWidth: true,
      bookType:"xlsx",
  });
  });
}

function formatJSon(FilterData,JsonData){
  return JsonData.map((v)=>FilterData.map(j=>{
      return v[j];
    }));
}

const exportToPDF = () =>{ 
  
       document.getElementById("transactionsTable").style.display='block';
       const opt ={margin: [1, 0, 0, 0],filename: "transactions.pdf",jsPDF:{ unit: 'in', format: 'letter', orientation: 'portrait' }}
      //landscape//portrait
      
      html2pdf().set(opt).from(document.getElementById("transactionsTable")).toPdf().get('pdf').then(function (pdf) {
      
      window.open(pdf.output('bloburl'), '_blank');
      document.getElementById("transactionsTable").style.display='none';
     });

      
}
//[top, left, bottom, right]
const searchTransaction = (arg=1)=>{
  loader.value=false;
  gettran.value = false;
  search.value = true;
  search1.value = false;
  
  if(beginDate.value===''){
    beginDate.value =  formatDateEn(new Date()); 
  }
   
  if(endDate.value===''){
    endDate.value =  formatDateEn(new Date(),1); 
  }
    const todayE =  formatDateEn(new Date(endDate.value),1);   
  axios.get(`${backApi}/api/v1/secure/transactions?page=${arg}&dateD=${beginDate.value}&dateF=${todayE}&organization=${selectedOrganization.value}&operator=${selectedOperator.value}&paymentStatus=${selectedStatus.value}&operationType=${process.env.VUE_APP_PAYOUT_OPTTYPE}`,{//SERVER+ //https://test.zivwallet.com/api/countries
        headers: {
            Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
        // users.value = response.data;
        transactions.value = response.data.transactions;  //.data.users   
        data  = response.data.transactions;  
        total.value=parseInt(response.data.pagination.total);
        page.value=parseInt(response.data.pagination.page);
        pagesLeft.value=parseInt(response.data.pagination.pagesLeft);
        currentPage.value=parseInt(response.data.pagination.currentPage);
        numPages.value=parseInt(response.data.pagination.numPages);
        for (let pas = 0; pas < data.length; pas++) {
          data[pas].operator_name = data[pas].operator.mnoName;
        }    
        //  console.log(data);
        loader.value=true; 
        
    }).catch((error) => {
      loader.value=true;
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                //window.location.href = data1.proxy+'/';
                //window.location.href = '/';
                router.push('/');
              }   
              
          }
  });
}
const searchTransaction1 = (event,arg=1)=>{ 
  // alert(typeof event ) 
  // alert(event.target.value)
  // alert(arg)  
   if(typeof event.target.value!=='undefined'){
    today.value = event.target.value 
   }  
  
    // alert(today.value)  
    loader.value=false;
    gettran.value = false;
    search.value = false;
    search1.value = true;

    const todayE =  formatDateEn(new Date(today.value),1);   
  axios.get(`${backApi}/api/v1/secure/transactions?page=${arg}&dateD=${today.value}&dateF=${todayE}&operationType=${process.env.VUE_APP_PAYOUT_OPTTYPE}`,{//SERVER+ //https://test.zivwallet.com/api/countries
        headers: {
            Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
        // users.value = response.data;
        transactions.value = response.data.transactions;  //.data.users   
        data  = response.data.transactions;  
        total.value=parseInt(response.data.pagination.total);
        page.value=parseInt(response.data.pagination.page);
        pagesLeft.value=parseInt(response.data.pagination.pagesLeft);
        currentPage.value=parseInt(response.data.pagination.currentPage);
        numPages.value=parseInt(response.data.pagination.numPages);
        for (let pas = 0; pas < data.length; pas++) {
          data[pas].operator_name = data[pas].operator.mnoName;
        }    
        //  console.log(data);
        loader.value=true; 
        
    }).catch((error) => {
      loader.value=true;
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                //window.location.href = data1.proxy+'/';
                //window.location.href = '/';
                router.push('/');
              }   
              
          }
  });
}

const sendMoney =()=>{
try {  
  schema.validateSync(formValues);
  axios.post(`${backApi}/api/v1/secure/transactions/init`,{
    operator:selectedOperator.value,
    amount:formValues.amount,
    mobile:formValues.contact,
    operationType:parseInt(process.env.VUE_APP_PAYOUT_OPTTYPE),
    countryCode:selectedCountry.value,
    successUrl: "https://billing.api/success",
    errorUrl: "https://billing.api/error"
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        if(response.status === 200){      
          //router.go();
          success.value='Transfert réussi'; 
          formValues.amount='';
          formValues.contact='';
          selectedOperator.value='';
          selectedCountry.value=''; 
          //console.log(response);
        }      
        
    }).catch((error) => {
      if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              } 
              
          }
  });
} catch (error) {
        console.error(error);        
         errors.value="une erreur inattendue est apparue"
      
}


}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value='';
        success.value='';
}

const searchTransactionForExport = ()=>{  
   btnLoad.value=false;
  
  if(beginDate.value===''){
    beginDate.value =  formatDateEn(new Date()); 
  }
   
  if(endDate.value===''){
    endDate.value =  formatDateEn(new Date(),1); 
  }
    const todayE =  formatDateEn(new Date(endDate.value),1);   
  axios.get(`${backApi}/api/v1/secure/transactions/list/export?dateD=${beginDate.value}&dateF=${todayE}&organization=${selectedOrganization.value}&operator=${selectedOperator.value}&paymentStatus=${selectedStatus.value}&operationType=${process.env.VUE_APP_PAYOUT_OPTTYPE}`,{//SERVER+ //https://test.zivwallet.com/api/countries
        headers: {
            Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
        // users.value = response.data;
        // transactions.value = response.data.transactions;  //.data.users 
        if(response.status === 200){   
          data  = response.data;  
          // console.log(data)
          
          for (let pas = 0; pas < data.length; pas++) {
            data[pas].operator_name = data[pas].operator.mnoName;
          }  
          exportExcel()  
          //  console.log(data);
          btnLoad.value=true; 
        } 
    }).catch((error) => {
      btnLoad.value=true;
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                //window.location.href = data1.proxy+'/';
                //window.location.href = '/';
                router.push('/');
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;   
              }  
              
          }
  });
}

const showScreen = (screen)=>{ 
  screenSelected.value=screen
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!loader.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[26] || (_cache[26] = [
          _createElementVNode("div", {
            class: "spinner-grow text-danger",
            style: {"width":"3rem","height":"3rem","margin-top":"25vh","margin-left":"42%","margin-bottom":"5vh"},
            role: "status"
          }, [
            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (loader.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "default-outline-btn bg-transparent position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0",
                type: "button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (showScreen('excel'))),
                "data-bs-toggle": "modal",
                "data-bs-target": "#filterLeadModal"
              }, _cache[27] || (_cache[27] = [
                _createTextVNode(" Excel "),
                _createElementVNode("i", { class: "flaticon-file-1 position-relative ms-5 top-2 fs-15" }, null, -1)
              ]))
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_6, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(today) ? (today).value = $event : today = $event)),
                    type: "date",
                    class: "form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16",
                    id: "lead-calendar",
                    placeholder: leadPlaceholder,
                    onChange: searchTransaction1
                  }, null, 544), [
                    [_vModelText, _unref(today)]
                  ])
                ])
              ]),
              _createElementVNode("button", {
                style: {"width":"max-content"},
                type: "button",
                class: "default-btn d-block text-center w-100 border-0 box-shadow fw-medium text-white rounded-1 pt-12 pb-12 ps-25 pe-25",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(redirect)('/multi-transferts')))
              }, " Envoi multiple "),
              _cache[29] || (_cache[29] = _createTextVNode("       ")),
              _cache[30] || (_cache[30] = _createElementVNode("button", {
                type: "button",
                class: "d-block text-center w-100 border-0 box-shadow fw-medium text-white rounded-1 pt-12 pb-12 ps-25 pe-25",
                "data-bs-toggle": "modal",
                "data-bs-target": "#sendMoneyModal",
                style: {"background-color":"#EB9355"}
              }, " Envoi simple ", -1)),
              _createElementVNode("button", {
                class: "dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block mt-10 mt-sm-0",
                type: "button",
                "data-bs-toggle": "modal",
                "data-bs-target": "#filterLeadModal",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (showScreen('search')))
              }, _cache[28] || (_cache[28] = [
                _createElementVNode("i", { class: "flaticon-dots" }, null, -1)
              ]))
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("table", _hoisted_9, [
                _cache[36] || (_cache[36] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                    }, " CODE TRANSACTION "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " OPERATEUR "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " TELEPHONE "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " MONTANT "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " DATE "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " STATUT "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                    })
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(transactions.value, (transaction) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: transaction.payoutId
                    }, [
                      _createElementVNode("th", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _cache[31] || (_cache[31] = _createElementVNode("div", { class: "form-check mb-0" }, [
                            _createElementVNode("input", {
                              class: "form-check-input shadow-none",
                              type: "checkbox"
                            })
                          ], -1)),
                          _createElementVNode("div", _hoisted_12, _toDisplayString(transaction.payoutId), 1)
                        ])
                      ]),
                      _createElementVNode("td", _hoisted_13, _toDisplayString(transaction.operator.mnoName), 1),
                      _createElementVNode("td", _hoisted_14, _toDisplayString(transaction.contact), 1),
                      _createElementVNode("td", _hoisted_15, _toDisplayString(transaction.amount.toLocaleString()), 1),
                      _createElementVNode("td", _hoisted_16, _toDisplayString(formatDateFr(new Date(transaction.transactionDate))), 1),
                      _createElementVNode("td", _hoisted_17, [
                        (transaction.paymentStatus==='SUCCEEDED')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_18, "SUCCESS"))
                          : _createCommentVNode("", true),
                        (transaction.paymentStatus==='FAILED')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_19, "ECHEC"))
                          : _createCommentVNode("", true),
                        (transaction.paymentStatus==='PROCESSING')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_20, "EN COURS"))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("td", _hoisted_21, [
                        _createElementVNode("div", _hoisted_22, [
                          _cache[35] || (_cache[35] = _createElementVNode("button", {
                            class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                            type: "button",
                            "data-bs-toggle": "dropdown",
                            "aria-expanded": "false"
                          }, [
                            _createElementVNode("i", { class: "flaticon-dots" })
                          ], -1)),
                          _createElementVNode("ul", _hoisted_23, [
                            _createElementVNode("li", null, [
                              _createElementVNode("a", {
                                onClick: ($event: any) => (loadTransaction(`${transaction.payoutId}`)),
                                href: "javascript:void(0);",
                                class: "dropdown-item d-flex align-items-center position-relative transition border-0 fw-medium text-black pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0",
                                type: "button",
                                "data-bs-toggle": "modal",
                                "data-bs-target": "#companyModal"
                              }, _cache[32] || (_cache[32] = [
                                _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }, null, -1),
                                _createTextVNode(" Voir")
                              ]), 8, _hoisted_24)
                            ]),
                            _cache[33] || (_cache[33] = _createElementVNode("li", null, [
                              _createElementVNode("a", {
                                target: "_blank",
                                class: "dropdown-item d-flex align-items-center",
                                href: "https://wa.me/message/SV5FPBLDUDCBJ1?text=urlencodedtext"
                              }, [
                                _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                                _createTextVNode(" Réclamation")
                              ])
                            ], -1)),
                            _cache[34] || (_cache[34] = _createElementVNode("li", null, null, -1))
                          ])
                        ])
                      ])
                    ]))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _cache[38] || (_cache[38] = _createElementVNode("h2", {
                  class: "text-center",
                  id: "title"
                }, "Liste des transactions ", -1)),
                _createElementVNode("table", _hoisted_26, [
                  _cache[37] || (_cache[37] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", {
                        scope: "col",
                        class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                      }, " CODE TRANSACTION "),
                      _createElementVNode("th", {
                        scope: "col",
                        class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                      }, " OPERATEUR "),
                      _createElementVNode("th", {
                        scope: "col",
                        class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                      }, " TELEPHONE "),
                      _createElementVNode("th", {
                        scope: "col",
                        class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                      }, " MONTANT "),
                      _createElementVNode("th", {
                        scope: "col",
                        class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                      }, " DATE "),
                      _createElementVNode("th", {
                        scope: "col",
                        class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                      }, " STATUT "),
                      _createElementVNode("th", {
                        scope: "col",
                        class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                      })
                    ])
                  ], -1)),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(transactions.value, (transaction) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: transaction.payoutId
                      }, [
                        _createElementVNode("th", _hoisted_27, [
                          _createElementVNode("div", _hoisted_28, [
                            _createElementVNode("div", _hoisted_29, _toDisplayString(transaction.payoutId), 1)
                          ])
                        ]),
                        _createElementVNode("td", _hoisted_30, _toDisplayString(transaction.operator.mnoName), 1),
                        _createElementVNode("td", _hoisted_31, _toDisplayString(transaction.contact), 1),
                        _createElementVNode("td", _hoisted_32, _toDisplayString(transaction.amount.toLocaleString()), 1),
                        _createElementVNode("td", _hoisted_33, _toDisplayString(formatDateFr(new Date(transaction.transactionDate))), 1),
                        _createElementVNode("td", _hoisted_34, [
                          (transaction.paymentStatus==='SUCCEEDED')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_35, "SUCCESS"))
                            : _createCommentVNode("", true),
                          (transaction.paymentStatus==='FAILED')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_36, "ECHEC"))
                            : _createCommentVNode("", true),
                          (transaction.paymentStatus==='PROCESSING')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_37, "EN COURS"))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ]),
            (total.value>0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                  (total.value>10)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_39, [
                        _cache[39] || (_cache[39] = _createTextVNode(" Showing ")),
                        _createElementVNode("span", _hoisted_40, _toDisplayString(page.value*10), 1),
                        _cache[40] || (_cache[40] = _createTextVNode(" out of ")),
                        _createElementVNode("span", _hoisted_41, _toDisplayString(total.value), 1),
                        _cache[41] || (_cache[41] = _createTextVNode(" resultats "))
                      ]))
                    : _createCommentVNode("", true),
                  (total.value<=10)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_42, [
                        _cache[42] || (_cache[42] = _createTextVNode(" Showing ")),
                        _createElementVNode("span", _hoisted_43, _toDisplayString(total.value), 1),
                        _cache[43] || (_cache[43] = _createTextVNode(" out of ")),
                        _createElementVNode("span", _hoisted_44, _toDisplayString(total.value), 1),
                        _cache[44] || (_cache[44] = _createTextVNode(" resultats "))
                      ]))
                    : _createCommentVNode("", true),
                  (gettran.value==true)
                    ? (_openBlock(), _createElementBlock("nav", _hoisted_45, [
                        _createElementVNode("ul", _hoisted_46, [
                          ((page.value-1)>0)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_47, [
                                _createElementVNode("a", {
                                  class: "page-link",
                                  onClick: _cache[4] || (_cache[4] = ($event: any) => (getTransactions(page.value-1))),
                                  "aria-label": "Previous"
                                }, _cache[45] || (_cache[45] = [
                                  _createElementVNode("i", { class: "flaticon-chevron-1" }, null, -1)
                                ]))
                              ]))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(numPages.value, (numPage) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: numPage,
                              class: "page-item"
                            }, [
                              (numPage==page.value)
                                ? (_openBlock(), _createElementBlock("a", _hoisted_48, _toDisplayString(numPage), 1))
                                : (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    class: "page-link",
                                    onClick: ($event: any) => (getTransactions(numPage))
                                  }, _toDisplayString(numPage), 9, _hoisted_49))
                            ]))
                          }), 128)),
                          ((page.value+1)<=numPages.value)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_50, [
                                _createElementVNode("a", {
                                  class: "page-link",
                                  onClick: _cache[5] || (_cache[5] = ($event: any) => (getTransactions(page.value+1))),
                                  "aria-label": "Next"
                                }, _cache[46] || (_cache[46] = [
                                  _createElementVNode("i", { class: "flaticon-chevron" }, null, -1)
                                ]))
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (search.value==true)
                    ? (_openBlock(), _createElementBlock("nav", _hoisted_51, [
                        _createElementVNode("ul", _hoisted_52, [
                          ((page.value-1)>0)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_53, [
                                _createElementVNode("a", {
                                  class: "page-link",
                                  onClick: _cache[6] || (_cache[6] = ($event: any) => (searchTransaction(page.value-1))),
                                  "aria-label": "Previous"
                                }, _cache[47] || (_cache[47] = [
                                  _createElementVNode("i", { class: "flaticon-chevron-1" }, null, -1)
                                ]))
                              ]))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(numPages.value, (numPage) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: numPage,
                              class: "page-item"
                            }, [
                              (numPage==page.value)
                                ? (_openBlock(), _createElementBlock("a", _hoisted_54, _toDisplayString(numPage), 1))
                                : (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    class: "page-link",
                                    onClick: ($event: any) => (searchTransaction(numPage))
                                  }, _toDisplayString(numPage), 9, _hoisted_55))
                            ]))
                          }), 128)),
                          ((page.value+1)<=numPages.value)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_56, [
                                _createElementVNode("a", {
                                  class: "page-link",
                                  onClick: _cache[7] || (_cache[7] = ($event: any) => (searchTransaction(page.value+1))),
                                  "aria-label": "Next"
                                }, _cache[48] || (_cache[48] = [
                                  _createElementVNode("i", { class: "flaticon-chevron" }, null, -1)
                                ]))
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (search1.value==true)
                    ? (_openBlock(), _createElementBlock("nav", _hoisted_57, [
                        _createElementVNode("ul", _hoisted_58, [
                          ((page.value-1)>0)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_59, [
                                _createElementVNode("a", {
                                  class: "page-link",
                                  onClick: _cache[8] || (_cache[8] = ($event: any) => (searchTransaction1($event,page.value-1))),
                                  "aria-label": "Previous"
                                }, _cache[49] || (_cache[49] = [
                                  _createElementVNode("i", { class: "flaticon-chevron-1" }, null, -1)
                                ]))
                              ]))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(numPages.value, (numPage) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: numPage,
                              class: "page-item"
                            }, [
                              (numPage==page.value)
                                ? (_openBlock(), _createElementBlock("a", _hoisted_60, _toDisplayString(numPage), 1))
                                : (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    class: "page-link",
                                    onClick: ($event: any) => (searchTransaction1($event,numPage))
                                  }, _toDisplayString(numPage), 9, _hoisted_61))
                            ]))
                          }), 128)),
                          ((page.value+1)<=numPages.value)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_62, [
                                _createElementVNode("a", {
                                  class: "page-link",
                                  onClick: _cache[9] || (_cache[9] = ($event: any) => (searchTransaction1($event,page.value+1))),
                                  "aria-label": "Next"
                                }, _cache[50] || (_cache[50] = [
                                  _createElementVNode("i", { class: "flaticon-chevron" }, null, -1)
                                ]))
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_63, [
      _createElementVNode("div", _hoisted_64, [
        _createElementVNode("div", _hoisted_65, [
          _createElementVNode("form", {
            onSubmit: _withModifiers(sendMoney, ["prevent"])
          }, [
            _cache[61] || (_cache[61] = _createElementVNode("div", { class: "modal-header" }, [
              _createElementVNode("h1", { class: "modal-title fs-5" }, "Envoi d'argent"),
              _createElementVNode("button", {
                type: "button",
                class: "btn-close",
                "data-bs-dismiss": "modal",
                "aria-label": "Close"
              })
            ], -1)),
            _createElementVNode("div", _hoisted_66, [
              (errors.value!=='')
                ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                    _createElementVNode("h6", _hoisted_68, _toDisplayString(errorId.value), 1),
                    _createElementVNode("h6", _hoisted_69, _toDisplayString(errors.value), 1)
                  ]))
                : _createCommentVNode("", true),
              (success.value!=='')
                ? (_openBlock(), _createElementBlock("div", _hoisted_70, [
                    _createElementVNode("h6", _hoisted_71, _toDisplayString(success.value), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_72, [
                _createElementVNode("div", _hoisted_73, [
                  (_unref(current_permissions)('USER'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_74, [
                        _cache[52] || (_cache[52] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Pays ", -1)),
                        _createElementVNode("select", {
                          name: "organization",
                          id: "organization",
                          class: "form-select fs-md-15 text-black shadow-none",
                          onChange: handleCountryChange,
                          required: ""
                        }, _cache[51] || (_cache[51] = [
                          _createElementVNode("option", { value: "" }, "Selectionnez...", -1),
                          _createElementVNode("option", { value: "SN" }, "Sénégal", -1),
                          _createElementVNode("option", { value: "CI" }, " Côte d'Ivoire", -1)
                        ]), 32)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_75, [
                  (_unref(current_permissions)('ADMIN'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_76, [
                        _cache[54] || (_cache[54] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Clients ", -1)),
                        _createElementVNode("select", {
                          name: "organization",
                          id: "organization",
                          class: "form-select fs-md-15 text-black shadow-none",
                          onChange: handleOrganizationChange,
                          required: ""
                        }, [
                          _cache[53] || (_cache[53] = _createElementVNode("option", { value: "" }, "Selectionnez...", -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(organizations.value, (organization) => {
                            return (_openBlock(), _createElementBlock("option", {
                              value: organization.code,
                              key: organization.id
                            }, _toDisplayString(organization.name), 9, _hoisted_77))
                          }), 128))
                        ], 32)
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(current_permissions)('ADMIN'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
                        _cache[56] || (_cache[56] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Pays ", -1)),
                        _createElementVNode("select", {
                          name: "organization",
                          id: "organization",
                          class: "form-select fs-md-15 text-black shadow-none",
                          onChange: handleCountryChange,
                          required: ""
                        }, _cache[55] || (_cache[55] = [
                          _createElementVNode("option", { value: "" }, "Selectionnez...", -1),
                          _createElementVNode("option", { value: "SN" }, "Sénégal", -1),
                          _createElementVNode("option", { value: "CI" }, " Côte d'Ivoire", -1)
                        ]), 32)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_79, [
                  _createElementVNode("div", _hoisted_80, [
                    _cache[58] || (_cache[58] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Opérateurs ", -1)),
                    _createElementVNode("select", {
                      name: "operator",
                      id: "operator",
                      class: "form-select fs-md-15 text-black shadow-none",
                      onChange: handleOperatorChange,
                      required: ""
                    }, [
                      _cache[57] || (_cache[57] = _createElementVNode("option", { value: "" }, "Selectionnez...", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(operators.value, (operator) => {
                        return (_openBlock(), _createElementBlock("option", {
                          value: operator.mnoName,
                          key: operator.code
                        }, _toDisplayString(operator.mnoName), 9, _hoisted_81))
                      }), 128))
                    ], 32)
                  ]),
                  _createElementVNode("div", _hoisted_82, [
                    _cache[59] || (_cache[59] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Téléphone ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((formValues.contact) = $event)),
                        type: "text",
                        class: _normalizeClass(["form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16", { 'is-invalid': errorsBis['contact'] }]),
                        id: "contact",
                        placeholder: "ex: 0500457801",
                        onBlur: _cache[11] || (_cache[11] = ($event: any) => (validate('contact'))),
                        onKeyup: _cache[12] || (_cache[12] = ($event: any) => (validate('contact'))),
                        onChange: _cache[13] || (_cache[13] = ($event: any) => (validate('contact')))
                      }, null, 34), [
                        [_vModelText, formValues.contact]
                      ]),
                      _createElementVNode("span", _hoisted_83, _toDisplayString(errorsBis['contact']), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_84, [
                  _createElementVNode("div", _hoisted_85, [
                    _cache[60] || (_cache[60] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Montant ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        onKeyup: handleAmountChange,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((formValues.amount) = $event)),
                        type: "number",
                        class: _normalizeClass(["form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16", { 'is-invalid': errorsBis['amount'] }]),
                        id: "amount",
                        placeholder: "2",
                        onBlur: _cache[15] || (_cache[15] = ($event: any) => (validate('amount')))
                      }, null, 34), [
                        [_vModelText, formValues.amount]
                      ]),
                      _createElementVNode("span", _hoisted_86, _toDisplayString(errorsBis['amount']), 1)
                    ])
                  ])
                ])
              ])
            ]),
            _cache[62] || (_cache[62] = _createElementVNode("div", { class: "modal-footer" }, [
              _createElementVNode("button", {
                type: "submit",
                class: "btn text-white",
                style: {"background-color":"#EB9355"}
              }, [
                _createElementVNode("i", {
                  class: "flaticon-plus position-relative ms-5 fs-12",
                  style: {"color":"#FFF"}
                }),
                _createTextVNode(" Enregister ")
              ])
            ], -1))
          ], 32)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_87, [
      _createElementVNode("div", _hoisted_88, [
        _createElementVNode("div", _hoisted_89, [
          _createElementVNode("div", _hoisted_90, [
            (screenSelected.value=='search')
              ? (_openBlock(), _createElementBlock("h1", _hoisted_91, "Recherche avancé"))
              : _createCommentVNode("", true),
            (screenSelected.value=='excel')
              ? (_openBlock(), _createElementBlock("h1", _hoisted_92, "Exportation des transactions au format excel"))
              : _createCommentVNode("", true),
            _cache[63] || (_cache[63] = _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            }, null, -1))
          ]),
          _createElementVNode("div", _hoisted_93, [
            (errors.value!=='')
              ? (_openBlock(), _createElementBlock("div", _hoisted_94, [
                  _createElementVNode("h6", _hoisted_95, "code: " + _toDisplayString(errorId.value), 1),
                  _createElementVNode("h6", _hoisted_96, _toDisplayString(errors.value), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_97, [
              _createElementVNode("div", _hoisted_98, [
                (_unref(current_permissions)('USER'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_99, [
                      _cache[65] || (_cache[65] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Pays ", -1)),
                      _createElementVNode("select", {
                        name: "organization",
                        id: "organization",
                        class: "form-select fs-md-15 text-black shadow-none",
                        onChange: handleCountryChange,
                        required: ""
                      }, _cache[64] || (_cache[64] = [
                        _createElementVNode("option", { value: "" }, "Selectionnez...", -1),
                        _createElementVNode("option", { value: "SN" }, "Sénégal", -1),
                        _createElementVNode("option", { value: "CI" }, " Côte d'Ivoire", -1)
                      ]), 32)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_100, [
                (_unref(current_permissions)('ADMIN'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_101, [
                      _cache[67] || (_cache[67] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Clients ", -1)),
                      _createElementVNode("select", {
                        name: "organization",
                        id: "organization",
                        class: "form-select fs-md-15 text-black shadow-none",
                        onChange: handleOrganizationChange,
                        required: ""
                      }, [
                        _cache[66] || (_cache[66] = _createElementVNode("option", { value: "" }, "Selectionnez...", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(organizations.value, (organization) => {
                          return (_openBlock(), _createElementBlock("option", {
                            value: organization.code,
                            key: organization.id
                          }, _toDisplayString(organization.name), 9, _hoisted_102))
                        }), 128))
                      ], 32)
                    ]))
                  : _createCommentVNode("", true),
                (_unref(current_permissions)('ADMIN'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_103, [
                      _cache[69] || (_cache[69] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Pays ", -1)),
                      _createElementVNode("select", {
                        name: "organization",
                        id: "organization",
                        class: "form-select fs-md-15 text-black shadow-none",
                        onChange: handleCountryChange,
                        required: ""
                      }, _cache[68] || (_cache[68] = [
                        _createElementVNode("option", { value: "" }, "Selectionnez...", -1),
                        _createElementVNode("option", { value: "SN" }, "Sénégal", -1),
                        _createElementVNode("option", { value: "CI" }, " Côte d'Ivoire", -1)
                      ]), 32)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_104, [
                _createElementVNode("div", _hoisted_105, [
                  _cache[71] || (_cache[71] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Opérateurs ", -1)),
                  _createElementVNode("select", {
                    name: "operator",
                    id: "operator",
                    class: "form-select fs-md-15 text-black shadow-none",
                    onChange: handleOperatorChange,
                    required: ""
                  }, [
                    _cache[70] || (_cache[70] = _createElementVNode("option", { value: "" }, "Selectionnez...", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(operators.value, (operator) => {
                      return (_openBlock(), _createElementBlock("option", {
                        value: operator.id,
                        key: operator.code
                      }, _toDisplayString(operator.mnoName), 9, _hoisted_106))
                    }), 128))
                  ], 32)
                ]),
                _createElementVNode("div", _hoisted_107, [
                  _cache[73] || (_cache[73] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Statuts ", -1)),
                  _createElementVNode("select", {
                    name: "status",
                    id: "status",
                    class: "form-select fs-md-15 text-black shadow-none",
                    onChange: handleStatusChange,
                    required: ""
                  }, _cache[72] || (_cache[72] = [
                    _createElementVNode("option", { value: "" }, "Selectionnez...", -1),
                    _createElementVNode("option", { value: "SUCCEEDED" }, " SUCCESS", -1),
                    _createElementVNode("option", { value: "PROCESSING" }, " EN COURS", -1),
                    _createElementVNode("option", { value: "FAILED" }, " ECHEC", -1)
                  ]), 32)
                ])
              ]),
              _createElementVNode("div", _hoisted_108, [
                _createElementVNode("div", _hoisted_109, [
                  _cache[74] || (_cache[74] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Date debut ", -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (_isRef(beginDate) ? (beginDate).value = $event : beginDate = $event)),
                      type: "text",
                      class: "form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16",
                      id: "lead-calendar",
                      placeholder: leadPlaceholder
                    }, null, 512), [
                      [_vModelText, _unref(beginDate)]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_110, [
                  _cache[75] || (_cache[75] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Date fin ", -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (_isRef(endDate) ? (endDate).value = $event : endDate = $event)),
                      type: "text",
                      class: "form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16",
                      id: "lead-calendar",
                      placeholder: leadPlaceholder
                    }, null, 512), [
                      [_vModelText, _unref(endDate)]
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_111, [
            (screenSelected.value=='search')
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn",
                  style: {"background-color":"#EB9355"},
                  "data-bs-dismiss": "modal",
                  onClick: _cache[18] || (_cache[18] = ($event: any) => (searchTransaction(1)))
                }, _cache[76] || (_cache[76] = [
                  _createElementVNode("i", {
                    class: "flaticon-search-interface-symbol",
                    style: {"color":"#FFF"}
                  }, null, -1)
                ])))
              : _createCommentVNode("", true),
            (screenSelected.value=='excel')
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  disabled: !btnLoad.value,
                  type: "button",
                  class: "btn",
                  style: {"background-color":"#EB9355"},
                  onClick: _cache[19] || (_cache[19] = ($event: any) => (searchTransactionForExport()))
                }, [
                  (!btnLoad.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_113))
                    : _createCommentVNode("", true),
                  _cache[77] || (_cache[77] = _createElementVNode("span", { style: {"color":"#FFF"} }, "Exporter", -1))
                ], 8, _hoisted_112))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_114, [
      _createElementVNode("div", _hoisted_115, [
        _createElementVNode("div", _hoisted_116, [
          _createElementVNode("form", null, [
            _cache[84] || (_cache[84] = _createElementVNode("div", { class: "modal-header" }, [
              _createElementVNode("h1", { class: "modal-title fs-5" }, "Details transaction"),
              _createElementVNode("button", {
                type: "button",
                class: "btn-close",
                "data-bs-dismiss": "modal",
                "aria-label": "Close"
              })
            ], -1)),
            _createElementVNode("div", _hoisted_117, [
              _createElementVNode("div", _hoisted_118, [
                _createElementVNode("div", _hoisted_119, [
                  _createElementVNode("div", _hoisted_120, [
                    _cache[78] || (_cache[78] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " code transaction ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((transaction.value.payoutId) = $event)),
                        type: "text",
                        class: "form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16",
                        readonly: ""
                      }, null, 512), [
                        [_vModelText, transaction.value.payoutId]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_121, [
                    _cache[79] || (_cache[79] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Telephone ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((transaction.value.contact) = $event)),
                        type: "text",
                        class: "form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16",
                        readonly: ""
                      }, null, 512), [
                        [_vModelText, transaction.value.contact]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_122, [
                  _createElementVNode("div", _hoisted_123, [
                    _cache[80] || (_cache[80] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Montant ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((amount).value = $event)),
                        type: "text",
                        class: "form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16",
                        readonly: ""
                      }, null, 512), [
                        [_vModelText, amount.value]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_124, [
                    _cache[81] || (_cache[81] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Date ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((transaction.value.transactionDate) = $event)),
                        type: "text",
                        class: "form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16",
                        readonly: ""
                      }, null, 512), [
                        [_vModelText, transaction.value.transactionDate]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_125, [
                  _createElementVNode("div", _hoisted_126, [
                    _cache[82] || (_cache[82] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Opérateur ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((operator).value = $event)),
                        type: "text",
                        class: "form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16",
                        readonly: ""
                      }, null, 512), [
                        [_vModelText, operator.value]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_127, [
                    _cache[83] || (_cache[83] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Statut ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((paymentStatus).value = $event)),
                        type: "text",
                        class: "form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16",
                        readonly: ""
                      }, null, 512), [
                        [_vModelText, paymentStatus.value]
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})