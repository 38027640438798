import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../assets/images/projects/project-mini1.jpg'
import _imports_1 from '../../../assets/images/user/user1.jpg'
import _imports_2 from '../../../assets/images/user/user2.jpg'
import _imports_3 from '../../../assets/images/projects/project-mini2.jpg'
import _imports_4 from '../../../assets/images/user/user4.jpg'
import _imports_5 from '../../../assets/images/projects/project-mini3.jpg'
import _imports_6 from '../../../assets/images/user/user7.jpg'
import _imports_7 from '../../../assets/images/user/user8.jpg'
import _imports_8 from '../../../assets/images/user/user3.jpg'
import _imports_9 from '../../../assets/images/projects/project-mini4.jpg'
import _imports_10 from '../../../assets/images/projects/project-mini5.jpg'
import _imports_11 from '../../../assets/images/user/user9.jpg'
import _imports_12 from '../../../assets/images/projects/project-mini6.jpg'
import _imports_13 from '../../../assets/images/user/user12.jpg'


const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_2 = { class: "card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-20 p-md-25 p-lg-30" }
const _hoisted_3 = { class: "d-sm-flex align-items-center mt-10 mt-lg-0" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-md-6 col-xxl-6 col-xxxl-4" }
const _hoisted_6 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing project-card" }
const _hoisted_7 = { class: "card-body p-20 p-md-25 p-lg-30" }
const _hoisted_8 = { class: "mb-12 mb-md-15 mb-lg-20 d-flex justify-content-between align-items-center" }
const _hoisted_9 = { class: "title d-flex align-items-center" }
const _hoisted_10 = { class: "ms-15" }
const _hoisted_11 = { class: "mb-0 fs-14 fs-md-16 fs-lg-18 fw-bold mt-8 mt-md-10" }
const _hoisted_12 = { class: "col-md-6 col-xxl-6 col-xxxl-4" }
const _hoisted_13 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing project-card" }
const _hoisted_14 = { class: "card-body p-20 p-md-25 p-lg-30" }
const _hoisted_15 = { class: "mb-12 mb-md-15 mb-lg-20 d-flex justify-content-between align-items-center" }
const _hoisted_16 = { class: "title d-flex align-items-center" }
const _hoisted_17 = { class: "ms-15" }
const _hoisted_18 = { class: "mb-0 fs-14 fs-md-16 fs-lg-18 fw-bold mt-8 mt-md-10" }
const _hoisted_19 = { class: "col-md-6 col-xxl-6 col-xxxl-4" }
const _hoisted_20 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing project-card" }
const _hoisted_21 = { class: "card-body p-20 p-md-25 p-lg-30" }
const _hoisted_22 = { class: "mb-12 mb-md-15 mb-lg-20 d-flex justify-content-between align-items-center" }
const _hoisted_23 = { class: "title d-flex align-items-center" }
const _hoisted_24 = { class: "ms-15" }
const _hoisted_25 = { class: "mb-0 fs-14 fs-md-16 fs-lg-18 fw-bold mt-8 mt-md-10" }
const _hoisted_26 = { class: "col-md-6 col-xxl-6 col-xxxl-4" }
const _hoisted_27 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing project-card" }
const _hoisted_28 = { class: "card-body p-20 p-md-25 p-lg-30" }
const _hoisted_29 = { class: "mb-12 mb-md-15 mb-lg-20 d-flex justify-content-between align-items-center" }
const _hoisted_30 = { class: "title d-flex align-items-center" }
const _hoisted_31 = { class: "ms-15" }
const _hoisted_32 = { class: "mb-0 fs-14 fs-md-16 fs-lg-18 fw-bold mt-8 mt-md-10" }
const _hoisted_33 = { class: "col-md-6 col-xxl-6 col-xxxl-4" }
const _hoisted_34 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing project-card" }
const _hoisted_35 = { class: "card-body p-20 p-md-25 p-lg-30" }
const _hoisted_36 = { class: "mb-12 mb-md-15 mb-lg-20 d-flex justify-content-between align-items-center" }
const _hoisted_37 = { class: "title d-flex align-items-center" }
const _hoisted_38 = { class: "ms-15" }
const _hoisted_39 = { class: "mb-0 fs-14 fs-md-16 fs-lg-18 fw-bold mt-8 mt-md-10" }
const _hoisted_40 = { class: "col-md-6 col-xxl-6 col-xxxl-4" }
const _hoisted_41 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing project-card" }
const _hoisted_42 = { class: "card-body p-20 p-md-25 p-lg-30" }
const _hoisted_43 = { class: "mb-12 mb-md-15 mb-lg-20 d-flex justify-content-between align-items-center" }
const _hoisted_44 = { class: "title d-flex align-items-center" }
const _hoisted_45 = { class: "ms-15" }
const _hoisted_46 = { class: "mb-0 fs-14 fs-md-16 fs-lg-18 fw-bold mt-8 mt-md-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"d-sm-flex align-items-center\"><form class=\"search-box position-relative\"><input type=\"text\" class=\"form-control shadow-none text-black rounded-0 border-0\" placeholder=\"Search project\"><button type=\"submit\" class=\"bg-transparent text-primary transition p-0 border-0\"><i class=\"flaticon-search-interface-symbol\"></i></button></form></div>", 1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_link, {
            to: "/create-new-project",
            class: "default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Add New Project "),
              _createElementVNode("i", { class: "flaticon-plus position-relative ms-5 fs-12" }, null, -1)
            ])),
            _: 1
          }),
          _cache[1] || (_cache[1] = _createStaticVNode("<select class=\"project-select form-select shadow-none fw-semibold rounded-1 mt-10 mt-sm-0 ms-sm-10\"><option selected>All (32)</option><option value=\"1\">In Process</option><option value=\"2\">On Hold</option><option value=\"3\">Completed</option></select><div class=\"dropdown mt-10 mt-sm-0 ms-sm-15\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete </a></li></ul></div>", 2))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _cache[5] || (_cache[5] = _createElementVNode("img", {
                  src: _imports_0,
                  class: "rounded-1",
                  width: "60",
                  alt: "project"
                }, null, -1)),
                _createElementVNode("div", _hoisted_10, [
                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "badge text-outline-primary" }, "In Process", -1)),
                  _createElementVNode("h5", _hoisted_11, [
                    _createVNode(_component_router_link, {
                      to: "/project-details",
                      class: "text-decoration-none text-black"
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" Adlash CRM Dashboard Design ")
                      ])),
                      _: 1
                    })
                  ])
                ])
              ]),
              _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View</a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit</a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete</a></li></ul></div>", 1))
            ]),
            _cache[7] || (_cache[7] = _createStaticVNode("<p class=\"mb-12 mb-md-15 mb-lg-25 text-muted lh-base fs-md-15 fs-lg-16\"> This project combiles all the data and backend information to the frontend and also design should be appealing. </p><ul class=\"info ps-0 mb-15 mb-md-20 mb-lg-30 list-unstyled\"><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\">CLIENT:</span> Solit IT Solution </li><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\">BUDGET:</span> $18,500 USD </li><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold d-flex\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\">CLIENT:</span><div class=\"users-list d-flex align-items-center\"><div class=\"rounded-circle text-center\"><img src=\"" + _imports_1 + "\" class=\"rounded-circle\" alt=\"user\"></div><div class=\"rounded-circle text-center\"><img src=\"" + _imports_2 + "\" class=\"rounded-circle\" alt=\"user\"></div><div class=\"rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-16 fw-semibold\"> P </div></div></li></ul><div class=\"mb-5 d-flex justify-content-between align-items-center\"><span class=\"fs-md-15 text-secondary\">Project Progress:</span><span class=\"text-primary fw-semibold\">38%</span></div>", 3)),
            _cache[8] || (_cache[8] = _createElementVNode("div", {
              class: "progress",
              role: "progressbar",
              "aria-valuenow": "38",
              "aria-valuemin": "0",
              "aria-valuemax": "100"
            }, [
              _createElementVNode("div", {
                class: "progress-bar",
                style: {"width":"38%"}
              })
            ], -1)),
            _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"mt-15 d-sm-flex justify-content-between\"><span class=\"text-muted fs-md-15 d-block\"><i class=\"flaticon-calendar lh-1 me-5 position-relative top-1\"></i> Project Deadline: <span class=\"fw-semibold text-paragraph fs-md-14\">5th Mar, 2023</span></span><span class=\"text-muted fs-md-15 d-block mt-5 mt-sm-0\"><i class=\"flaticon-document lh-1 me-1 position-relative top-2\"></i><span class=\"text-black\">12</span>/35 </span></div>", 1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _cache[12] || (_cache[12] = _createElementVNode("img", {
                  src: _imports_3,
                  class: "rounded-1",
                  width: "60",
                  alt: "project"
                }, null, -1)),
                _createElementVNode("div", _hoisted_17, [
                  _cache[11] || (_cache[11] = _createElementVNode("span", { class: "badge text-outline-danger" }, "On Hold", -1)),
                  _createElementVNode("h5", _hoisted_18, [
                    _createVNode(_component_router_link, {
                      to: "/project-details",
                      class: "text-decoration-none text-black"
                    }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode(" Banko CRM Dashboard Design ")
                      ])),
                      _: 1
                    })
                  ])
                ])
              ]),
              _cache[13] || (_cache[13] = _createStaticVNode("<div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View</a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit</a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete</a></li></ul></div>", 1))
            ]),
            _cache[14] || (_cache[14] = _createStaticVNode("<p class=\"mb-12 mb-md-15 mb-lg-25 text-muted lh-base fs-md-15 fs-lg-16\"> This project combiles all the data and backend information to the frontend and also design should be appealing. </p><ul class=\"info ps-0 mb-15 mb-md-20 mb-lg-30 list-unstyled\"><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\">CLIENT:</span> Medizo Healthcare Center </li><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\">BUDGET:</span> $20,000 USD </li><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold d-flex\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\">CLIENT:</span><div class=\"users-list d-flex align-items-center\"><div class=\"rounded-circle text-center\"><img src=\"" + _imports_4 + "\" class=\"rounded-circle\" alt=\"user\"></div><div class=\"rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-16 fw-semibold\"> T </div></div></li></ul><div class=\"mb-5 d-flex justify-content-between align-items-center\"><span class=\"fs-md-15 text-secondary\">Project Progress:</span><span class=\"text-primary fw-semibold\">80%</span></div>", 3)),
            _cache[15] || (_cache[15] = _createElementVNode("div", {
              class: "progress",
              role: "progressbar",
              "aria-valuenow": "80",
              "aria-valuemin": "0",
              "aria-valuemax": "100"
            }, [
              _createElementVNode("div", {
                class: "progress-bar",
                style: {"width":"80%"}
              })
            ], -1)),
            _cache[16] || (_cache[16] = _createStaticVNode("<div class=\"mt-15 d-sm-flex justify-content-between\"><span class=\"text-muted fs-md-15 d-block\"><i class=\"flaticon-calendar lh-1 me-5 position-relative top-1\"></i> Project Deadline: <span class=\"fw-semibold text-paragraph fs-md-14\">15th Dec, 2023</span></span><span class=\"text-muted fs-md-15 d-block mt-5 mt-sm-0\"><i class=\"flaticon-document lh-1 me-1 position-relative top-2\"></i><span class=\"text-black\">80</span>/100 </span></div>", 1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _cache[19] || (_cache[19] = _createElementVNode("img", {
                  src: _imports_5,
                  class: "rounded-1",
                  width: "60",
                  alt: "project"
                }, null, -1)),
                _createElementVNode("div", _hoisted_24, [
                  _cache[18] || (_cache[18] = _createElementVNode("span", { class: "badge text-outline-success" }, "Completed", -1)),
                  _createElementVNode("h5", _hoisted_25, [
                    _createVNode(_component_router_link, {
                      to: "/project-details",
                      class: "text-decoration-none text-black"
                    }, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createTextVNode(" Adlash Ecommerce Dashboard ")
                      ])),
                      _: 1
                    })
                  ])
                ])
              ]),
              _cache[20] || (_cache[20] = _createStaticVNode("<div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View</a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit</a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete</a></li></ul></div>", 1))
            ]),
            _cache[21] || (_cache[21] = _createStaticVNode("<p class=\"mb-12 mb-md-15 mb-lg-25 text-muted lh-base fs-md-15 fs-lg-16\"> This project combiles all the data and backend information to the frontend and also design should be appealing. </p><ul class=\"info ps-0 mb-15 mb-md-20 mb-lg-30 list-unstyled\"><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\">CLIENT:</span> Ceve Banking Ltd. </li><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\">BUDGET:</span> $8,500 USD </li><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold d-flex\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\">CLIENT:</span><div class=\"users-list d-flex align-items-center\"><div class=\"rounded-circle text-center\"><img src=\"" + _imports_6 + "\" class=\"rounded-circle\" alt=\"user\"></div><div class=\"rounded-circle text-center\"><img src=\"" + _imports_1 + "\" class=\"rounded-circle\" alt=\"user\"></div><div class=\"rounded-circle text-center\"><img src=\"" + _imports_7 + "\" class=\"rounded-circle\" alt=\"user\"></div><div class=\"rounded-circle text-center\"><img src=\"" + _imports_8 + "\" class=\"rounded-circle\" alt=\"user\"></div></div></li></ul><div class=\"mb-5 d-flex justify-content-between align-items-center\"><span class=\"fs-md-15 text-secondary\">Project Progress:</span><span class=\"text-primary fw-semibold\">40%</span></div>", 3)),
            _cache[22] || (_cache[22] = _createElementVNode("div", {
              class: "progress",
              role: "progressbar",
              "aria-valuenow": "40",
              "aria-valuemin": "0",
              "aria-valuemax": "100"
            }, [
              _createElementVNode("div", {
                class: "progress-bar",
                style: {"width":"40%"}
              })
            ], -1)),
            _cache[23] || (_cache[23] = _createStaticVNode("<div class=\"mt-15 d-sm-flex justify-content-between\"><span class=\"text-muted fs-md-15 d-block\"><i class=\"flaticon-calendar lh-1 me-5 position-relative top-1\"></i> Project Deadline: <span class=\"fw-semibold text-paragraph fs-md-14\">23th Jul, 2023</span></span><span class=\"text-muted fs-md-15 d-block mt-5 mt-sm-0\"><i class=\"flaticon-document lh-1 me-1 position-relative top-2\"></i><span class=\"text-black\">50</span>/75 </span></div>", 1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, [
                _cache[26] || (_cache[26] = _createElementVNode("img", {
                  src: _imports_9,
                  class: "rounded-1",
                  width: "60",
                  alt: "project"
                }, null, -1)),
                _createElementVNode("div", _hoisted_31, [
                  _cache[25] || (_cache[25] = _createElementVNode("span", { class: "badge text-outline-primary" }, "In Process", -1)),
                  _createElementVNode("h5", _hoisted_32, [
                    _createVNode(_component_router_link, {
                      to: "/project-details",
                      class: "text-decoration-none text-black"
                    }, {
                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                        _createTextVNode(" Jova Ecommerce Dashboard ")
                      ])),
                      _: 1
                    })
                  ])
                ])
              ]),
              _cache[27] || (_cache[27] = _createStaticVNode("<div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View</a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit</a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete</a></li></ul></div>", 1))
            ]),
            _cache[28] || (_cache[28] = _createStaticVNode("<p class=\"mb-12 mb-md-15 mb-lg-25 text-muted lh-base fs-md-15 fs-lg-16\"> This project combiles all the data and backend information to the frontend and also design should be appealing. </p><ul class=\"info ps-0 mb-15 mb-md-20 mb-lg-30 list-unstyled\"><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\">CLIENT:</span> Jova Ecommerce Site </li><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\">BUDGET:</span> $18,500 USD </li><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold d-flex\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\">CLIENT:</span><div class=\"users-list d-flex align-items-center\"><div class=\"rounded-circle text-center\"><img src=\"" + _imports_1 + "\" class=\"rounded-circle\" alt=\"user\"></div><div class=\"rounded-circle text-center\"><img src=\"" + _imports_2 + "\" class=\"rounded-circle\" alt=\"user\"></div></div></li></ul><div class=\"mb-5 d-flex justify-content-between align-items-center\"><span class=\"fs-md-15 text-secondary\">Project Progress:</span><span class=\"text-primary fw-semibold\">43%</span></div>", 3)),
            _cache[29] || (_cache[29] = _createElementVNode("div", {
              class: "progress",
              role: "progressbar",
              "aria-valuenow": "43",
              "aria-valuemin": "0",
              "aria-valuemax": "100"
            }, [
              _createElementVNode("div", {
                class: "progress-bar",
                style: {"width":"43%"}
              })
            ], -1)),
            _cache[30] || (_cache[30] = _createStaticVNode("<div class=\"mt-15 d-sm-flex justify-content-between\"><span class=\"text-muted fs-md-15 d-block\"><i class=\"flaticon-calendar lh-1 me-5 position-relative top-1\"></i> Project Deadline: <span class=\"fw-semibold text-paragraph fs-md-14\">15th Jun, 2023</span></span><span class=\"text-muted fs-md-15 d-block mt-5 mt-sm-0\"><i class=\"flaticon-document lh-1 me-1 position-relative top-2\"></i><span class=\"text-black\">43</span>/100 </span></div>", 1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_33, [
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("div", _hoisted_37, [
                _cache[33] || (_cache[33] = _createElementVNode("img", {
                  src: _imports_10,
                  class: "rounded-1",
                  width: "60",
                  alt: "project"
                }, null, -1)),
                _createElementVNode("div", _hoisted_38, [
                  _cache[32] || (_cache[32] = _createElementVNode("span", { class: "badge text-outline-success" }, "Completed", -1)),
                  _createElementVNode("h5", _hoisted_39, [
                    _createVNode(_component_router_link, {
                      to: "/project-details",
                      class: "text-decoration-none text-black"
                    }, {
                      default: _withCtx(() => _cache[31] || (_cache[31] = [
                        _createTextVNode(" Jeel CRM Dashboard Design ")
                      ])),
                      _: 1
                    })
                  ])
                ])
              ]),
              _cache[34] || (_cache[34] = _createStaticVNode("<div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View</a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit</a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete</a></li></ul></div>", 1))
            ]),
            _cache[35] || (_cache[35] = _createStaticVNode("<p class=\"mb-12 mb-md-15 mb-lg-25 text-muted lh-base fs-md-15 fs-lg-16\"> This project combiles all the data and backend information to the frontend and also design should be appealing. </p><ul class=\"info ps-0 mb-15 mb-md-20 mb-lg-30 list-unstyled\"><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\">CLIENT:</span> Jeel Cosmetics </li><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\">BUDGET:</span> $1,500 USD </li><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold d-flex\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\">CLIENT:</span><div class=\"users-list d-flex align-items-center\"><div class=\"rounded-circle text-center\"><img src=\"" + _imports_11 + "\" class=\"rounded-circle\" alt=\"user\"></div></div></li></ul><div class=\"mb-5 d-flex justify-content-between align-items-center\"><span class=\"fs-md-15 text-secondary\">Project Progress:</span><span class=\"text-primary fw-semibold\">50%</span></div>", 3)),
            _cache[36] || (_cache[36] = _createElementVNode("div", {
              class: "progress",
              role: "progressbar",
              "aria-valuenow": "50",
              "aria-valuemin": "0",
              "aria-valuemax": "100"
            }, [
              _createElementVNode("div", {
                class: "progress-bar",
                style: {"width":"50%"}
              })
            ], -1)),
            _cache[37] || (_cache[37] = _createStaticVNode("<div class=\"mt-15 d-sm-flex justify-content-between\"><span class=\"text-muted fs-md-15 d-block\"><i class=\"flaticon-calendar lh-1 me-5 position-relative top-1\"></i> Project Deadline: <span class=\"fw-semibold text-paragraph fs-md-14\">15th Mar, 2023</span></span><span class=\"text-muted fs-md-15 d-block mt-5 mt-sm-0\"><i class=\"flaticon-document lh-1 me-1 position-relative top-2\"></i><span class=\"text-black\">25</span>/50 </span></div>", 1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_40, [
        _createElementVNode("div", _hoisted_41, [
          _createElementVNode("div", _hoisted_42, [
            _createElementVNode("div", _hoisted_43, [
              _createElementVNode("div", _hoisted_44, [
                _cache[40] || (_cache[40] = _createElementVNode("img", {
                  src: _imports_12,
                  class: "rounded-1",
                  width: "60",
                  alt: "project"
                }, null, -1)),
                _createElementVNode("div", _hoisted_45, [
                  _cache[39] || (_cache[39] = _createElementVNode("span", { class: "badge text-outline-danger" }, "On Hold", -1)),
                  _createElementVNode("h5", _hoisted_46, [
                    _createVNode(_component_router_link, {
                      to: "/project-details",
                      class: "text-decoration-none text-black"
                    }, {
                      default: _withCtx(() => _cache[38] || (_cache[38] = [
                        _createTextVNode(" Xenax CRM Dashboard Design ")
                      ])),
                      _: 1
                    })
                  ])
                ])
              ]),
              _cache[41] || (_cache[41] = _createStaticVNode("<div class=\"dropdown\"><button class=\"dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8 position-relative top-1\"></i> View</a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8 position-relative top-1\"></i> Edit</a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8 position-relative top-1\"></i> Delete</a></li></ul></div>", 1))
            ]),
            _cache[42] || (_cache[42] = _createStaticVNode("<p class=\"mb-12 mb-md-15 mb-lg-25 text-muted lh-base fs-md-15 fs-lg-16\"> This project combiles all the data and backend information to the frontend and also design should be appealing. </p><ul class=\"info ps-0 mb-15 mb-md-20 mb-lg-30 list-unstyled\"><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\">CLIENT:</span> Xenax Ecommerce </li><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\"> BUDGET: </span> $18,500 USD </li><li class=\"text-secondary fs-md-15 fs-md-16 fw-semibold d-flex\"><span class=\"text-muted fs-12 fs-md-13 text-uppercase fw-medium d-inline-block\"> CLIENT: </span><div class=\"users-list d-flex align-items-center\"><div class=\"rounded-circle text-center\"><img src=\"" + _imports_13 + "\" class=\"rounded-circle\" alt=\"user\"></div><div class=\"rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-16 fw-semibold\"> J </div></div></li></ul><div class=\"mb-5 d-flex justify-content-between align-items-center\"><span class=\"fs-md-15 text-secondary\">Project Progress:</span><span class=\"text-primary fw-semibold\">75%</span></div>", 3)),
            _cache[43] || (_cache[43] = _createElementVNode("div", {
              class: "progress",
              role: "progressbar",
              "aria-valuenow": "75",
              "aria-valuemin": "0",
              "aria-valuemax": "100"
            }, [
              _createElementVNode("div", {
                class: "progress-bar",
                style: {"width":"75%"}
              })
            ], -1)),
            _cache[44] || (_cache[44] = _createStaticVNode("<div class=\"mt-15 d-sm-flex justify-content-between\"><span class=\"text-muted fs-md-15 d-block\"><i class=\"flaticon-calendar lh-1 me-5 position-relative top-1\"></i> Project Deadline: <span class=\"fw-semibold text-paragraph fs-md-14\"> 11th Mar, 2023 </span></span><span class=\"text-muted fs-md-15 d-block mt-5 mt-sm-0\"><i class=\"flaticon-document lh-1 me-1 position-relative top-2\"></i><span class=\"text-black\">75</span>/100 </span></div>", 1))
          ])
        ])
      ]),
      _cache[45] || (_cache[45] = _createStaticVNode("<div class=\"col-12\"><div class=\"pagination-area d-md-flex mb-25 justify-content-between align-items-center\"><p class=\"mb-0 text-paragraph\"> Showing <span class=\"fw-bold\">6</span> out of <span class=\"fw-bold\">13</span> results </p><nav class=\"mt-15 mt-md-0\"><ul class=\"pagination mb-0\"><li class=\"page-item\"><a class=\"page-link\" href=\"#\" aria-label=\"Previous\"><i class=\"flaticon-chevron-1\"></i></a></li><li class=\"page-item\"><a class=\"page-link active\" href=\"#\">1</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"#\">2</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"#\">3</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"#\" aria-label=\"Next\"><i class=\"flaticon-chevron\"></i></a></li></ul></nav></div></div>", 1))
    ])
  ], 64))
}