import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "clearfix"
}
const _hoisted_2 = {
  key: 1,
  class: "row"
}
const _hoisted_3 = { class: "col-xxl-8 order-2 order-xl-1" }
const _hoisted_4 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_5 = { class: "card-body p-10" }
const _hoisted_6 = { class: "settings-tabs ps-0 mb-0 list-unstyled" }
const _hoisted_7 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing settings-card" }
const _hoisted_8 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-md-6" }
const _hoisted_11 = { class: "form-group mb-15 mb-sm-20 mb-md-25" }
const _hoisted_12 = { class: "col-md-6" }
const _hoisted_13 = { class: "form-group mb-15 mb-sm-20 mb-md-25" }
const _hoisted_14 = { class: "col-md-6" }
const _hoisted_15 = { class: "form-group mb-15 mb-sm-20 mb-md-25" }
const _hoisted_16 = { class: "col-md-6" }
const _hoisted_17 = { class: "form-group mb-15 mb-sm-20 mb-md-25" }

import {ref, onMounted,reactive,inject } from "vue";
import * as yup from 'yup';
import { User } from "@/utils/Model";
import BlotFormatter from "quill-blot-formatter";
import ImageUploader from "quill-image-uploader";
import axios from "axios";
import router from '../../../router/index'

export default /*@__PURE__*/_defineComponent({
  __name: 'AccountSettings',
  setup(__props) {

const backApi=inject('back');

let token: string|null = localStorage.getItem('login_token');
let codeOrganization: string|null = localStorage.getItem('organization_code');
let codeUser: string|null = localStorage.getItem('code');
let role: string|null = localStorage.getItem('role');
const user =ref({} as User);
const pageLoad=ref(false)



const showUserInfo = () =>{
    // +arg.
    
     axios.get(backApi+'/api/v1/secure/users/'+codeUser+'/show',{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        // reloading.value = response.data;  //.data.reloadings 
        user.value = response.data;        
        pageLoad.value=false;    
        console.log(response.data);
       
    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                console.log(error.response);
              }   
              
          }
          pageLoad.value=false;     
  });
}
onMounted(() => { 


 setTimeout(function () {
  pageLoad.value=true; 
       },1000)
       showUserInfo();
});

// export default defineComponent({
//   name: "AccountSettings",
//   setup: () => {
//     const modules = {
//       module: BlotFormatter,
//       ImageUploader,
//       options: {
//         upload: (file: string | Blob) => {
//           return new Promise((resolve, reject) => {
//             const formData = new FormData();
//             formData.append("image", file);

//             axios
//               .post("/upload-image", formData)
//               .then((res: { data: { url: unknown } }) => {
//                 console.log(res);
//                 resolve(res.data.url);
//               })
//               .catch((err: unknown) => {
//                 reject("Upload failed");
//                 console.error("Error:", err);
//               });
//           });
//         },
//       },
//     };
//     return { modules };
//   },
// });

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!pageLoad.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[4] || (_cache[4] = [
          _createElementVNode("div", {
            class: "spinner-grow text-danger",
            style: {"width":"3rem","height":"3rem","margin-top":"25vh","margin-left":"42%","margin-bottom":"5vh"},
            role: "status"
          }, [
            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (pageLoad.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("ul", _hoisted_6, [
                  _createElementVNode("li", null, [
                    _createVNode(_component_router_link, {
                      to: "/account-settings",
                      class: "fs-md-15 fs-lg-16 fw-medium text-decoration-none d-block w-100 rounded-1 text-center transition"
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode(" Informations personnelles ")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", null, [
                    _createVNode(_component_router_link, {
                      to: "/settings-change-password",
                      class: "fs-md-15 fs-lg-16 fw-medium text-decoration-none d-block w-100 rounded-1 text-center transition"
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode(" Modifier mon mot de passe ")
                      ])),
                      _: 1
                    })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("form", null, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _cache[7] || (_cache[7] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Nom de l'organisation ", -1)),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((user.value.organization.name) = $event)),
                          readonly: "",
                          type: "text",
                          class: "form-control shadow-none rounded-0 text-black"
                        }, null, 512), [
                          [_vModelText, user.value.organization.name]
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _cache[8] || (_cache[8] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Nom ", -1)),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((user.value.name) = $event)),
                          readonly: "",
                          type: "text",
                          class: "form-control shadow-none rounded-0 text-black"
                        }, null, 512), [
                          [_vModelText, user.value.name]
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _cache[9] || (_cache[9] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Prénom(s) ", -1)),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((user.value.username) = $event)),
                          readonly: "",
                          type: "text",
                          class: "form-control shadow-none rounded-0 text-black"
                        }, null, 512), [
                          [_vModelText, user.value.username]
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _cache[10] || (_cache[10] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " email ", -1)),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((user.value.email) = $event)),
                          readonly: "",
                          type: "email",
                          class: "form-control shadow-none rounded-0 text-black",
                          placeholder: "e.g. adam127704@gmail.com"
                        }, null, 512), [
                          [_vModelText, user.value.email]
                        ])
                      ])
                    ]),
                    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "col-md-12" }, [
                      _createElementVNode("div", { class: "d-flex align-items-center justify-content-between" })
                    ], -1))
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})