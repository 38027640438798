import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing" }
const _hoisted_2 = { class: "card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25" }
const _hoisted_3 = { class: "d-sm-flex align-items-center" }
const _hoisted_4 = { class: "select-calendar position-relative me-sm-10 ms-sm-15 mt-10 mt-sm-0" }
const _hoisted_5 = { class: "card-body p-15 p-sm-20 p-md-25" }
const _hoisted_6 = { class: "table-responsive" }
const _hoisted_7 = { class: "table text-nowrap align-middle mb-0" }
const _hoisted_8 = { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }
const _hoisted_9 = { class: "d-flex align-items-center" }
const _hoisted_10 = { class: "d-flex align-items-center ms-5 fs-md-15 fs-lg-16" }
const _hoisted_11 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_12 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_13 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_14 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_15 = { class: "shadow-none lh-1 fw-medium" }
const _hoisted_16 = {
  key: 0,
  class: "badge text-outline-success"
}
const _hoisted_17 = {
  key: 1,
  class: "badge text-outline-danger"
}
const _hoisted_18 = {
  key: 2,
  class: "badge text-outline-info"
}

import {ref, onMounted,inject  } from "vue";
import axios from 'axios';
import flatpickr from "flatpickr";
import { useRoute, useRouter } from 'vue-router'
import conf from '../../../../env.json'
import router from '../../../router/index'
// import {SERVER} from "../../../utils/const"
declare interface Transaction {
    checkoutId: string,
    operator: string,
    contact: string,
    amount: string,    
    transactionDate: string,
    paymentStatus: string
  }
const leadPlaceholder = "Select Lead Date";

export default /*@__PURE__*/_defineComponent({
  __name: 'LeadsContentByCompany',
  setup(__props) {

const backApi=inject('back');
const transactions =ref(new Array<Transaction>());
  function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}
let code:string|null='';
onMounted(() => {
  flatpickr("#lead-calendar", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    defaultDate: "today",
  });

  flatpickr("#basic-calendar", {
    dateFormat: "Y-m-d H:i",
  });
  const route = useRoute()
  code = route.params.id;
  getTransactions(code);
});
const token =  localStorage.getItem('login_token');
const getTransactions = (code) =>{

  axios.get(`${backApi}/api/v1/secure/transactions?organization=${code}`,{//SERVER+ //https://test.zivwallet.com/api/countries
        headers: {
            Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
        // users.value = response.data;
        transactions.value = response.data;  //.data.users          
        console.log(transactions.value);
        
        
    }).catch((error) => {
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                //window.location.href = '/';
              }   
              
          }
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "d-sm-flex align-items-center" }, [
        _createElementVNode("button", {
          class: "default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0",
          type: "button"
        }, [
          _createTextVNode(" Export "),
          _createElementVNode("i", { class: "flaticon-file-1 position-relative ms-5 top-2 fs-15" })
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("form", { class: "search-box position-relative" }, [
          _createElementVNode("input", {
            type: "text",
            class: "form-control shadow-none text-black rounded-0 border-0",
            placeholder: "Search lead"
          }),
          _createElementVNode("button", {
            type: "submit",
            class: "bg-transparent text-primary transition p-0 border-0"
          }, [
            _createElementVNode("i", { class: "flaticon-search-interface-symbol" })
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("i", { class: "flaticon-calendar lh-1 icon" }, null, -1)),
          _createElementVNode("input", {
            ref: "leadCalendar",
            type: "text",
            class: "form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16",
            id: "lead-calendar",
            placeholder: leadPlaceholder
          }, null, 512)
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("button", {
          class: "dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block mt-10 mt-sm-0",
          type: "button",
          "data-bs-toggle": "modal",
          "data-bs-target": "#filterLeadModal"
        }, [
          _createElementVNode("i", { class: "flaticon-dots" })
        ], -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("table", _hoisted_7, [
          _cache[6] || (_cache[6] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              }, " CODE TRANSACTION "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " OPERATEUR "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " TELEPHONE "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " MONTANT "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " DATE "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              }, " STATUT "),
              _createElementVNode("th", {
                scope: "col",
                class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              })
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(transactions.value, (transaction) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: transaction.checkoutId
              }, [
                _createElementVNode("th", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "form-check mb-0" }, [
                      _createElementVNode("input", {
                        class: "form-check-input shadow-none",
                        type: "checkbox"
                      })
                    ], -1)),
                    _createElementVNode("div", _hoisted_10, _toDisplayString(transaction.checkoutId), 1)
                  ])
                ]),
                _createElementVNode("td", _hoisted_11, _toDisplayString(transaction.operator.mnoName), 1),
                _createElementVNode("td", _hoisted_12, _toDisplayString(transaction.contact), 1),
                _createElementVNode("td", _hoisted_13, _toDisplayString(transaction.amount.toLocaleString()), 1),
                _createElementVNode("td", _hoisted_14, _toDisplayString(formatDateFr(new Date(transaction.transactionDate))), 1),
                _createElementVNode("td", _hoisted_15, [
                  (transaction.paymentStatus==='SUCCEEDED')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_16, "SUCCESS"))
                    : _createCommentVNode("", true),
                  (transaction.paymentStatus==='FAILED')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_17, "ECHEC"))
                    : _createCommentVNode("", true),
                  (transaction.paymentStatus==='PROCESSING')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_18, "EN COURS"))
                    : _createCommentVNode("", true)
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("td", { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }, [
                  _createElementVNode("div", { class: "dropdown" }, [
                    _createElementVNode("button", {
                      class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                      type: "button",
                      "data-bs-toggle": "dropdown",
                      "aria-expanded": "false"
                    }, [
                      _createElementVNode("i", { class: "flaticon-dots" })
                    ]),
                    _createElementVNode("ul", { class: "dropdown-menu" }, [
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          class: "dropdown-item d-flex align-items-center",
                          href: "javascript:void(0);"
                        }, [
                          _createElementVNode("i", { class: "flaticon-view lh-1 me-8 position-relative top-1" }),
                          _createTextVNode(" View")
                        ])
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          class: "dropdown-item d-flex align-items-center",
                          href: "javascript:void(0);"
                        }, [
                          _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }),
                          _createTextVNode(" Edit")
                        ])
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          class: "dropdown-item d-flex align-items-center",
                          href: "javascript:void(0);"
                        }, [
                          _createElementVNode("i", { class: "flaticon-delete lh-1 me-8 position-relative top-1" }),
                          _createTextVNode(" Delete")
                        ])
                      ])
                    ])
                  ])
                ], -1))
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}
}

})