import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../../assets/images/contact.png'
import _imports_1 from '../../../assets/images/user/user1.jpg'
import _imports_2 from '../../../assets/images/user/user2.jpg'
import _imports_3 from '../../../assets/images/user/user3.jpg'
import _imports_4 from '../../../assets/images/user/user4.jpg'
import _imports_5 from '../../../assets/images/user/user5.jpg'
import _imports_6 from '../../../assets/images/user/user6.jpg'


const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white letter-spacing product-details-box" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col-lg-5 col-md-9" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "col-lg-2 col-md-3" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "col-lg-5 col-md-12" }
const _hoisted_9 = { class: "product-details-content" }
const _hoisted_10 = { class: "box" }
const _hoisted_11 = { class: "text-paragraph fs-md-15 fs-xxxl-16 lh-base" }
const _hoisted_12 = { class: "buttons-list d-sm-flex align-items-center" }
const _hoisted_13 = { class: "input-counter d-flex align-items-center" }
const _hoisted_14 = {
  class: "number-counter me-15",
  id: "number-counter"
}
const _hoisted_15 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_swiper, {
            slidesPerView: 1,
            loop: true,
            autoplay: {
              delay: 4000,
              disableOnInteraction: true,
            },
            spaceBetween: 10,
            thumbs: { swiper: _ctx.thumbsSwiper },
            modules: [_ctx.Thumbs],
            class: "productDetailsSwiper"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
                return (_openBlock(), _createBlock(_component_swiper_slide, {
                  key: product.id
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: product.image,
                      alt: "product-details"
                    }, null, 8, _hoisted_5)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["thumbs", "modules"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_swiper, {
            direction: 'vertical',
            loop: true,
            autoplay: {
              delay: 4000,
              disableOnInteraction: false,
            },
            spaceBetween: 25,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesProgress: true,
            breakpoints: {
              0: {
                direction: 'horizontal',
                spaceBetween: 15,
              },
              768: {
                direction: 'vertical',
                spaceBetween: 25,
              },
            },
            modules: [_ctx.Thumbs],
            onSwiper: _ctx.setThumbsSwiper,
            class: "productDetailsThumbSwiper"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
                return (_openBlock(), _createBlock(_component_swiper_slide, {
                  key: product.id
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: product.image,
                      alt: "product-details"
                    }, null, 8, _hoisted_7)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["modules", "onSwiper"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _cache[13] || (_cache[13] = _createStaticVNode("<h3 class=\"text-black fw-semibold\"> Complete Smart Watch With Multifuction <br> Black Dial </h3><div class=\"reviews d-flex align-items-center\"><div class=\"rating lh-1\"><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star-1\"></i><i class=\"flaticon-star\"></i></div><span class=\"text-black fw-bold fs-13 ms-5 me-5\">4.0</span><span class=\"text-muted fw-medium fs-13\">(21 Reviews)</span></div><div class=\"mt-10 mb-12 mb-md-15 price d-flex align-items-center\"><span class=\"text-primary fw-bold fs-md-15 fs-lg-16\"> $3050.00 </span></div><p class=\"text-paragraph fs-md-15 fs-xxxl-16 lh-base\"> New 2023 Speedmaster Moonwatch Professional Co-Axial Master Chronometer 42mm Mens. Lorem consectetur ipsum dolor sit amet, consectetur adipising, sed do eiusmod tempor amet. </p><ul class=\"info ps-0 list-unstyled\"><li class=\"text-paragraph fs-md-15 fs-xxxl-16\"><span class=\"text-black fw-semibold d-inline-block\">MPN:</span> O31030425001002 </li><li class=\"text-paragraph fs-md-15 fs-xxxl-16\"><span class=\"text-black fw-semibold d-inline-block\"> Vendor: </span> Labila </li><li class=\"text-paragraph fs-md-15 fs-xxxl-16\"><span class=\"text-black fw-semibold d-inline-block\"> Category: </span> Accessories </li><li class=\"text-paragraph fs-md-15 fs-xxxl-16\"><span class=\"text-black fw-semibold d-inline-block\">SKU:</span> Aw2314 </li></ul>", 5)),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("p", _hoisted_11, [
                _cache[3] || (_cache[3] = _createTextVNode(" From only ")),
                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-black fw-medium" }, "$154.17", -1)),
                _cache[5] || (_cache[5] = _createTextVNode(" per month with ")),
                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-black fw-medium" }, "0%", -1)),
                _cache[7] || (_cache[7] = _createTextVNode(" finance options. ")),
                _createVNode(_component_router_link, {
                  to: "/product-details",
                  class: "text-decoration-none text-black fw-medium position-relative"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" View Options ")
                  ])),
                  _: 1
                }),
                _cache[8] || (_cache[8] = _createTextVNode(" Finance is subject to application & status. "))
              ])
            ]),
            _cache[14] || (_cache[14] = _createElementVNode("span", { class: "d-block text-paragraph" }, [
              _createElementVNode("span", { class: "text-success" }, [
                _createElementVNode("i", { class: "ph ph-check lh-1 fs-16 position-relative top-1 me-1" }),
                _createTextVNode(" IN STOCK ")
              ]),
              _createTextVNode(" - Order within 5 hours 31 minutes for "),
              _createElementVNode("span", { class: "text-black" }, "free express delivery.")
            ], -1)),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("button", {
                    type: "button",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.decrement && _ctx.decrement(...args)))
                  }, _cache[9] || (_cache[9] = [
                    _createElementVNode("i", { class: "flaticon-minus" }, null, -1)
                  ])),
                  _createElementVNode("input", {
                    type: "number",
                    value: _ctx.itemNumber
                  }, null, 8, _hoisted_15),
                  _createElementVNode("button", {
                    type: "button",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.increment && _ctx.increment(...args)))
                  }, _cache[10] || (_cache[10] = [
                    _createElementVNode("i", { class: "flaticon-plus" }, null, -1)
                  ]))
                ]),
                _cache[11] || (_cache[11] = _createElementVNode("button", {
                  class: "default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-20 pe-20 pt-md-11 pb-md-11 ps-md-25 pe-md-25 ps-xl-40 pe-xl-40 rounded-1",
                  type: "submit"
                }, " Add To Cart ", -1))
              ]),
              _cache[12] || (_cache[12] = _createElementVNode("button", {
                type: "button",
                class: "fav-btn mt-10 mt-sm-0 ms-sm-15 d-inline-block position-relative bg-transparent border-0 transition"
              }, [
                _createElementVNode("i", { class: "flaticon-star-3" }),
                _createElementVNode("span", { class: "position-relative" }, "Add To Favourite")
              ], -1))
            ])
          ])
        ])
      ]),
      _cache[15] || (_cache[15] = _createElementVNode("div", { class: "product-details-tabs" }, [
        _createElementVNode("ul", {
          class: "nav nav-tabs border-0 rounded-0",
          id: "myTab",
          role: "tablist"
        }, [
          _createElementVNode("li", {
            class: "nav-item",
            role: "presentation"
          }, [
            _createElementVNode("button", {
              class: "nav-link fw-semibold d-block position-relative border-0 rounded-0 active",
              id: "description-tab",
              "data-bs-toggle": "tab",
              "data-bs-target": "#description-tab-pane",
              type: "button",
              role: "tab",
              "aria-controls": "description-tab-pane",
              "aria-selected": "true"
            }, " Description ")
          ]),
          _createElementVNode("li", {
            class: "nav-item",
            role: "presentation"
          }, [
            _createElementVNode("button", {
              class: "nav-link fw-semibold d-block position-relative border-0 rounded-0",
              id: "reviews-tab",
              "data-bs-toggle": "tab",
              "data-bs-target": "#reviews-tab-pane",
              type: "button",
              role: "tab",
              "aria-controls": "reviews-tab-pane",
              "aria-selected": "false"
            }, " Reviews ")
          ])
        ]),
        _createElementVNode("div", {
          class: "tab-content",
          id: "myTabContent"
        }, [
          _createElementVNode("div", {
            class: "tab-pane fade show active",
            id: "description-tab-pane",
            role: "tabpanel",
            tabindex: "0"
          }, [
            _createElementVNode("div", { class: "row" }, [
              _createElementVNode("div", { class: "col-lg-8" }, [
                _createElementVNode("div", { class: "content" }, [
                  _createElementVNode("p", { class: "text-paragraph fs-md-15 fs-xxxl-16" }, " New 2023 Speedmaster Moonwatch Professional Co-Axial Master Chronometer 42mm Mens. Lorem ipsum dolor sit amet, consectetur adipising, sed do eiusmod tempor. "),
                  _createElementVNode("p", { class: "text-paragraph fs-md-15 fs-xxxl-16" }, " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "),
                  _createElementVNode("ul", { class: "ps-0 list-unstyled" }, [
                    _createElementVNode("li", { class: "text-black fs-md-15 fs-xxxl-16 position-relative" }, " Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium. "),
                    _createElementVNode("li", { class: "text-black fs-md-15 fs-xxxl-16 position-relative" }, " Doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo toterum pitael accustision. ")
                  ]),
                  _createElementVNode("p", { class: "text-paragraph fs-md-15 fs-xxxl-16" }, " Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt. "),
                  _createElementVNode("p", { class: "text-paragraph fs-md-15 fs-xxxl-16" }, " Ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "),
                  _createElementVNode("p", { class: "text-paragraph fs-md-15 fs-xxxl-16" }, " New 2023 Speedmaster Moonwatch Professional Co-Axial Master Chronometer 42mm Mens. Lorem ipsum dolor sit amet, consectetur adipising, sed do eiusmod tempor. ")
                ])
              ]),
              _createElementVNode("div", { class: "col-lg-4" }, [
                _createElementVNode("div", { class: "card mb-0 border-0 rounded-0 campaign-contact-box" }, [
                  _createElementVNode("div", { class: "card-body letter-spacing position-relative" }, [
                    _createElementVNode("h2", { class: "fw-medium text-white mb-0" }, [
                      _createTextVNode(" Enhance Your "),
                      _createElementVNode("strong", { class: "fw-black" }, "Campaign"),
                      _createTextVNode(" For Better Outreach "),
                      _createElementVNode("a", {
                        href: "#",
                        class: "text-white text-decoration-none lh-1 position-relative ms-5 top-3"
                      }, [
                        _createElementVNode("i", { class: "flaticon-right-arrow-1" })
                      ])
                    ]),
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "contact-image"
                    })
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "tab-pane fade",
            id: "reviews-tab-pane",
            role: "tabpanel",
            tabindex: "0"
          }, [
            _createElementVNode("div", { class: "product-reviews" }, [
              _createElementVNode("h3", { class: "text-black mb-5 fw-bold" }, "4.8"),
              _createElementVNode("span", { class: "d-block fs-md-15 fs-lg-16 fw-medium mb-8" }, "Overall Rating"),
              _createElementVNode("div", { class: "reviews d-flex align-items-center" }, [
                _createElementVNode("div", { class: "rating lh-1" }, [
                  _createElementVNode("i", { class: "flaticon-star-1" }),
                  _createElementVNode("i", { class: "flaticon-star-1" }),
                  _createElementVNode("i", { class: "flaticon-star-1" }),
                  _createElementVNode("i", { class: "flaticon-star-1" }),
                  _createElementVNode("i", { class: "flaticon-star" })
                ]),
                _createElementVNode("span", { class: "text-black fw-bold fs-13 ms-5 me-5" }, "4.0"),
                _createElementVNode("span", { class: "text-muted fw-medium fs-13" }, "(21 Reviews)")
              ]),
              _createElementVNode("div", { class: "rating-count" }, [
                _createElementVNode("div", { class: "mt-15 mb-5 d-flex justify-content-between align-items-center" }, [
                  _createElementVNode("span", { class: "fw-medium text-black" }, "5 Star"),
                  _createElementVNode("span", { class: "fw-medium text-black" }, "112")
                ]),
                _createElementVNode("div", {
                  class: "progress",
                  role: "progressbar",
                  "aria-valuenow": "80",
                  "aria-valuemin": "0",
                  "aria-valuemax": "100"
                }, [
                  _createElementVNode("div", {
                    class: "progress-bar",
                    style: {"width":"80%"}
                  })
                ]),
                _createElementVNode("div", { class: "mt-15 mb-5 d-flex justify-content-between align-items-center" }, [
                  _createElementVNode("span", { class: "fw-medium text-black" }, "4 Star"),
                  _createElementVNode("span", { class: "fw-medium text-black" }, "43")
                ]),
                _createElementVNode("div", {
                  class: "progress",
                  role: "progressbar",
                  "aria-valuenow": "60",
                  "aria-valuemin": "0",
                  "aria-valuemax": "100"
                }, [
                  _createElementVNode("div", {
                    class: "progress-bar",
                    style: {"width":"60%"}
                  })
                ]),
                _createElementVNode("div", { class: "mt-15 mb-5 d-flex justify-content-between align-items-center" }, [
                  _createElementVNode("span", { class: "fw-medium text-black" }, "3 Star"),
                  _createElementVNode("span", { class: "fw-medium text-black" }, "43")
                ]),
                _createElementVNode("div", {
                  class: "progress",
                  role: "progressbar",
                  "aria-valuenow": "45",
                  "aria-valuemin": "0",
                  "aria-valuemax": "100"
                }, [
                  _createElementVNode("div", {
                    class: "progress-bar",
                    style: {"width":"45%"}
                  })
                ]),
                _createElementVNode("div", { class: "mt-15 mb-5 d-flex justify-content-between align-items-center" }, [
                  _createElementVNode("span", { class: "fw-medium text-black" }, "2 Star"),
                  _createElementVNode("span", { class: "fw-medium text-black" }, "10")
                ]),
                _createElementVNode("div", {
                  class: "progress",
                  role: "progressbar",
                  "aria-valuenow": "30",
                  "aria-valuemin": "0",
                  "aria-valuemax": "100"
                }, [
                  _createElementVNode("div", {
                    class: "progress-bar",
                    style: {"width":"30%"}
                  })
                ]),
                _createElementVNode("div", { class: "mt-15 mb-5 d-flex justify-content-between align-items-center" }, [
                  _createElementVNode("span", { class: "fw-medium text-black" }, "1 Star"),
                  _createElementVNode("span", { class: "fw-medium text-black" }, "0")
                ]),
                _createElementVNode("div", {
                  class: "progress",
                  role: "progressbar",
                  "aria-valuenow": "0",
                  "aria-valuemin": "0",
                  "aria-valuemax": "100"
                }, [
                  _createElementVNode("div", {
                    class: "progress-bar",
                    style: {"width":"0%"}
                  })
                ])
              ]),
              _createElementVNode("div", { class: "row reviews-list" }, [
                _createElementVNode("div", { class: "col-lg-4 mb-25" }, [
                  _createElementVNode("div", { class: "p-15 p-sm-20 p-md-25 rounded-1 bg-gray" }, [
                    _createElementVNode("div", { class: "rating mb-8" }, [
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" })
                    ]),
                    _createElementVNode("p", { class: "text-paragraph lh-base fs-md-15 fs-lg-16 mb-0" }, " Lorem consectetur ipsum dolor sit amet, consectetur adipising, sed do eiusmod tempor amet. "),
                    _createElementVNode("div", { class: "mt-15 d-flex align-items-center" }, [
                      _createElementVNode("img", {
                        src: _imports_1,
                        alt: "user",
                        class: "rounded-circle me-12",
                        width: "30"
                      }),
                      _createElementVNode("span", { class: "fs-md-15 d-block text-black fw-medium" }, " Alvarado Turner ")
                    ])
                  ])
                ]),
                _createElementVNode("div", { class: "col-lg-4 mb-25" }, [
                  _createElementVNode("div", { class: "p-15 p-sm-20 p-md-25 rounded-1 bg-gray" }, [
                    _createElementVNode("div", { class: "rating mb-8" }, [
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star" })
                    ]),
                    _createElementVNode("p", { class: "text-paragraph lh-base fs-md-15 fs-lg-16 mb-0" }, " Lorem consectetur ipsum dolor sit amet, consectetur adipising, sed do eiusmod tempor amet. "),
                    _createElementVNode("div", { class: "mt-15 d-flex align-items-center" }, [
                      _createElementVNode("img", {
                        src: _imports_2,
                        alt: "user",
                        class: "rounded-circle me-12",
                        width: "30"
                      }),
                      _createElementVNode("span", { class: "fs-md-15 d-block text-black fw-medium" }, " Alina Smith ")
                    ])
                  ])
                ]),
                _createElementVNode("div", { class: "col-lg-4 mb-25" }, [
                  _createElementVNode("div", { class: "p-15 p-sm-20 p-md-25 rounded-1 bg-gray" }, [
                    _createElementVNode("div", { class: "rating mb-8" }, [
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-2" })
                    ]),
                    _createElementVNode("p", { class: "text-paragraph lh-base fs-md-15 fs-lg-16 mb-0" }, " Lorem consectetur ipsum dolor sit amet, consectetur adipising, sed do eiusmod tempor amet. "),
                    _createElementVNode("div", { class: "mt-15 d-flex align-items-center" }, [
                      _createElementVNode("img", {
                        src: _imports_3,
                        alt: "user",
                        class: "rounded-circle me-12",
                        width: "30"
                      }),
                      _createElementVNode("span", { class: "fs-md-15 d-block text-black fw-medium" }, " James Smith ")
                    ])
                  ])
                ]),
                _createElementVNode("div", { class: "col-lg-4 mb-25" }, [
                  _createElementVNode("div", { class: "p-15 p-sm-20 p-md-25 rounded-1 bg-gray" }, [
                    _createElementVNode("div", { class: "rating mb-8" }, [
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star" }),
                      _createElementVNode("i", { class: "flaticon-star" })
                    ]),
                    _createElementVNode("p", { class: "text-paragraph lh-base fs-md-15 fs-lg-16 mb-0" }, " Lorem consectetur ipsum dolor sit amet, consectetur adipising, sed do eiusmod tempor amet. "),
                    _createElementVNode("div", { class: "mt-15 d-flex align-items-center" }, [
                      _createElementVNode("img", {
                        src: _imports_4,
                        alt: "user",
                        class: "rounded-circle me-12",
                        width: "30"
                      }),
                      _createElementVNode("span", { class: "fs-md-15 d-block text-black fw-medium" }, " Sarah Taylor ")
                    ])
                  ])
                ]),
                _createElementVNode("div", { class: "col-lg-4 mb-25" }, [
                  _createElementVNode("div", { class: "p-15 p-sm-20 p-md-25 rounded-1 bg-gray" }, [
                    _createElementVNode("div", { class: "rating mb-8" }, [
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" })
                    ]),
                    _createElementVNode("p", { class: "text-paragraph lh-base fs-md-15 fs-lg-16 mb-0" }, " Lorem consectetur ipsum dolor sit amet, consectetur adipising, sed do eiusmod tempor amet. "),
                    _createElementVNode("div", { class: "mt-15 d-flex align-items-center" }, [
                      _createElementVNode("img", {
                        src: _imports_5,
                        alt: "user",
                        class: "rounded-circle me-12",
                        width: "30"
                      }),
                      _createElementVNode("span", { class: "fs-md-15 d-block text-black fw-medium" }, " Ivan Dan ")
                    ])
                  ])
                ]),
                _createElementVNode("div", { class: "col-lg-4 mb-25" }, [
                  _createElementVNode("div", { class: "p-15 p-sm-20 p-md-25 rounded-1 bg-gray" }, [
                    _createElementVNode("div", { class: "rating mb-8" }, [
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-1" }),
                      _createElementVNode("i", { class: "flaticon-star-2" }),
                      _createElementVNode("i", { class: "flaticon-star" })
                    ]),
                    _createElementVNode("p", { class: "text-paragraph lh-base fs-md-15 fs-lg-16 mb-0" }, " Lorem consectetur ipsum dolor sit amet, consectetur adipising, sed do eiusmod tempor amet. "),
                    _createElementVNode("div", { class: "mt-15 d-flex align-items-center" }, [
                      _createElementVNode("img", {
                        src: _imports_6,
                        alt: "user",
                        class: "rounded-circle me-12",
                        width: "30"
                      }),
                      _createElementVNode("span", { class: "fs-md-15 d-block text-black fw-medium" }, " Riley Dave ")
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ], -1))
    ])
  ]))
}