import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-20 d-sm-flex align-items-center justify-content-between letter-spacing" }
const _hoisted_2 = { class: "mb-5 mb-sm-0 fw-bold" }
const _hoisted_3 = { "aria-label": "breadcrumb" }
const _hoisted_4 = { class: "breadcrumb mb-0 list-unstyled ps-0" }
const _hoisted_5 = { class: "breadcrumb-item" }
const _hoisted_6 = {
  class: "breadcrumb-item active",
  "aria-current": "page"
}

import { defineComponent } from "vue";
import {ref,onMounted } from "vue";
import { useRoute } from 'vue-router'
import axios from 'axios';
import conf from '../../../env.json'

declare interface Organization {
      id : string,
      code: string,
      name: string,
      description: string,    
      email: string,
      created_at: string
      }
     
    
export default /*@__PURE__*/_defineComponent({
  __name: 'BreadCrumbDetail',
  props: ["PageTitle","company"],
  setup(__props) {

const props = __props

const back = ()=>{
      window.history.back()
    }
 
    let code:string| string[]='';

    onMounted(() => {  
      const route = useRoute();
      code = route.params.id;
      getOrganization(code);
     //alert(code)
    });
    const token =  localStorage.getItem('login_token');
    const organization = ref({} as Organization)
    
    
      const getOrganization = (code) =>{

            axios.get(`${process.env.VUE_APP_API_BASE}/api/v1/secure/organizations/${code}/show`,{
                  headers: {
                      Authorization: `Bearer ${token}`
                }
              })
              .then((response) => {
                  // users.value = response.data;
                  organization.value = response.data;  //.data.users   
                  
              }).catch((error) => {
                    // if( error.response){                       
                                               
                    // }
            });
        }
  

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "",
        onClick: back,
        class: "link-btn lh-1 text-decoration-none"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("i", { class: "ph ph-caret-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(__props.PageTitle) + " " + _toDisplayString(organization.value.name), 1)
    ]),
    _createElementVNode("nav", _hoisted_3, [
      _createElementVNode("ol", _hoisted_4, [
        _createElementVNode("li", _hoisted_5, [
          _createVNode(_component_router_link, {
            to: "/dashboard",
            class: "text-decoration-none text-black"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Tableau de bord ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", _hoisted_6, _toDisplayString(__props.PageTitle), 1)
      ])
    ])
  ]))
}
}

})