import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "row justify-content-center",
  style: {"margin-top":"-8%"}
}
const _hoisted_2 = { class: "col-md-8 col-lg-6 col-xl-7 col-xxl-6 col-xxxl-4" }
const _hoisted_3 = { class: "card mb-25 border-0 rounded-0 bg-white authentication-card" }
const _hoisted_4 = { class: "card-body letter-spacing" }
const _hoisted_5 = {
  key: 0,
  class: "text-black fw-bold mb-0 text-center"
}
const _hoisted_6 = {
  key: 1,
  style: {"margin-top":"5%"}
}
const _hoisted_7 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"red"}
}
const _hoisted_8 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"red"}
}
const _hoisted_9 = {
  key: 2,
  style: {"margin-top":"5%"}
}
const _hoisted_10 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"green"}
}
const _hoisted_11 = {
  key: 0,
  class: "row"
}
const _hoisted_12 = { class: "form-group col-md-6" }
const _hoisted_13 = { class: "form-group col-md-6" }
const _hoisted_14 = {
  key: 1,
  class: "form-group mt-10 mb-10 mb-sm-10 mb-md-25"
}
const _hoisted_15 = { class: "col-md-12" }
const _hoisted_16 = ["value"]
const _hoisted_17 = {
  key: 2,
  class: "text-center form-check form-check-primary mb-0 fs-md-15 fs-lg-16 mr-0 text-muted lh-1 mb-15 mb-md-20"
}
const _hoisted_18 = ["src"]
const _hoisted_19 = ["src"]
const _hoisted_20 = ["src"]
const _hoisted_21 = ["src"]
const _hoisted_22 = ["src"]
const _hoisted_23 = {
  key: 4,
  class: "form-group mb-15 mb-sm-20 mb-md-25"
}
const _hoisted_24 = {
  key: 5,
  class: "row line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0"
}
const _hoisted_25 = {
  key: 6,
  class: "form-group mb-15 mb-sm-20 mb-md-25"
}
const _hoisted_26 = {
  key: 7,
  class: "row line-numbers bg-gray pt-1 pb-0 ps-25 pe-25 mb-0"
}
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { key: 1 }
const _hoisted_29 = { key: 2 }
const _hoisted_30 = {
  key: 8,
  id: "compte_a_rebours",
  class: "form-group mb-15 mb-sm-20 mb-md-25"
}
const _hoisted_31 = {
  key: 9,
  class: "form-group mt-10 mb-10 mb-sm-10 mb-md-25"
}
const _hoisted_32 = { class: "invalid-feedback" }
const _hoisted_33 = {
  key: 10,
  class: "row justify-content-center"
}
const _hoisted_34 = { class: "col-md-6 col-lg-6" }
const _hoisted_35 = ["disabled"]
const _hoisted_36 = {
  key: 0,
  class: "spinner-grow spinner-grow-sm",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_37 = {
  key: 11,
  class: "form-group mb-15 mb-sm-20 mb-md-25"
}
const _hoisted_38 = {
  key: 12,
  class: "form-group mb-15 mb-sm-20 mb-md-25"
}
const _hoisted_39 = {
  key: 13,
  class: "form-group mb-15 mb-sm-20 mb-md-25"
}
const _hoisted_40 = {
  key: 14,
  class: "row line-numbers bg-gray pt-0 pb-0 ps-25 pe-25 mb-0"
}

import router from '@/router';
import { useRoute} from 'vue-router'
import axios from 'axios';
import { ref, reactive,inject,onMounted } from 'vue';
import * as yup from 'yup';
import data from '../../../../env.json'
import "intl-tel-input/build/css/intlTelInput.css";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

import intlTelInput from 'intl-tel-input';
import ci from "intl-tel-input/i18n/ci"; 
import { Country,Fee,Operator } from "@/utils/Model";

import mtn from "../../../assets/images/operator/mtn1.png"
import wave from "../../../assets/images/operator/wave.png"
import orange from "../../../assets/images/operator/om1.png"
import moov from "../../../assets/images/operator/moov1.png"
import all from "../../../assets/images/operator/all_operator.png"

import {validate_phone_number,formatDateFr} from "../../../utils/function";

export default /*@__PURE__*/_defineComponent({
  __name: 'TicketContent',
  setup(__props) {

const backApi=inject('back');
const error = ref('');
const errors = ref('');
const errorId = ref('');
const success = ref('');
const errorsBis = {'otp':0,'amount':0};//'name':'',
const phone = ref("");
const operators =ref(new Array<Operator>());
let operatorsTemp =new Array<Operator>();

let token: string|null = localStorage.getItem('login_token');
const selectedOperator = ref('');
let contrycode ='';
let contryIndicatif =''
const step1 = ref(1);
const step2 = ref(0);
const step3 = ref(0);
const OTP_OPERATOR = ref(process.env.VUE_APP_OTP_OPERATOR);
const MTN_OPERATOR = ref(process.env.VUE_APP_MTN);
const MOOV_OPERATOR = ref(process.env.VUE_APP_MOOV);
const WAVE_OPERATOR = ref(process.env.VUE_APP_WAVE);
let checkoutId = "";
let publicToken:string|null='';
const amount = ref(0);
const waveradio = ref(0);
const status = ref(null);
let countRetry=0;
let verify_transact;
let sampleInterval;
const btnLoad = ref(true);
const pageLoad = ref(false);
let startingMinutes = ref(5);
let startingSeconds = ref(0);
const mins = ref(startingMinutes);
const secs = ref(startingSeconds);
let paymentUrl;

let  iti ;
const schema = yup.object({
    otp: yup.string().min(4, 'Le code doit faire au moin 4 chiffres'),
    amount: yup.number().required('Le montant est requis'),    
});



const formValues = reactive({ 
  otp: "0000", 
  amount: 0,
});

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;
          // alert(errorsBis[field])
        });
        errors.value=''
    }

    
    function handlePhone(myvalue,tag) {
      var contry =iti.getSelectedCountryData().iso2;
      let phone_length=0;
      switch (contry) {
        case 'ci':
          phone_length=process.env.VUE_APP_CI_PHONE_LENTGH;
          break;        
        case 'sn':
          phone_length=process.env.VUE_APP_SN_PHONE_LENTGH;
          break;
        default:
          alert("Sélectionnez un pays");
      }
      validate_phone_number(myvalue,tag,phone_length)

    }

    function handleOperatorChange(event) {
      // alert(event.target.value)
      selectedOperator.value = event.target.value;
    }

    function contryChange(countryObject){
        //  alert(countryObject.dialCode)
         contrycode =countryObject.iso2;
         contryIndicatif =countryObject.dialCode;
        // console.log(countryObject)
        var j = 0;
        var operatorArr = new Array<Operator>();
        while(j<operatorsTemp.length){    
            if(operatorsTemp[j].countryCode == countryObject.iso2){         
                operatorArr.push(operatorsTemp[j]);
            }      
            j++;
        }
        operators.value = operatorArr;
    }

    function initOperator(myValue,tag){        
         contrycode =iti.getSelectedCountryData().iso2;
    
        var j = 0;
        let operatorArr = new Array<Operator>();
        while(j<operatorsTemp.length){    
            if(operatorsTemp[j].countryCode == contrycode.toUpperCase()){         
                operatorArr.push(operatorsTemp[j]);
            }      
            j++;
        }
        // alert(operatorArr[0].mnoName);
        // alert(waveradio.value);
        operators.value = operatorArr;
    }

   
    const next = () => {
      let numero = phone.value;
      // alert(checkField())
      if(checkField()){
      // if(selectedOperator.value!=="" && amount.value>0 && (numero.length==process.env.VUE_APP_CI_PHONE_LENTGH||numero.length==process.env.VUE_APP_SN_PHONE_LENTGH)){      
              step1.value=0;
              step2.value=1;
              if(selectedOperator.value!==OTP_OPERATOR.value){
                  initTransaction();
                // if(selectedOperator.value==MTN_OPERATOR.value || selectedOperator.value==MOOV_OPERATOR.value){
                  sampleInterval = setInterval(() => {
                    if (secs.value > 0) {
                        secs.value=secs.value-1;     
                      }
                      if (secs.value === 0) {
                            
                        if (mins.value < 0) { 
                          checkPublicTransactionStatus();
                          clearInterval(verify_transact);        
                          clearInterval(sampleInterval);   
                          btnLoad.value=true;
                          step3.value=1;
                          step2.value=0;
                          step1.value=0;               
                        
                          
                        } else {
                            mins.value=mins.value-1;   
                            secs.value=59;            
                            if ((mins.value-1) === -1) {
                              checkPublicTransactionStatus();
                              clearInterval(verify_transact);
                              clearInterval(sampleInterval);
                              btnLoad.value=true;
                              step3.value=1;
                              step2.value=0;
                              step1.value=0;
                              
                              
                            }        
                          
                        }
                      }
                    }, 1000);
                    
                    return () => {
                      clearInterval(sampleInterval);
                      
                    };
                // }
              }
      }
      // else{
      //   errorId.value="Erreur survenue";            
      //   errors.value="Veuillez entrer des données valides.";
      // }
    }

    const goback = () => {
      step1.value=1;
      step2.value=0;
      step3.value=0;
      router.go(0)
    }
const checkField = () => {
      let result = true;
      if(selectedOperator.value==""){
        errorId.value="Erreur survenue";            
        errors.value="Veuillez sélectionner un opérateur.";
        result =  false;
      }
      if(amount.value<=0){
        errorId.value="Erreur survenue";            
        errors.value="Veuillez entrer un montant valide.";
        result =  false;
      }
      if(phone.value==""||(phone.value.length<process.env.VUE_APP_CI_PHONE_LENTGH && contrycode=="ci")||(phone.value.length<process.env.VUE_APP_SN_PHONE_LENTGH && contrycode=="sn")){
        errorId.value="Erreur survenue";            
        errors.value="Veuillez entrer un numéro valide.";
        result =  false;
      }
      if(formValues.otp==""){
        errorId.value="Erreur survenue";            
        errors.value="Veuillez entrer un code otp valide.";
        // alert(formValues.otp)
        result =  false;
      }
     return result ;
}

const initTransaction = async () => {
      btnLoad.value=false;
     
      if(checkField()){
          try {  
            
          
            schema.validateSync(formValues);
            
            axios.post(backApi+'/api/v1/public/transactions/init', {//http://127.0.0.1:8000
              operator: selectedOperator.value, 
              publicToken: publicToken,       
              amount: amount.value,
              mobile:phone.value,//"0708195112",//phone.value,
              operationType: parseInt(process.env.VUE_APP_PAYIN_OPTTYPE),
              countryCode: contrycode,         
              otp: formValues.otp,
              successUrl: "https://billing.api/success",
              errorUrl: "https://billing.api/error"
            }, {
            headers: {          
              'Content-Type': 'application/json',   
              // Authorization: `Bearer ${token}`        
            }
          }).then((response) => {        
            if(response.status == 201){
              checkoutId = response.data.checkoutId;
            
              if(selectedOperator.value==WAVE_OPERATOR.value || selectedOperator.value==OTP_OPERATOR.value || selectedOperator.value==MTN_OPERATOR.value || selectedOperator.value==MOOV_OPERATOR.value){
                verify_transact=setInterval(checkPublicTransactionStatus,10000);            
              }
              
              // success.value="Inscription effectuée avec succès nous vous contacterons pour la validation de votre compte"
                        
            }
          }).catch((error) => {
            btnLoad.value=true;
              if( error.response){  
                  errorId.value=error.response.data.errorId;            
                  errors.value=error.response.data.message;              
              }
          });
            

          } catch (error) {
            console.error(error);
            if( error.response){  
                  errorId.value=error.response.data.errorId;            
                  errors.value=error.response.data.message;              
              }
              btnLoad.value=true;
            //  errors.value="une erreur inattendue est apparue"//ValidationError:
          
          }
    }
    // else{
    //   alert('Manci')
    // } 
 
};


const showPublicTransaction = (token) =>{

axios.get(backApi+'/api/v1/public/transactions/'+token,{  //secure
  headers: {
    'Content-Type': 'application/json',    
  } 
})
.then((response) => {
  if(response.status == 200){
    amount.value = response.data.amount;  //.data.users   
    status.value = response.data.paymentStatus;       
    // console.log(response.data);
    
  }  
   
}).catch((error) => {
      if( error.response){
          // console.log(error.response.data); // => the response payload 
          if( error.response){  
              // errorId.value=error.response.data.errorId;            
              // errors.value=error.response.data.message;  
              // errorId.value=error.response.data.errorId;            
              errors.value=error.response.data;  
              router.push('/');           
          }  
          
      }
});
//  loader.value=true;
}

const checkPublicTransactionStatus = () =>{

axios.get(backApi+'/api/v1/public/transactions/'+publicToken,{  //secure
  headers: {
    'Content-Type': 'application/json',    
  } 
})
.then((response) => {
  if(response.status == 200){
     
    status.value = response.data.paymentStatus;  
    // alert(status.value) 
    countRetry+=1;
    if(selectedOperator.value==OTP_OPERATOR.value && (countRetry==3 || status.value=="SUCCEEDED" || status.value=="FAILED")){// || status.value=="FAILED"
      clearInterval(verify_transact);
      btnLoad.value=true;
      step3.value=1;
      step2.value=0;
      step1.value=0;
    }
    if((selectedOperator.value==MTN_OPERATOR.value || selectedOperator.value==MOOV_OPERATOR.value)&&( status.value=="SUCCEEDED" || status.value=="FAILED")){
            
            clearInterval(verify_transact);
            clearInterval(sampleInterval);
            btnLoad.value=true;
            step3.value=1;
            step2.value=0;
            step1.value=0;
            
    }
    if(selectedOperator.value==WAVE_OPERATOR.value){// || status.value=="FAILED"
      if( status.value=="SUCCEEDED" || status.value=="FAILED"){
        clearInterval(verify_transact);
        btnLoad.value=true;
        step3.value=1;
        step2.value=0;
        step1.value=0;
      }
      if(countRetry==1){
        paymentUrl = response.data.paymentUrl;
        window.open(paymentUrl,'_blank'); 
      }
      
    }
    console.log(response.data);
    
  }  
   
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if( error.response){  
              errorId.value=error.response.data.errorId;            
              errors.value=error.response.data.message;              
          }  
          
      }
});
//  loader.value=true;
}

const getOperators = () =>{

axios.get(backApi+'/api/v1/public/operators/',{  //secure
  headers: {
    'Content-Type': 'application/json',   
        // Authorization: `Bearer ${token}`
  } 
})
.then((response) => {
    // users.value = response.data;
    operatorsTemp = response.data;  //.data.users          
    console.log(response.data);
    
    
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            router.push('/')
            
          }   
          
      }
});
//  loader.value=true;
}

// <option  value="SN" >Sénégal</option>
// <option  value="CI" > Côte d'Ivoire</option>
onMounted(() => {

  const route = useRoute();
  publicToken = route.params.token;  
  checkoutId = "";
  getOperators();
  showPublicTransaction(publicToken)
  const inputc = document.querySelector("#phone");
  // setTimeout(function () {
  //  pageLoad.value=true; 
        //  },1000);
  
  iti = intlTelInput(inputc, {
    // any initialisation options go here
    onlyCountries: ["ci","sn"],
    // i18n: ci,
    initialCountry: "auto",
  // utilsScript: "/intl-tel-input/js/utils.js?1722010922246",
  geoIpLookup: function(callback) {
    fetch("https://ipapi.co/json")
      .then(function(res) { return res.json(); })
      .then(function(data) { callback(data.country_code); })
      .catch(function() { callback("ci"); });
  }
});
// document.getElementById('phone').style.display ="block"; 
// contrycode =iti.getSelectedCountryData().iso2;




});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (step3.value==0 && step2.value==0 )
            ? (_openBlock(), _createElementBlock("h4", _hoisted_5, " Payez ici "))
            : _createCommentVNode("", true),
          (errors.value!=='')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("h6", _hoisted_7, _toDisplayString(errorId.value), 1),
                _createElementVNode("h6", _hoisted_8, _toDisplayString(errors.value), 1)
              ]))
            : _createCommentVNode("", true),
          (success.value!=='')
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("h6", _hoisted_10, _toDisplayString(success.value), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("form", {
            onSubmit: _withModifiers(initTransaction, ["prevent"])
          }, [
            (step1.value==1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _cache[11] || (_cache[11] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-5" }, " Numéro de téléphone ", -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "tel",
                      class: "form-control shadow-none rounded-0 text-black field",
                      placeholder: "0708204050",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((phone).value = $event)),
                      id: "phone",
                      onKeyup: _cache[1] || (_cache[1] = ($event: any) => (handlePhone($event.target.value,$event.target))),
                      onFocus: _cache[2] || (_cache[2] = ($event: any) => (initOperator($event.target.value,$event.target))),
                      onBlur: _cache[3] || (_cache[3] = ($event: any) => (handlePhone($event.target.value,$event.target)))
                    }, null, 544), [
                      [_vModelText, phone.value]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _cache[12] || (_cache[12] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-5" }, "Montant", -1)),
                    (amount.value==0)
                      ? _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 0,
                          type: "number",
                          class: _normalizeClass(["form-control shadow-none rounded-0 text-black field", { 'is-invalid': errorsBis['amount'] }]),
                          placeholder: "e.g. 5 000",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((amount).value = $event)),
                          onKeyup: _cache[5] || (_cache[5] = ($event: any) => (_unref(validate_phone_number)($event.target.value,$event.target))),
                          required: ""
                        }, null, 34)), [
                          [_vModelText, amount.value]
                        ])
                      : _createCommentVNode("", true),
                    (amount.value>0)
                      ? _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 1,
                          type: "number",
                          class: "form-control shadow-none rounded-0 text-black field",
                          placeholder: "e.g. 5 000",
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((amount).value = $event)),
                          onKeyup: _cache[7] || (_cache[7] = ($event: any) => (_unref(validate_phone_number)($event.target.value,$event.target))),
                          disabled: ""
                        }, null, 544)), [
                          [_vModelText, amount.value]
                        ])
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            (step1.value==1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _cache[14] || (_cache[14] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-5" }, " Opérateurs ", -1)),
                    _createElementVNode("select", {
                      name: "operator",
                      id: "operator",
                      class: "form-select fs-md-15 text-black shadow-none selectfield",
                      onChange: handleOperatorChange,
                      required: ""
                    }, [
                      _cache[13] || (_cache[13] = _createElementVNode("option", { value: "" }, "Selectionnez...", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(operators.value, (operator) => {
                        return (_openBlock(), _createElementBlock("option", {
                          value: operator.mnoName,
                          key: operator.id
                        }, _toDisplayString(operator.mnoName), 9, _hoisted_16))
                      }), 128))
                    ], 32)
                  ])
                ]))
              : _createCommentVNode("", true),
            (step1.value==1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("img", {
                    src: _unref(all),
                    height: "50%",
                    width: "50%"
                  }, null, 8, _hoisted_18)
                ]))
              : _createCommentVNode("", true),
            (step1.value==1)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 3,
                  onClick: next,
                  class: "default-btn transition fw-medium text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100 border border-warning",
                  style: {"background-color":"#fff","border-color":"#EB9355","border-width":"1px","border-style":"solid"}
                }, [
                  (selectedOperator.value==OTP_OPERATOR.value)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: _unref(orange),
                        width: "25",
                        height: "25"
                      }, null, 8, _hoisted_19))
                    : _createCommentVNode("", true),
                  (selectedOperator.value==MTN_OPERATOR.value)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        src: _unref(mtn),
                        width: "25",
                        height: "25"
                      }, null, 8, _hoisted_20))
                    : _createCommentVNode("", true),
                  (selectedOperator.value==MOOV_OPERATOR.value)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 2,
                        src: _unref(moov),
                        width: "25",
                        height: "25"
                      }, null, 8, _hoisted_21))
                    : _createCommentVNode("", true),
                  (selectedOperator.value==WAVE_OPERATOR.value)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 3,
                        src: _unref(wave),
                        width: "25",
                        height: "25"
                      }, null, 8, _hoisted_22))
                    : _createCommentVNode("", true),
                  _cache[15] || (_cache[15] = _createElementVNode("span", { style: {"color":"#EB9355"} }, "Payer", -1))
                ]))
              : _createCommentVNode("", true),
            (step2.value==1 && selectedOperator.value==OTP_OPERATOR.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, _cache[16] || (_cache[16] = [
                  _createElementVNode("h2", null, "Confirmation du paiement", -1)
                ])))
              : _createCommentVNode("", true),
            (step2.value==1 && selectedOperator.value==OTP_OPERATOR.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_24, " Suivez les étapes suivantes pour obtenir le code d’autorisation. 1. Pour générer un code temporaire, ouvrez l’appli Orange Money Afrique et cliquez sur Mon Compte, ou composez le #144*82# et lancez l’appel 2. Tapez votre code secret et validez 3. Veuillez copier et coller le code reçu par SMS dans le champ ci-dessous. "))
              : _createCommentVNode("", true),
            (step2.value==1 && selectedOperator.value!==OTP_OPERATOR.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_25, _cache[17] || (_cache[17] = [
                  _createElementVNode("h2", null, "Vérification du paiement", -1)
                ])))
              : _createCommentVNode("", true),
            (step2.value==1 && selectedOperator.value!==OTP_OPERATOR.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                  (selectedOperator.value==MTN_OPERATOR.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_27, "Veuillez saisir votre code secret MTN Money pour confirmer le paiement."))
                    : _createCommentVNode("", true),
                  (selectedOperator.value==MOOV_OPERATOR.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_28, "Veuillez saisir votre code secret FLOOZ pour confirmer le paiement."))
                    : _createCommentVNode("", true),
                  (selectedOperator.value==WAVE_OPERATOR.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_29, "Vous serez redirigé dans quelques instants vers la page affichant le qrcode à scanner pour finaliser votre opération."))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (step2.value==1 && selectedOperator.value!==OTP_OPERATOR.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                  _createElementVNode("p", null, _toDisplayString((mins.value<0 && secs.value<0) ? "" : (                  
                mins.value+":"+((secs.value < 10 )? `0${secs.value}` : secs.value)               
                )), 1)
                ]))
              : _createCommentVNode("", true),
            (step2.value==1 && selectedOperator.value==OTP_OPERATOR.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                  _cache[18] || (_cache[18] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-5" }, " Saisir le code reçu par SMS ", -1)),
                  _withDirectives(_createElementVNode("input", {
                    type: "number",
                    class: _normalizeClass(["form-control shadow-none rounded-0 text-black field", { 'is-invalid': errorsBis['otp'] }]),
                    placeholder: "**************",
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((formValues.otp) = $event)),
                    onBlur: _cache[9] || (_cache[9] = ($event: any) => (validate('otp'))),
                    onKeyup: _cache[10] || (_cache[10] = ($event: any) => (validate('otp')))
                  }, null, 34), [
                    [_vModelText, formValues.otp]
                  ]),
                  _createElementVNode("span", _hoisted_32, _toDisplayString(errorsBis['otp']), 1)
                ]))
              : _createCommentVNode("", true),
            (step2.value==1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                  _createElementVNode("div", { class: "col-md-6 col-lg-6" }, [
                    _createElementVNode("button", {
                      onClick: goback,
                      class: "default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100 border border-warning",
                      style: {"background-color":"#fff","border-color":"#EB9355","border-width":"1px","border-style":"solid"}
                    }, _cache[19] || (_cache[19] = [
                      _createElementVNode("i", {
                        class: "flaticon-chevron-1 mt-2",
                        style: {"color":"#EB9355"}
                      }, null, -1),
                      _createElementVNode("span", {
                        class: "align-baseline",
                        style: {"color":"#EB9355"}
                      }, "Retour", -1)
                    ]))
                  ]),
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("button", {
                      class: "default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100",
                      type: "submit",
                      style: {"background-color":"#EB9355"},
                      disabled: !btnLoad.value
                    }, [
                      (!btnLoad.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_36))
                        : _createCommentVNode("", true),
                      _cache[20] || (_cache[20] = _createTextVNode(" Payer "))
                    ], 8, _hoisted_35)
                  ])
                ]))
              : _createCommentVNode("", true),
            (step3.value==1 && status.value=='SUCCEEDED')
              ? (_openBlock(), _createElementBlock("div", _hoisted_37, _cache[21] || (_cache[21] = [
                  _createElementVNode("h2", { class: "text-center" }, [
                    _createElementVNode("i", {
                      class: "flaticon-secure-shield",
                      style: {"color":"green"}
                    })
                  ], -1),
                  _createElementVNode("h3", null, "Votre paiement a été approuvé", -1)
                ])))
              : _createCommentVNode("", true),
            (step3.value==1 && status.value=='FAILED')
              ? (_openBlock(), _createElementBlock("div", _hoisted_38, _cache[22] || (_cache[22] = [
                  _createElementVNode("h2", { class: "text-center" }, [
                    _createElementVNode("i", {
                      class: "flaticon-cancel",
                      style: {"color":"red"}
                    })
                  ], -1),
                  _createElementVNode("h2", null, "Votre paiement a échoué", -1)
                ])))
              : _createCommentVNode("", true),
            (step3.value==1 && status.value=='PROCESSING')
              ? (_openBlock(), _createElementBlock("div", _hoisted_39, _cache[23] || (_cache[23] = [
                  _createElementVNode("h2", { class: "text-center" }, [
                    _createElementVNode("i", {
                      class: "flaticon-compare",
                      style: {"color":"blue"}
                    })
                  ], -1),
                  _createElementVNode("h2", null, "Votre paiement est en cours", -1)
                ])))
              : _createCommentVNode("", true),
            (step3.value==1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                  _createElementVNode("p", null, [
                    _cache[24] || (_cache[24] = _createElementVNode("span", { class: "text-black fw-bold" }, "Opérateur : ", -1)),
                    _createElementVNode("span", null, _toDisplayString(selectedOperator.value), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _cache[25] || (_cache[25] = _createElementVNode("span", { class: "text-black fw-bold" }, "ID Transaction : ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_unref(checkoutId)), 1)
                  ]),
                  _createElementVNode("p", null, [
                    _cache[26] || (_cache[26] = _createElementVNode("span", { class: "text-black fw-bold" }, "Date Paiement : ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_unref(formatDateFr)(new Date())), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 32)
        ])
      ])
    ])
  ]))
}
}

})