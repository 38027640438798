import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "clearfix"
}
const _hoisted_2 = {
  key: 1,
  class: "card mb-25 border-0 rounded-0 bg-white letter-spacing"
}
const _hoisted_3 = { class: "card-body p-15 p-sm-20 p-md-25" }
const _hoisted_4 = { class: "table-responsive" }
const _hoisted_5 = { class: "table text-nowrap align-middle mb-0" }
const _hoisted_6 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_7 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_8 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_9 = { class: "shadow-none lh-1 fw-medium" }
const _hoisted_10 = {
  key: 0,
  class: "badge text-outline-success"
}
const _hoisted_11 = {
  key: 1,
  class: "badge text-outline-danger"
}
const _hoisted_12 = { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }
const _hoisted_13 = { class: "dropdown" }
const _hoisted_14 = { class: "dropdown-menu" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = {
  key: 0,
  class: "pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center"
}
const _hoisted_19 = {
  key: 0,
  class: "mb-0 text-paragraph"
}
const _hoisted_20 = { class: "fw-bold" }
const _hoisted_21 = { class: "fw-bold" }
const _hoisted_22 = {
  key: 1,
  class: "mb-0 text-paragraph"
}
const _hoisted_23 = { class: "fw-bold" }
const _hoisted_24 = { class: "fw-bold" }
const _hoisted_25 = { class: "mt-15 mt-md-0" }
const _hoisted_26 = { class: "pagination mb-0" }
const _hoisted_27 = {
  key: 0,
  class: "page-item"
}
const _hoisted_28 = {
  key: 0,
  class: "page-link active",
  href: "#"
}
const _hoisted_29 = ["onClick"]
const _hoisted_30 = {
  key: 1,
  class: "page-item"
}
const _hoisted_31 = {
  class: "modal fade",
  id: "companyModal",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_32 = { class: "modal-dialog modal-lg modal-dialog-centered" }
const _hoisted_33 = { class: "modal-content" }
const _hoisted_34 = { class: "modal-body" }
const _hoisted_35 = {
  key: 0,
  style: {"margin-top":"5%"}
}
const _hoisted_36 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"red"}
}
const _hoisted_37 = {
  key: 1,
  style: {"margin-top":"5%"}
}
const _hoisted_38 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"green"}
}
const _hoisted_39 = { class: "form-group mb-15 mb-sm-20 mb-md-25" }
const _hoisted_40 = { class: "row" }
const _hoisted_41 = { class: "col-md-12" }
const _hoisted_42 = { class: "invalid-feedback" }
const _hoisted_43 = { class: "row" }
const _hoisted_44 = { class: "col-md-12" }
const _hoisted_45 = { class: "invalid-feedback" }

import {ref, onMounted,reactive,inject } from "vue";
import axios from 'axios';
import router from '../../router/index'
import { useRouter } from 'vue-router';
import * as yup from 'yup';
declare interface TypeFree {
  id : string,
  code: string,
  name: string,
  description: string, 
  status:string
  }
 
  
export default /*@__PURE__*/_defineComponent({
  __name: 'TypeFreeContent',
  setup(__props) {

const backApi=inject('back');
const page = ref(1);
const total = ref(0);
const pagesLeft = ref(0);
const currentPage = ref(0);
const numPages = ref(0);



let token: string|null = localStorage.getItem('login_token');

const errors = ref('');  
  const success = ref('');
  const errorsBis = {'name':'','description':''};
  const pageLoad = ref(false);

  const formValues = reactive({    
    description: "",  
    name: ""   
  });

  
  const schema = yup.object({    
      description: yup.string().required('La description est requise'),  
      name: yup.string().required('Le nom du pays est requis'),   
     
  });


const typefees =ref(new Array<TypeFree>());
  function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}
const getTypeFees = (arg=1) =>{
    // +arg
    axios.get(backApi+'/api/v1/secure/typefees/',{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        typefees.value = response.data;  //.data.typefees            
        
        // total.value=parseInt(response.data.pagination.total);
        // page.value=parseInt(response.data.pagination.page);
        // pagesLeft.value=parseInt(response.data.pagination.pagesLeft);
        // currentPage.value=parseInt(response.data.pagination.currentPage);
        // numPages.value=parseInt(response.data.pagination.numPages);

        total.value=10;
        page.value=1;
        pagesLeft.value=0;
        currentPage.value=1;
        numPages.value=1;
        
        // alert(total.value)
    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const changeStatus =(status,code)=>{
 // alert(code)
  axios.put(`${backApi}/api/v1/secure/typefees/${code}/status`,{
          code: code,
          status:  (status=='ACTIVE')?'INACTIVE':'ACTIVE'
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
      }       
      }).then((response) => {        
        router.go();        
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const storeTypeFree =()=>{
try {  
  schema.validateSync(formValues);
  axios.post(`${backApi}/api/v1/secure/typefees/new`,{          
          description:formValues.description,
          name:formValues.name
          
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        if(response.status === 200){      
          router.go();  
        }      
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
} catch (error) {
        console.error(error);        
         errors.value="une erreur inattendue est apparue"
      
}

// name:name.value,
// email:email.value,
// contact:contact.value,
// description:description.value
}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value=''
}


onMounted(() => { 

  getTypeFees();
   setTimeout(function () {
    pageLoad.value=true; 
         },1000)
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!pageLoad.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[8] || (_cache[8] = [
          _createElementVNode("div", {
            class: "spinner-grow text-danger",
            style: {"width":"3rem","height":"3rem","margin-top":"25vh","margin-left":"42%","margin-bottom":"5vh"},
            role: "status"
          }, [
            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (pageLoad.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[20] || (_cache[20] = _createStaticVNode("<div class=\"card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25\"><div class=\"d-sm-flex align-items-center\"></div><div class=\"d-flex align-items-center\"><button class=\"default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0\" type=\"button\" data-bs-toggle=\"modal\" data-bs-target=\"#companyModal\" style=\"background-color:#EB9355;\"> Créer un type de frais <i class=\"flaticon-plus position-relative ms-5 fs-12\"></i></button></div></div>", 1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("table", _hoisted_5, [
                _cache[11] || (_cache[11] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                    }, " Code "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                    }, " Nom "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " Description "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " Status "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                    })
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(typefees.value, (typefee) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: typefee.id
                    }, [
                      _createElementVNode("td", _hoisted_6, _toDisplayString(typefee.code), 1),
                      _createElementVNode("td", _hoisted_7, _toDisplayString(typefee.name), 1),
                      _createElementVNode("td", _hoisted_8, _toDisplayString(typefee.description), 1),
                      _createElementVNode("td", _hoisted_9, [
                        (typefee.status==='ACTIVE')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Actif"))
                          : _createCommentVNode("", true),
                        (typefee.status==='INACTIVE')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_11, "Inactif"))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("td", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _cache[10] || (_cache[10] = _createElementVNode("button", {
                            class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                            type: "button",
                            "data-bs-toggle": "dropdown",
                            "aria-expanded": "false"
                          }, [
                            _createElementVNode("i", { class: "flaticon-dots" })
                          ], -1)),
                          _createElementVNode("ul", _hoisted_14, [
                            _createElementVNode("li", null, [
                              _createElementVNode("a", {
                                class: "dropdown-item d-flex align-items-center",
                                href: "javascript:void(0);",
                                onClick: ($event: any) => (changeStatus(typefee.status,typefee.code))
                              }, [
                                _cache[9] || (_cache[9] = _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }, null, -1)),
                                (typefee.status==='ACTIVE')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_16, "Désactiver"))
                                  : _createCommentVNode("", true),
                                (typefee.status==='INACTIVE')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_17, "Activer"))
                                  : _createCommentVNode("", true)
                              ], 8, _hoisted_15)
                            ])
                          ])
                        ])
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ]),
            (total.value>0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  (total.value>10)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_19, [
                        _cache[12] || (_cache[12] = _createTextVNode(" Showing ")),
                        _createElementVNode("span", _hoisted_20, _toDisplayString(page.value*10), 1),
                        _cache[13] || (_cache[13] = _createTextVNode(" out of ")),
                        _createElementVNode("span", _hoisted_21, _toDisplayString(total.value), 1),
                        _cache[14] || (_cache[14] = _createTextVNode(" resultats "))
                      ]))
                    : _createCommentVNode("", true),
                  (total.value<=10)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_22, [
                        _cache[15] || (_cache[15] = _createTextVNode(" Showing ")),
                        _createElementVNode("span", _hoisted_23, _toDisplayString(total.value), 1),
                        _cache[16] || (_cache[16] = _createTextVNode(" out of ")),
                        _createElementVNode("span", _hoisted_24, _toDisplayString(total.value), 1),
                        _cache[17] || (_cache[17] = _createTextVNode(" resultats "))
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("nav", _hoisted_25, [
                    _createElementVNode("ul", _hoisted_26, [
                      ((page.value-1)>0)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_27, [
                            _createElementVNode("a", {
                              class: "page-link",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (getTypeFees(page.value-1))),
                              "aria-label": "Previous"
                            }, _cache[18] || (_cache[18] = [
                              _createElementVNode("i", { class: "flaticon-chevron-1" }, null, -1)
                            ]))
                          ]))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(numPages.value, (numPage) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: numPage,
                          class: "page-item"
                        }, [
                          (numPage==page.value)
                            ? (_openBlock(), _createElementBlock("a", _hoisted_28, _toDisplayString(numPage), 1))
                            : (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                class: "page-link",
                                onClick: ($event: any) => (getTypeFees(numPage))
                              }, _toDisplayString(numPage), 9, _hoisted_29))
                        ]))
                      }), 128)),
                      ((page.value+1)<=numPages.value)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_30, [
                            _createElementVNode("a", {
                              class: "page-link",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (getTypeFees(page.value+1))),
                              "aria-label": "Next"
                            }, _cache[19] || (_cache[19] = [
                              _createElementVNode("i", { class: "flaticon-chevron" }, null, -1)
                            ]))
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_31, [
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("form", {
            onSubmit: _withModifiers(storeTypeFree, ["prevent"])
          }, [
            _cache[23] || (_cache[23] = _createElementVNode("div", { class: "modal-header" }, [
              _createElementVNode("h1", { class: "modal-title fs-5" }, "Type de frais"),
              _createElementVNode("button", {
                type: "button",
                class: "btn-close",
                "data-bs-dismiss": "modal",
                "aria-label": "Close"
              })
            ], -1)),
            _createElementVNode("div", _hoisted_34, [
              (errors.value!=='')
                ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                    _createElementVNode("h6", _hoisted_36, _toDisplayString(errors.value), 1)
                  ]))
                : _createCommentVNode("", true),
              (success.value!=='')
                ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                    _createElementVNode("h6", _hoisted_38, _toDisplayString(success.value), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("div", _hoisted_40, [
                  _createElementVNode("div", _hoisted_41, [
                    _cache[21] || (_cache[21] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Nom ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formValues.name) = $event)),
                        type: "text",
                        class: _normalizeClass(["form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16", { 'is-invalid': errorsBis['name'] }]),
                        id: "lead-calendar",
                        placeholder: "Cote d'Ivoire",
                        onBlur: _cache[3] || (_cache[3] = ($event: any) => (validate('name'))),
                        onKeyup: _cache[4] || (_cache[4] = ($event: any) => (validate('name')))
                      }, null, 34), [
                        [_vModelText, formValues.name]
                      ]),
                      _createElementVNode("span", _hoisted_42, _toDisplayString(errorsBis['name']), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("div", _hoisted_44, [
                    _cache[22] || (_cache[22] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Description ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("textarea", {
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((formValues.description) = $event)),
                        type: "text",
                        class: _normalizeClass(["form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16", { 'is-invalid': errorsBis['description'] }]),
                        id: "lead-calendar",
                        placeholder: "Saisir la description de l'entreprise ici",
                        onBlur: _cache[6] || (_cache[6] = ($event: any) => (validate('description'))),
                        onKeyup: _cache[7] || (_cache[7] = ($event: any) => (validate('description')))
                      }, "                                    ", 34), [
                        [_vModelText, formValues.description]
                      ]),
                      _createElementVNode("span", _hoisted_45, _toDisplayString(errorsBis['description']), 1)
                    ])
                  ])
                ])
              ])
            ]),
            _cache[24] || (_cache[24] = _createElementVNode("div", { class: "modal-footer" }, [
              _createElementVNode("button", {
                type: "submit",
                class: "btn text-white",
                style: {"background-color":"#EB9355"}
              }, [
                _createElementVNode("i", {
                  class: "flaticon-plus position-relative ms-5 fs-12",
                  style: {"color":"#FFF"}
                }),
                _createTextVNode(" Enregister ")
              ])
            ], -1))
          ], 32)
        ])
      ])
    ])
  ], 64))
}
}

})