import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "clearfix"
}
const _hoisted_2 = {
  key: 1,
  class: "card mb-25 border-0 rounded-0 bg-white letter-spacing"
}
const _hoisted_3 = { class: "card-body p-15 p-sm-20 p-md-25" }
const _hoisted_4 = { class: "table-responsive" }
const _hoisted_5 = { class: "table text-nowrap align-middle mb-0" }
const _hoisted_6 = { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }
const _hoisted_7 = { class: "d-flex align-items-center" }
const _hoisted_8 = { class: "d-flex align-items-center ms-5 fs-md-15 fs-lg-16" }
const _hoisted_9 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_10 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_11 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_12 = { class: "shadow-none lh-1 fw-medium" }
const _hoisted_13 = {
  key: 0,
  class: "badge text-outline-success"
}
const _hoisted_14 = {
  key: 1,
  class: "badge text-outline-danger"
}
const _hoisted_15 = { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }
const _hoisted_16 = { class: "dropdown" }
const _hoisted_17 = { class: "dropdown-menu" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = {
  class: "modal fade",
  id: "companyModal",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_22 = { class: "modal-dialog modal-lg modal-dialog-centered" }
const _hoisted_23 = { class: "modal-content" }
const _hoisted_24 = { class: "modal-body" }
const _hoisted_25 = {
  key: 0,
  style: {"margin-top":"5%"}
}
const _hoisted_26 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"red"}
}
const _hoisted_27 = {
  key: 1,
  style: {"margin-top":"5%"}
}
const _hoisted_28 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"green"}
}
const _hoisted_29 = { class: "form-group mb-15 mb-sm-20 mb-md-25" }
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "col-md-6" }
const _hoisted_32 = { class: "invalid-feedback" }
const _hoisted_33 = { class: "col-md-6" }
const _hoisted_34 = { class: "invalid-feedback" }
const _hoisted_35 = { class: "row" }
const _hoisted_36 = { class: "col-md-6" }
const _hoisted_37 = { class: "invalid-feedback" }
const _hoisted_38 = { class: "col-md-6" }
const _hoisted_39 = { class: "invalid-feedback" }

import {ref, onMounted,reactive,inject } from "vue";
import axios from 'axios';
import flatpickr from "flatpickr";
import { useRoute, useRouter } from 'vue-router'
import conf from '../../../../env.json'
import router from '../../../router/index'
import * as yup from 'yup';
declare interface User {
    id: number ,
    code: string,
    name: string,
    status: string,    
    email: string,
    username: string
  }
const leadPlaceholder = "Select Lead Date";

export default /*@__PURE__*/_defineComponent({
  __name: 'UsersContentByCompany',
  setup(__props) {

const backApi=inject('back');

const errors = ref('');  
const success = ref('');
const errorsBis = {'name':'','email':'','password':'','confirm_password':''};
const pageLoad = ref(false);

  const formValues = reactive({
    name: "", 
    // role: "",
    //status: "", 
    email: "",          
    password: "",
    confirm_password: "",    
    //organization: ""
  });

  
  const schema = yup.object({    
      email: yup.string().email('l\'e-mail doit être un e-mail valide').required('L\'email est requis'),
      name: yup.string().required('Le nom complet est requis'),   
      password: yup.string().required('Le mot de passe est requis').min(6, 'Le mot de passe doit faire au moin 6 caractères'),
      confirm_password: yup.string().required('La confirmation du mot de passe est requis').min(6, 'La confirmation du mot de passe doit faire au moin 6 caractères').oneOf([yup.ref('password'), null], "La confirmation du mot de passe doit être identique au mot de passe")
  });



const users =ref(new Array<User>());
  function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}
let code:string|null='';
onMounted(() => {
  flatpickr("#lead-calendar", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    defaultDate: "today",
  });

  flatpickr("#basic-calendar", {
    dateFormat: "Y-m-d H:i",
  });
  const route = useRoute()
  code = route.params.id;
  getUsers(code);

  setTimeout(function () {
    pageLoad.value=true; 
         },1000)
  
});
const token =  localStorage.getItem('login_token');
const getUsers = (code) =>{

  axios.get(`${backApi}/api/v1/secure/users/?organization=${code}`,{
        headers: {
            Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
        // users.value = response.data;
        users.value = response.data;  //.data.users          
        // console.log(users.value);
        
        
    }).catch((error) => {
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                //window.location.href = '/';
              }   
              
          }
  });
}

const storeUser =()=>{
try {  
  schema.validateSync(formValues);
  axios.post(`${backApi}/api/v1/secure/users/new`,{
          name:formValues.name,
          role:'USER',
          status:'ACTIVE',
          email:formValues.email,
          password:formValues.password,
          organization:code
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        if(response.status === 200){      
          router.go();  
        }      
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
} catch (error) {
        console.error(error);        
         errors.value="une erreur inattendue est apparue"
      
}


}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value=''
}

const changeStatus =(status,code)=>{
  axios.post(`${backApi}/api/v1/secure/users/activate`,{
          code: code,
          status:  (status=='ACTIVE')?'INACTIVE':'ACTIVE'
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
      }       
      }).then((response) => {        
        router.go();        
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!pageLoad.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[12] || (_cache[12] = [
          _createElementVNode("div", {
            class: "spinner-grow text-danger",
            style: {"width":"3rem","height":"3rem","margin-top":"25vh","margin-left":"42%","margin-bottom":"5vh"},
            role: "status"
          }, [
            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (pageLoad.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[17] || (_cache[17] = _createStaticVNode("<div class=\"card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25\"><div class=\"d-sm-flex align-items-center\"><button class=\"default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0\" type=\"button\" data-bs-toggle=\"modal\" data-bs-target=\"#companyModal\" style=\"background-color:#EB9355;\"> Créer un utilisateur <i class=\"flaticon-plus position-relative ms-5 fs-12\"></i></button></div><div class=\"d-sm-flex align-items-center\"><div class=\"select-calendar position-relative me-sm-10 ms-sm-15 mt-10 mt-sm-0\"></div></div></div>", 1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("table", _hoisted_5, [
                _cache[16] || (_cache[16] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                    }, " ID "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " NOM "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " NOM UTILISATEUR "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " E-MAIL "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " STATUT "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                    })
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(users.value, (user) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: user.code
                    }, [
                      _createElementVNode("th", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "form-check mb-0" }, [
                            _createElementVNode("input", {
                              class: "form-check-input shadow-none",
                              type: "checkbox"
                            })
                          ], -1)),
                          _createElementVNode("div", _hoisted_8, _toDisplayString(user.code), 1)
                        ])
                      ]),
                      _createElementVNode("td", _hoisted_9, _toDisplayString(user.name), 1),
                      _createElementVNode("td", _hoisted_10, _toDisplayString(user.username), 1),
                      _createElementVNode("td", _hoisted_11, _toDisplayString(user.email), 1),
                      _createElementVNode("td", _hoisted_12, [
                        (user.status==='ACTIVE')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, "ACTIVE"))
                          : _createCommentVNode("", true),
                        (user.status==='INACTIVE')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_14, "INACTIVE"))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("td", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _cache[15] || (_cache[15] = _createElementVNode("button", {
                            class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                            type: "button",
                            "data-bs-toggle": "dropdown",
                            "aria-expanded": "false"
                          }, [
                            _createElementVNode("i", { class: "flaticon-dots" })
                          ], -1)),
                          _createElementVNode("ul", _hoisted_17, [
                            _createElementVNode("li", null, [
                              _createElementVNode("a", {
                                class: "dropdown-item d-flex align-items-center",
                                href: "javascript:void(0);",
                                onClick: ($event: any) => (changeStatus(user.status,user.code))
                              }, [
                                _cache[14] || (_cache[14] = _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }, null, -1)),
                                (user.status==='ACTIVE')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_19, "Désactiver"))
                                  : _createCommentVNode("", true),
                                (user.status==='INACTIVE')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_20, "Activer"))
                                  : _createCommentVNode("", true)
                              ], 8, _hoisted_18)
                            ])
                          ])
                        ])
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("form", {
            onSubmit: _withModifiers(storeUser, ["prevent"])
          }, [
            _cache[22] || (_cache[22] = _createElementVNode("div", { class: "modal-header" }, [
              _createElementVNode("h1", { class: "modal-title fs-5" }, "Creation d'un utilisateur"),
              _createElementVNode("button", {
                type: "button",
                class: "btn-close",
                "data-bs-dismiss": "modal",
                "aria-label": "Close"
              })
            ], -1)),
            _createElementVNode("div", _hoisted_24, [
              (errors.value!=='')
                ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                    _createElementVNode("h6", _hoisted_26, _toDisplayString(errors.value), 1)
                  ]))
                : _createCommentVNode("", true),
              (success.value!=='')
                ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                    _createElementVNode("h6", _hoisted_28, _toDisplayString(success.value), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, [
                    _cache[18] || (_cache[18] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Nom complet ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formValues.name) = $event)),
                        type: "text",
                        class: _normalizeClass(["form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16", { 'is-invalid': errorsBis['name'] }]),
                        placeholder: "ex: Ouattara Patrick",
                        onBlur: _cache[1] || (_cache[1] = ($event: any) => (validate('name'))),
                        onKeyup: _cache[2] || (_cache[2] = ($event: any) => (validate('name')))
                      }, null, 34), [
                        [_vModelText, formValues.name]
                      ]),
                      _createElementVNode("span", _hoisted_32, _toDisplayString(errorsBis['name']), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_33, [
                    _cache[19] || (_cache[19] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " E-mail ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formValues.email) = $event)),
                        type: "text",
                        class: _normalizeClass(["form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16", { 'is-invalid': errorsBis['email'] }]),
                        placeholder: "ex: info@imoney.africa",
                        onBlur: _cache[4] || (_cache[4] = ($event: any) => (validate('email'))),
                        onKeyup: _cache[5] || (_cache[5] = ($event: any) => (validate('email')))
                      }, null, 34), [
                        [_vModelText, formValues.email]
                      ]),
                      _createElementVNode("span", _hoisted_34, _toDisplayString(errorsBis['email']), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("div", _hoisted_36, [
                    _cache[20] || (_cache[20] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Mot de passe ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((formValues.password) = $event)),
                        type: "password",
                        class: _normalizeClass(["form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16", { 'is-invalid': errorsBis['password'] }]),
                        placeholder: "***********",
                        onBlur: _cache[7] || (_cache[7] = ($event: any) => (validate('password'))),
                        onKeyup: _cache[8] || (_cache[8] = ($event: any) => (validate('password')))
                      }, null, 34), [
                        [_vModelText, formValues.password]
                      ]),
                      _createElementVNode("span", _hoisted_37, _toDisplayString(errorsBis['password']), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_38, [
                    _cache[21] || (_cache[21] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Confirmation du mot de passe ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((formValues.confirm_password) = $event)),
                        type: "password",
                        class: _normalizeClass(["form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16", { 'is-invalid': errorsBis['confirm_password'] }]),
                        placeholder: "***********",
                        onBlur: _cache[10] || (_cache[10] = ($event: any) => (validate('confirm_password'))),
                        onKeyup: _cache[11] || (_cache[11] = ($event: any) => (validate('confirm_password')))
                      }, null, 34), [
                        [_vModelText, formValues.confirm_password]
                      ]),
                      _createElementVNode("span", _hoisted_39, _toDisplayString(errorsBis['confirm_password']), 1)
                    ])
                  ])
                ])
              ])
            ]),
            _cache[23] || (_cache[23] = _createElementVNode("div", { class: "modal-footer" }, [
              _createElementVNode("button", {
                type: "submit",
                class: "btn text-white",
                style: {"background-color":"#EB9355"}
              }, [
                _createElementVNode("i", {
                  class: "flaticon-plus position-relative ms-5 fs-12",
                  style: {"color":"#FFF"}
                }),
                _createTextVNode(" Enregister ")
              ])
            ], -1))
          ], 32)
        ])
      ])
    ])
  ], 64))
}
}

})