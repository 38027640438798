import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "clearfix"
}
const _hoisted_2 = {
  key: 1,
  class: "card mb-25 border-0 rounded-0 bg-white letter-spacing"
}
const _hoisted_3 = { class: "card-body p-15 p-sm-20 p-md-25" }
const _hoisted_4 = { class: "table-responsive" }
const _hoisted_5 = { class: "table text-nowrap align-middle mb-0" }
const _hoisted_6 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_7 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_8 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_9 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_10 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_11 = { class: "shadow-none lh-1 fw-medium" }
const _hoisted_12 = {
  key: 0,
  class: "badge text-outline-success"
}
const _hoisted_13 = {
  key: 1,
  class: "badge text-outline-danger"
}
const _hoisted_14 = { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }
const _hoisted_15 = { class: "dropdown" }
const _hoisted_16 = { class: "dropdown-menu" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = {
  key: 0,
  class: "pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center"
}
const _hoisted_21 = {
  key: 0,
  class: "mb-0 text-paragraph"
}
const _hoisted_22 = { class: "fw-bold" }
const _hoisted_23 = { class: "fw-bold" }
const _hoisted_24 = {
  key: 1,
  class: "mb-0 text-paragraph"
}
const _hoisted_25 = { class: "fw-bold" }
const _hoisted_26 = { class: "fw-bold" }
const _hoisted_27 = { class: "mt-15 mt-md-0" }
const _hoisted_28 = { class: "pagination mb-0" }
const _hoisted_29 = {
  key: 0,
  class: "page-item"
}
const _hoisted_30 = {
  key: 0,
  class: "page-link active",
  href: "#"
}
const _hoisted_31 = ["onClick"]
const _hoisted_32 = {
  key: 1,
  class: "page-item"
}
const _hoisted_33 = {
  class: "modal fade",
  id: "companyModal",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_34 = { class: "modal-dialog modal-lg modal-dialog-centered" }
const _hoisted_35 = { class: "modal-content" }
const _hoisted_36 = { class: "modal-body" }
const _hoisted_37 = {
  key: 0,
  style: {"margin-top":"5%"}
}
const _hoisted_38 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"red"}
}
const _hoisted_39 = {
  key: 1,
  style: {"margin-top":"5%"}
}
const _hoisted_40 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"green"}
}
const _hoisted_41 = { class: "form-group mb-15 mb-sm-20 mb-md-25" }
const _hoisted_42 = { class: "row" }
const _hoisted_43 = { class: "col-md-6" }
const _hoisted_44 = { class: "invalid-feedback" }
const _hoisted_45 = { class: "col-md-6" }
const _hoisted_46 = { class: "invalid-feedback" }
const _hoisted_47 = { class: "row" }
const _hoisted_48 = { class: "col-md-6" }
const _hoisted_49 = { class: "invalid-feedback" }
const _hoisted_50 = { class: "col-md-6" }
const _hoisted_51 = { class: "invalid-feedback" }

import {ref, onMounted,reactive,inject } from "vue";
import axios from 'axios';
import router from '../../router/index'
import { useRouter } from 'vue-router';
import * as yup from 'yup';

declare interface Country {
  id : string,
  code: string,
  name: string,
  indicative: string,    
  currency: string,
  countryCode: string,
  created_at: string,
  status:string
  }
 
  
export default /*@__PURE__*/_defineComponent({
  __name: 'CountryContent',
  setup(__props) {

const backApi=inject('back');
const page = ref(1);
const total = ref(0);
const pagesLeft = ref(0);
const currentPage = ref(0);
const numPages = ref(0);



let token: string|null = localStorage.getItem('login_token');

const errors = ref('');  
  const success = ref('');
  const errorsBis = {'name':'','code':'','indicative':'','currency':''};
  const pageLoad = ref(false);

  const formValues = reactive({
    code: "",  
    indicative: "",  
    name: "",  
    currency: ""
  });

  const phoneRegExp = /^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  //const currencyRegExp = /^((\\+[A-Z]{3}[ \\-]*))?$/;
  const codeRegExp = /^([A-Z]{2})$/;
  const currencyRegExp = /^([A-Z]{3})$/;
  const indicativeRegExp = /^([0-9]{3})$/;
  const schema = yup.object({    
      code: yup.string().required('Le code pays est requis').matches(codeRegExp,"Veuillez saisir un code pays valide").test('len', "Le code pays doit faire 2 caractères", val => val.length === 2),  
      name: yup.string().required('Le nom du pays est requis'),   
      currency: yup.string().required('La dévise est requise').matches(currencyRegExp,"Veuillez saisir une dévise valide").test('len', "La dévise doit faire 3 caractères", val => val.length === 3),  
      indicative: yup.string().required('L\'indicatif est requis').matches(indicativeRegExp,"Veuillez saisir un indicatif valide").test('len', "L'indicatif doit faire 3 chiffres'", val => val.length === 3),     
  });


const countries =ref(new Array<Country>());
  function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}
const getCountries = (arg=1) =>{
    // +arg
    axios.get(backApi+'/api/v1/secure/countries/',{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        countries.value = response.data;  //.data.countries            
        
        // total.value=parseInt(response.data.pagination.total);
        // page.value=parseInt(response.data.pagination.page);
        // pagesLeft.value=parseInt(response.data.pagination.pagesLeft);
        // currentPage.value=parseInt(response.data.pagination.currentPage);
        // numPages.value=parseInt(response.data.pagination.numPages);

        total.value=10;
        page.value=1;
        pagesLeft.value=0;
        currentPage.value=1;
        numPages.value=1;
        
        // alert(total.value)
    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const changeStatus =(status,code)=>{
  //alert(code)
  axios.put(`${backApi}/api/v1/secure/countries/${code}/status`,{
          code: code,
          status:  (status=='ACTIVE')?'INACTIVE':'ACTIVE'
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
      }       
      }).then((response) => {        
        router.go();        
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const storeCountry =()=>{
try {  
  schema.validateSync(formValues);
  axios.post(`${backApi}/api/v1/secure/countries/new`,{
          countryCode:formValues.code,
          indicative:formValues.indicative,
          name:formValues.name,
          currency:formValues.currency
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        if(response.status === 200){      
          router.go();  
        }      
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
} catch (error) {
        console.error(error);        
         errors.value="une erreur inattendue est apparue"
      
}

// name:name.value,
// email:email.value,
// contact:contact.value,
// description:description.value
}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value=''
}


onMounted(() => { 

  getCountries();
   setTimeout(function () {
    pageLoad.value=true; 
         },1000)
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!pageLoad.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[15] || (_cache[15] = [
          _createElementVNode("div", {
            class: "spinner-grow text-danger",
            style: {"width":"3rem","height":"3rem","margin-top":"25vh","margin-left":"42%","margin-bottom":"5vh"},
            role: "status"
          }, [
            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (pageLoad.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[27] || (_cache[27] = _createStaticVNode("<div class=\"card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25\"><div class=\"d-sm-flex align-items-center\"></div><div class=\"d-flex align-items-center\"><button class=\"default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0\" type=\"button\" data-bs-toggle=\"modal\" data-bs-target=\"#companyModal\" style=\"background-color:#EB9355;\"> Créer un pays <i class=\"flaticon-plus position-relative ms-5 fs-12\"></i></button></div></div>", 1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("table", _hoisted_5, [
                _cache[18] || (_cache[18] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                    }, " Code "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                    }, " Code pays "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                    }, " Nom "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " Indicatif "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " Dévise "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " STATUS "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                    })
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(countries.value, (country) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: country.id
                    }, [
                      _createElementVNode("td", _hoisted_6, _toDisplayString(country.code), 1),
                      _createElementVNode("td", _hoisted_7, _toDisplayString(country.countryCode), 1),
                      _createElementVNode("td", _hoisted_8, _toDisplayString(country.name), 1),
                      _createElementVNode("td", _hoisted_9, _toDisplayString(country.indicative), 1),
                      _createElementVNode("td", _hoisted_10, _toDisplayString(country.currency), 1),
                      _createElementVNode("td", _hoisted_11, [
                        (country.status==='ACTIVE')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_12, "Actif"))
                          : _createCommentVNode("", true),
                        (country.status==='INACTIVE')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, "Inactif"))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("td", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _cache[17] || (_cache[17] = _createElementVNode("button", {
                            class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                            type: "button",
                            "data-bs-toggle": "dropdown",
                            "aria-expanded": "false"
                          }, [
                            _createElementVNode("i", { class: "flaticon-dots" })
                          ], -1)),
                          _createElementVNode("ul", _hoisted_16, [
                            _createElementVNode("li", null, [
                              _createElementVNode("a", {
                                class: "dropdown-item d-flex align-items-center",
                                href: "javascript:void(0);",
                                onClick: ($event: any) => (changeStatus(country.status,country.code))
                              }, [
                                _cache[16] || (_cache[16] = _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }, null, -1)),
                                (country.status==='ACTIVE')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_18, "Désactiver"))
                                  : _createCommentVNode("", true),
                                (country.status==='INACTIVE')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_19, "Activer"))
                                  : _createCommentVNode("", true)
                              ], 8, _hoisted_17)
                            ])
                          ])
                        ])
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ]),
            (total.value>0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  (total.value>10)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_21, [
                        _cache[19] || (_cache[19] = _createTextVNode(" Showing ")),
                        _createElementVNode("span", _hoisted_22, _toDisplayString(page.value*10), 1),
                        _cache[20] || (_cache[20] = _createTextVNode(" out of ")),
                        _createElementVNode("span", _hoisted_23, _toDisplayString(total.value), 1),
                        _cache[21] || (_cache[21] = _createTextVNode(" resultats "))
                      ]))
                    : _createCommentVNode("", true),
                  (total.value<=10)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_24, [
                        _cache[22] || (_cache[22] = _createTextVNode(" Showing ")),
                        _createElementVNode("span", _hoisted_25, _toDisplayString(total.value), 1),
                        _cache[23] || (_cache[23] = _createTextVNode(" out of ")),
                        _createElementVNode("span", _hoisted_26, _toDisplayString(total.value), 1),
                        _cache[24] || (_cache[24] = _createTextVNode(" resultats "))
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("nav", _hoisted_27, [
                    _createElementVNode("ul", _hoisted_28, [
                      ((page.value-1)>0)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_29, [
                            _createElementVNode("a", {
                              class: "page-link",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (getCountries(page.value-1))),
                              "aria-label": "Previous"
                            }, _cache[25] || (_cache[25] = [
                              _createElementVNode("i", { class: "flaticon-chevron-1" }, null, -1)
                            ]))
                          ]))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(numPages.value, (numPage) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: numPage,
                          class: "page-item"
                        }, [
                          (numPage==page.value)
                            ? (_openBlock(), _createElementBlock("a", _hoisted_30, _toDisplayString(numPage), 1))
                            : (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                class: "page-link",
                                onClick: ($event: any) => (getCountries(numPage))
                              }, _toDisplayString(numPage), 9, _hoisted_31))
                        ]))
                      }), 128)),
                      ((page.value+1)<=numPages.value)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_32, [
                            _createElementVNode("a", {
                              class: "page-link",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (getCountries(page.value+1))),
                              "aria-label": "Next"
                            }, _cache[26] || (_cache[26] = [
                              _createElementVNode("i", { class: "flaticon-chevron" }, null, -1)
                            ]))
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_33, [
      _createElementVNode("div", _hoisted_34, [
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("form", {
            onSubmit: _withModifiers(storeCountry, ["prevent"])
          }, [
            _cache[32] || (_cache[32] = _createElementVNode("div", { class: "modal-header" }, [
              _createElementVNode("h1", { class: "modal-title fs-5" }, "Pays"),
              _createElementVNode("button", {
                type: "button",
                class: "btn-close",
                "data-bs-dismiss": "modal",
                "aria-label": "Close"
              })
            ], -1)),
            _createElementVNode("div", _hoisted_36, [
              (errors.value!=='')
                ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                    _createElementVNode("h6", _hoisted_38, _toDisplayString(errors.value), 1)
                  ]))
                : _createCommentVNode("", true),
              (success.value!=='')
                ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                    _createElementVNode("h6", _hoisted_40, _toDisplayString(success.value), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("div", _hoisted_42, [
                  _createElementVNode("div", _hoisted_43, [
                    _cache[28] || (_cache[28] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Code pays ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formValues.code) = $event)),
                        type: "text",
                        class: _normalizeClass(["form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16", { 'is-invalid': errorsBis['code'] }]),
                        id: "lead-calendar",
                        placeholder: "CI",
                        onBlur: _cache[3] || (_cache[3] = ($event: any) => (validate('code'))),
                        onKeyup: _cache[4] || (_cache[4] = ($event: any) => (validate('code')))
                      }, null, 34), [
                        [_vModelText, formValues.code]
                      ]),
                      _createElementVNode("span", _hoisted_44, _toDisplayString(errorsBis['code']), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_45, [
                    _cache[29] || (_cache[29] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Nom ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((formValues.name) = $event)),
                        type: "text",
                        class: _normalizeClass(["form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16", { 'is-invalid': errorsBis['name'] }]),
                        id: "lead-calendar",
                        placeholder: "Cote d'Ivoire",
                        onBlur: _cache[6] || (_cache[6] = ($event: any) => (validate('name'))),
                        onKeyup: _cache[7] || (_cache[7] = ($event: any) => (validate('name')))
                      }, null, 34), [
                        [_vModelText, formValues.name]
                      ]),
                      _createElementVNode("span", _hoisted_46, _toDisplayString(errorsBis['name']), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_47, [
                  _createElementVNode("div", _hoisted_48, [
                    _cache[30] || (_cache[30] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Indicatif ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((formValues.indicative) = $event)),
                        type: "text",
                        class: _normalizeClass(["form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16", { 'is-invalid': errorsBis['indicative'] }]),
                        id: "lead-calendar",
                        placeholder: "ex: 225",
                        onBlur: _cache[9] || (_cache[9] = ($event: any) => (validate('indicative'))),
                        onKeyup: _cache[10] || (_cache[10] = ($event: any) => (validate('indicative'))),
                        onChange: _cache[11] || (_cache[11] = ($event: any) => (validate('indicative')))
                      }, null, 34), [
                        [_vModelText, formValues.indicative]
                      ]),
                      _createElementVNode("span", _hoisted_49, _toDisplayString(errorsBis['indicative']), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_50, [
                    _cache[31] || (_cache[31] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Dévise ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((formValues.currency) = $event)),
                        type: "text",
                        class: _normalizeClass(["form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16", { 'is-invalid': errorsBis['currency'] }]),
                        id: "lead-calendar",
                        placeholder: "ex: XOF",
                        onBlur: _cache[13] || (_cache[13] = ($event: any) => (validate('currency'))),
                        onKeyup: _cache[14] || (_cache[14] = ($event: any) => (validate('currency')))
                      }, null, 34), [
                        [_vModelText, formValues.currency]
                      ]),
                      _createElementVNode("span", _hoisted_51, _toDisplayString(errorsBis['currency']), 1)
                    ])
                  ])
                ])
              ])
            ]),
            _cache[33] || (_cache[33] = _createElementVNode("div", { class: "modal-footer" }, [
              _createElementVNode("button", {
                type: "submit",
                class: "btn text-white",
                style: {"background-color":"#EB9355"}
              }, [
                _createElementVNode("i", {
                  class: "flaticon-plus position-relative ms-5 fs-12",
                  style: {"color":"#FFF"}
                }),
                _createTextVNode(" Enregister ")
              ])
            ], -1))
          ], 32)
        ])
      ])
    ])
  ], 64))
}
}

})