import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white sales-pos-location-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "mb-15 mb-sm-0 d-sm-flex align-items-center justify-content-between" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "card-title fw-black mb-0" }
const _hoisted_6 = {
  id: "weeklySalesChart",
  class: "chart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "fw-medium text-muted fs-13 d-block mb-5 text-uppercase" }, " COLLECTES HEBDOMADAIRE ", -1)),
          _createElementVNode("h4", _hoisted_5, "XOF " + _toDisplayString(_ctx.total), 1)
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-select mt-10 mt-sm-0 d-inline-block d-sm-flex align-items-center ps-10 pe-10 pt-5 pb-5" }, null, -1))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_apexchart, {
          type: "line",
          height: "375",
          options: _ctx.weeklySalesChart,
          series: _ctx.sales
        }, null, 8, ["options", "series"])
      ])
    ])
  ]))
}