import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../assets/images/i-money1.jpg'


const _hoisted_1 = {
  class: "logo position-absolute start-0 end-0 top-0",
  style: {"background-color":"#861727"}
}
const _hoisted_2 = { class: "sidebar-menu" }
const _hoisted_3 = {
  class: "sidebar-navbar-nav ps-0 mb-0 list-unstyled accordion",
  id: "sidebarNavAccordion"
}
const _hoisted_4 = { class: "sidebar-nav-item" }
const _hoisted_5 = { class: "sidebar-nav-item accordion-item bg-transparent border-0 rounded-0" }
const _hoisted_6 = {
  id: "sidebarCollapseOne",
  class: "accordion-collapse collapse show",
  "data-bs-parent": "#sidebarNavAccordion"
}
const _hoisted_7 = { class: "accordion-body" }
const _hoisted_8 = { class: "sidebar-sub-menu ps-0 mb-0 list-unstyled" }
const _hoisted_9 = { class: "sidebar-sub-menu-item" }
const _hoisted_10 = { class: "sidebar-sub-menu-item" }
const _hoisted_11 = {
  key: 0,
  class: "sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
}
const _hoisted_12 = {
  id: "sidebarCollapseTwo",
  class: "accordion-collapse collapse",
  "data-bs-parent": "#sidebarNavAccordion"
}
const _hoisted_13 = { class: "accordion-body" }
const _hoisted_14 = { class: "sidebar-sub-menu ps-0 mb-0 list-unstyled" }
const _hoisted_15 = { class: "sidebar-sub-menu-item" }
const _hoisted_16 = {
  key: 1,
  class: "sidebar-nav-item"
}
const _hoisted_17 = { class: "sidebar-nav-item accordion-item bg-transparent border-0 rounded-0" }
const _hoisted_18 = {
  id: "sidebarCollapseThree",
  class: "accordion-collapse collapse show",
  "data-bs-parent": "#sidebarNavAccordion"
}
const _hoisted_19 = { class: "accordion-body" }
const _hoisted_20 = { class: "sidebar-sub-menu ps-0 mb-0 list-unstyled" }
const _hoisted_21 = { class: "sidebar-sub-menu-item" }
const _hoisted_22 = { class: "sidebar-sub-menu-item" }
const _hoisted_23 = {
  key: 2,
  class: "sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
}
const _hoisted_24 = {
  id: "sidebarCollapseTwo",
  class: "accordion-collapse collapse show",
  "data-bs-parent": "#sidebarNavAccordion"
}
const _hoisted_25 = { class: "accordion-body" }
const _hoisted_26 = { class: "sidebar-sub-menu ps-0 mb-0 list-unstyled" }
const _hoisted_27 = { class: "sidebar-sub-menu-item" }
const _hoisted_28 = { class: "sidebar-sub-menu-item" }
const _hoisted_29 = { class: "sidebar-sub-menu-item" }
const _hoisted_30 = { class: "sidebar-nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.stateStoreInstance.show==true)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([
      'sidebar-area position-fixed start-0 top-0 h-100vh transition',
      { active: _ctx.stateStoreInstance.open },
    ]),
        id: "sidebar-area",
        style: {"background-color":"#861727"}
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_router_link, {
            to: "/dashboard",
            class: "d-flex align-items-center text-white text-decoration-none"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "logo-icon",
                style: {"max-width":"25%"}
              }, null, -1),
              _createElementVNode("span", { class: "fw-bold ms-10" }, "IZYPAY", -1)
            ])),
            _: 1
          }),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "border-bottom" }, null, -1)),
          _createElementVNode("button", {
            class: "sidebar-burger-menu position-absolute lh-1 bg-transparent p-0 border-0",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.stateStoreInstance.onChange && _ctx.stateStoreInstance.onChange(...args)))
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("i", { class: "ph-duotone ph-caret-double-right" }, null, -1)
          ]))
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            _createElementVNode("li", _hoisted_4, [
              _createVNode(_component_router_link, {
                to: "/dashboard",
                class: "sidebar-nav-link d-block"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createElementVNode("i", { class: "flaticon-more-1" }, null, -1),
                  _createElementVNode("span", { class: "title" }, "Tableau de bord", -1)
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_5, [
              _cache[7] || (_cache[7] = _createElementVNode("a", {
                href: "#",
                class: "accordion-button rounded-0 shadow-none bg-transparent d-block",
                "data-bs-toggle": "collapse",
                "data-bs-target": "#sidebarCollapseOne",
                "aria-expanded": "true",
                "aria-controls": "sidebarCollapseOne"
              }, [
                _createElementVNode("i", { class: "flaticon-trolley-cart" }),
                _createElementVNode("span", { class: "title" }, "Transactions")
              ], -1)),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("ul", _hoisted_8, [
                    _createElementVNode("li", _hoisted_9, [
                      _createVNode(_component_router_link, {
                        to: "/transactions",
                        class: "sidebar-sub-menu-link"
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" Paiements collectés ")
                        ])),
                        _: 1
                      })
                    ]),
                    _createElementVNode("li", _hoisted_10, [
                      _createVNode(_component_router_link, {
                        to: "/transferts",
                        class: "sidebar-sub-menu-link"
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode(" Transferts ")
                        ])),
                        _: 1
                      })
                    ])
                  ])
                ])
              ])
            ]),
            (_ctx.current_permissions('ADMIN'))
              ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
                  _cache[9] || (_cache[9] = _createElementVNode("a", {
                    href: "#",
                    class: "accordion-button collapsed rounded-0 shadow-none bg-transparent d-block",
                    "data-bs-toggle": "collapse",
                    "data-bs-target": "#sidebarCollapseTwo",
                    "aria-expanded": "false",
                    "aria-controls": "sidebarCollapseTwo"
                  }, [
                    _createElementVNode("i", { class: "flaticon-rocket" }),
                    _createElementVNode("span", { class: "title" }, "CRM")
                  ], -1)),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("ul", _hoisted_14, [
                        _createElementVNode("li", _hoisted_15, [
                          _createVNode(_component_router_link, {
                            to: "/crm-customers",
                            class: "sidebar-sub-menu-link"
                          }, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                              _createTextVNode(" Clients ")
                            ])),
                            _: 1
                          })
                        ])
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.current_permissions('ADMIN'))
              ? (_openBlock(), _createElementBlock("li", _hoisted_16, [
                  _createVNode(_component_router_link, {
                    to: `/utilisateurs/${_ctx.code}`,
                    class: "sidebar-nav-link d-block"
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createElementVNode("i", { class: "flaticon-people" }, null, -1),
                      _createElementVNode("span", { class: "title" }, "Utilisateurs", -1)
                    ])),
                    _: 1
                  }, 8, ["to"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", _hoisted_17, [
              _cache[13] || (_cache[13] = _createElementVNode("a", {
                href: "#",
                class: "accordion-button rounded-0 shadow-none bg-transparent d-block",
                "data-bs-toggle": "collapse",
                "data-bs-target": "#sidebarCollapseThree",
                "aria-expanded": "true",
                "aria-controls": "sidebarCollapseThree"
              }, [
                _createElementVNode("i", { class: "flaticon-money" }),
                _createElementVNode("span", { class: "title" }, "Finances")
              ], -1)),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("ul", _hoisted_20, [
                    _createElementVNode("li", _hoisted_21, [
                      _createVNode(_component_router_link, {
                        to: "/repayment",
                        class: "sidebar-sub-menu-link"
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode(" Réversements ")
                        ])),
                        _: 1
                      })
                    ]),
                    _createElementVNode("li", _hoisted_22, [
                      _createVNode(_component_router_link, {
                        to: "/reloading",
                        class: "sidebar-sub-menu-link"
                      }, {
                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                          _createTextVNode(" Rechargements ")
                        ])),
                        _: 1
                      })
                    ])
                  ])
                ])
              ])
            ]),
            (_ctx.current_permissions('ADMIN'))
              ? (_openBlock(), _createElementBlock("li", _hoisted_23, [
                  _cache[17] || (_cache[17] = _createElementVNode("a", {
                    href: "#",
                    class: "accordion-button rounded-0 shadow-none bg-transparent d-block",
                    "data-bs-toggle": "collapse",
                    "data-bs-target": "#sidebarCollapseTwo",
                    "aria-expanded": "true",
                    "aria-controls": "sidebarCollapseTwo"
                  }, [
                    _createElementVNode("i", { class: "flaticon-setting" }),
                    _createElementVNode("span", { class: "title" }, "Paramètres")
                  ], -1)),
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("ul", _hoisted_26, [
                        _createElementVNode("li", _hoisted_27, [
                          _createVNode(_component_router_link, {
                            to: "/country",
                            class: "sidebar-sub-menu-link"
                          }, {
                            default: _withCtx(() => _cache[14] || (_cache[14] = [
                              _createTextVNode(" Pays ")
                            ])),
                            _: 1
                          })
                        ]),
                        _createElementVNode("li", _hoisted_28, [
                          _createVNode(_component_router_link, {
                            to: "/typefree",
                            class: "sidebar-sub-menu-link"
                          }, {
                            default: _withCtx(() => _cache[15] || (_cache[15] = [
                              _createTextVNode(" Type de frais ")
                            ])),
                            _: 1
                          })
                        ]),
                        _createElementVNode("li", _hoisted_29, [
                          _createVNode(_component_router_link, {
                            to: "/fee",
                            class: "sidebar-sub-menu-link"
                          }, {
                            default: _withCtx(() => _cache[16] || (_cache[16] = [
                              _createTextVNode(" Frais ")
                            ])),
                            _: 1
                          })
                        ])
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", _hoisted_30, [
              _createVNode(_component_router_link, {
                to: "",
                onClick: _ctx.logout,
                class: "sidebar-nav-link d-block"
              }, {
                default: _withCtx(() => _cache[18] || (_cache[18] = [
                  _createElementVNode("i", { class: "flaticon-logout" }, null, -1),
                  _createElementVNode("span", { class: "title" }, "Se déconnecter", -1)
                ])),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}