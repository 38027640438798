import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-7 col-xxxl-6" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xl-12 col-lg-12 col-xxxl-12 col-md-12" }
const _hoisted_5 = { class: "col-xxl-5 col-xxxl-6" }
const _hoisted_6 = { class: "col-xxl-4" }
const _hoisted_7 = { class: "col-xxl-6" }
const _hoisted_8 = { class: "col-xxl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhatHappening = _resolveComponent("WhatHappening")!
  const _component_WebsiteVisitors = _resolveComponent("WebsiteVisitors")!
  const _component_StatsBoxes = _resolveComponent("StatsBoxes")!
  const _component_WeeklySales = _resolveComponent("WeeklySales")!
  const _component_TopSellingProducts = _resolveComponent("TopSellingProducts")!
  const _component_ProductsOrders = _resolveComponent("ProductsOrders")!
  const _component_StockReport = _resolveComponent("StockReport")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_WhatHappening),
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-lg-8 col-xxxl-8 col-md-8" }, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_WebsiteVisitors)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_StatsBoxes),
      _createVNode(_component_WeeklySales)
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-xxl-5 col-xxxl-6" }, null, -1)),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_TopSellingProducts)
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-xxl-4" }, null, -1)),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_ProductsOrders)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_StockReport)
    ])
  ]))
}