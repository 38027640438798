import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"margin-top":"5%"}
}
const _hoisted_2 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"red"}
}
const _hoisted_3 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"red"}
}
const _hoisted_4 = {
  key: 1,
  style: {"margin-top":"5%"}
}
const _hoisted_5 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"green"}
}
const _hoisted_6 = {
  key: 2,
  class: "clearfix"
}
const _hoisted_7 = {
  key: 3,
  class: "card mb-25 border-0 rounded-0 bg-white letter-spacing"
}
const _hoisted_8 = { class: "card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25" }
const _hoisted_9 = { class: "d-sm-flex align-items-center" }
const _hoisted_10 = { class: "d-sm-flex align-items-center" }
const _hoisted_11 = { class: "card-body p-15 p-sm-20 p-md-25" }
const _hoisted_12 = { class: "table-responsive" }
const _hoisted_13 = {
  id: "",
  class: "table text-nowrap align-middle mb-0"
}
const _hoisted_14 = { class: "shadow-none lh-1 fw-medium text-black-emphasis title ps-0" }
const _hoisted_15 = { class: "d-flex align-items-center" }
const _hoisted_16 = { class: "d-flex align-items-center ms-5 fs-md-15 fs-lg-16" }
const _hoisted_17 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_18 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_19 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_20 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_21 = { class: "pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center" }
const _hoisted_22 = { class: "mb-0 text-paragraph" }
const _hoisted_23 = { class: "fw-bold" }
const _hoisted_24 = { class: "mt-15 mt-md-0" }
const _hoisted_25 = { class: "pagination mb-0" }
const _hoisted_26 = { class: "page-item" }
const _hoisted_27 = { class: "fw-bold" }

import {ref, onMounted,onBeforeMount,reactive,inject} from "vue";
import * as yup from 'yup';
import axios from 'axios';
import flatpickr from "flatpickr";
import html2pdf from "html2pdf.js";
import LeadscontentPdf from "./LeadsContentPdf.vue";
import router from '../../../router/index'
import conf from '../../../../env.json'
import {current_permissions} from '../../../utils/permission';
import { read, utils } from 'xlsx';
declare interface Transaction {
    id: number,
    checkoutId: string,   
    operator: string,
    contact: string,
    amount: string,    
    transactionDate: string,
    paymentStatus: string
  }

  declare interface Organization {
  id : string,
  code: string,
  name: string,
  description: string,    
  email: string,
  created_at: string
  }

  declare interface Operator {
  // id : string,
  // code: string,
  mnoName: string,
  status: string,    
  countryCode: string  
  }

const leadPlaceholder = "Select date";

export default /*@__PURE__*/_defineComponent({
  __name: 'MassTransfertsContent',
  setup(__props) {

const backApi=inject('back');

const errors = ref('');  
const errorId = ref('');  
const success = ref('');
const errorsBis = {'contact':'','amount':''};
const formValues = reactive({    
    contact: "",
    amount:""
  });
const phoneRegExp = /^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const schema = yup.object({     
     contact: yup.string().required('Le numéro de téléphone est requis'),//.matches(phoneRegExp,"Veuillez saisir un numéro de telephone valide").test('len', "Le numero de téléphone doit faire exactement 10 chiffres", val => val.length === 10),     
     amount: yup.number().required('Le montant est requis'),
  });
let data = [];
let beginDate = ref('');
let endDate = ref('');
let today = ref('');
const selectedOrganization = ref('');
const selectedOperator = ref('');
const selectedStatus = ref('');
const selectedCountry = ref('');
const loader = ref(false);
const page = ref(1);
const total = ref(0);
//const pageServ = ref('');
const pagesLeft = ref(0);
const currentPage = ref(0);
const numPages = ref(0);
const gettran = ref(false);
const search = ref(false);
const search1 = ref(false);
const amount = ref(0);
const operator = ref('');
const paymentStatus = ref(''); 
let importArray = [];
const transferts = ref([]);
let totalAmount = 0;
const totalTransfertAmount = ref(0);
let transactionsJson = [];
// const fileUpload = ref('');

    
const transactions =ref(new Array<Transaction>());
const organizations =ref(new Array<Organization>());
const operators =ref(new Array<Operator>());
let operatorsTemp =new Array<Operator>();
const transaction =ref({} as Transaction);

  function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}

function formatDateEn(value,d=0){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate()+d;  
        day = day<10?'0'+day:day;      
        return year+'-'+month+'-'+day;
        
    }
}

function formatDateEnBis(value,d=0){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate()+d;  
        day = day<10?'0'+day:day;      
        return year+'-'+month+'-'+day;
        
    }
}

function handleOrganizationChange(event) {
      selectedOrganization.value = event.target.value;
    }

function handleOperatorChange(event) {
  selectedOperator.value = event.target.value;
  errors.value='';
  success.value='';
  errorId.value='';
}

function handleStatusChange(event) {
  selectedStatus.value = event.target.value;
}

function handleCountryChange(event) {
   selectedCountry.value = event.target.value; 
  transferts.value = [];
  importArray=[];
  transactionsJson=[];
  totalTransfertAmount.value = 0;
  totalAmount = 0;
  // let fileUpload = 
  document.getElementById("fileUpload").value="";
  //  fileUpload.val
  total.value=0;
   errors.value='';
   success.value='';
   errorId.value='';
}

function handleCountryChangeOLD(event) {
   selectedCountry.value = event.target.value;
  // operators
  var j = 0;
  var operatorArr = new Array<Operator>();
  while(j<operatorsTemp.length){    
      if(operatorsTemp[j].countryCode == event.target.value){         
          operatorArr.push(operatorsTemp[j]);
      }      
      j++;
  }
   operators.value = operatorArr;
   errors.value='';
   success.value='';
   errorId.value='';
}

function handleAmountChange(event) {    
      let withdrawAmount = event.target.value;
      if(parseFloat(withdrawAmount)<=0){
        formValues.amount=''
      }
}

function getModelExcel() {       
        window.open(`/model_payout.xls`, "_blank");
}
  
onMounted(() => {
 
  // getOrganizations();
  getOperators();
  loader.value=true; 
  setTimeout(function () {
    //loader.value=true; 
        flatpickr("#lead-calendar", {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        defaultDate: "today",
        enableTime: true,
      });
        },1000);

 

  flatpickr("#basic-calendar", {
    dateFormat: "Y-m-d H:i",
  });

});
const token =  localStorage.getItem('login_token');
const code =  localStorage.getItem('organization_code');


const getOrganizations = () =>{

    axios.get(backApi+'/api/v1/secure/organizations/',{  
      headers: {
           
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {
        // users.value = response.data;
        organizations.value = response.data;  //.data.users          
        console.log(response.data);
        
        
    }).catch((error) => {
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                //window.location.href = '/';
                //router.push('/');
              }   
              
          }
   });
  //  loader.value=true;
}
const getOperators = () =>{

axios.get(backApi+'/api/v1/public/operators/',{  //secure
  headers: {
       
        Authorization: `Bearer ${token}`
  } 
})
.then((response) => {
    // users.value = response.data;
    operatorsTemp = response.data;  //.data.users          
    console.log(response.data);
    
    
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            router.push('/')
            
          }   
          
      }
});
//  loader.value=true;
}

//[top, left, bottom, right]


const sendMoney =()=>{
if(selectedCountry.value!==''){// && selectedOperator.value!==''
try {  
  // alert(selectedOperator.value);
  axios.post(`${backApi}/api/v1/secure/transactions/init`,{    
    organization:code,    
    payoutType:2, 
    operationType:parseInt(process.env.VUE_APP_PAYOUT_OPTTYPE),    
    //countryCode:selectedCountry.value,
    //operator: selectedOperator.value,
    transactions: transactionsJson
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => { 
        selectedCountry.value ='';
        selectedOperator.value ='';
        if(response.status === 200 || response.status === 201){      
          router.go();
          success.value='Transfert réussi'; 
         
          //console.log(response);
        }      
        
    }).catch((error) => {
      if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              } 
              
          }
  });
} catch (error) {
        console.error(error);        
         errors.value="une erreur inattendue est apparue"
      
}
}else{
  errors.value="Sélectionnez le pays s'il vous plaît"
}

}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value='';
        success.value='';
}

const handleImageUpload = (event) => {
  loader.value=false;
  const file = event.target.files[0];
      const reader = new FileReader();
      transferts.value = [];
      importArray=[];
      transactionsJson=[];
      totalTransfertAmount.value = 0;
      totalAmount = 0;
      total.value=0;
      errors.value='';
      success.value='';
      errorId.value='';
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

      
        var i = 0;
        for (const row of jsonData) {
          //(row[0]!==undefined && row[0]!=='') && (row[1]!==undefined && row[1]!=='') && (row[2]!==undefined && row[2]!=='') && (row[3]!==undefined && row[3]!=='') && (row[4]!==undefined && row[4]!=='')
          if((row[0]!==undefined && row[0]!=='NOM') && (row[1]!==undefined && row[1]!=='PRENOM') && (row[2]!==undefined && row[2]!=='PHONE_NUMBER') && (row[3]!==undefined && row[3]!=='AMOUNT')  && (row[4]!==undefined && row[4]!=='PAYMENT_METHOD')){// && (row[4]!==undefined && row[4]!=='PAYMENT_METHOD')
              if((row[2].length==10||row[2].length==8) && parseInt(row[3])>0){
                const user = {
                  name: row[0], 
                  lastname: row[1], 
                  mobile: row[2],
                  amount: row[3],
                  operator: row[4],
                  // country: row[5],           
                };
                const temp ={
                  amount: row[3],
                  mobile: row[2],
                  operator: row[4],
                  countryCode:selectedCountry.value
                }
                importArray.push(user);
                transactionsJson.push(temp);
                totalAmount+= parseInt(row[3]);//(i>0)? :''
                i++;
               }else{              
               //if((row[2].length!==10 && row[2].length!==8) && parseInt(row[3])>0){
                  errors.value='Données incorrectes dans le fichier';              
                  errorId.value='Ligne '+(i+1);
               }
             
           
          }
          //else{
            //  if((row[0]==undefined || row[0]=='') || (row[1]==undefined || row[1]=='')  ||  (row[2]==undefined  ||  row[2]=='')  ||  (row[3]==undefined  ||  row[3]=='')  ||  (row[4]==undefined  ||  row[4]=='')){
            //   errors.value='Données incorrectes dans le fichier';              
            //   errorId.value='Ligne '+(i-1);
            //  }
          //}
          
        }
        // i = 0;
        total.value=i;//i-1;
        // importArray.shift();
        // transactionsJson.shift();
        transferts.value = importArray;        
        totalTransfertAmount.value = totalAmount;
        
      };
      loader.value=true;
      // setTimeout(function () {
      //   loader.value=true;
      // }, 1000) 
      reader.readAsArrayBuffer(file);
          
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (errors.value!=='')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h6", _hoisted_2, _toDisplayString(errorId.value), 1),
          _createElementVNode("h6", _hoisted_3, _toDisplayString(errors.value), 1)
        ]))
      : _createCommentVNode("", true),
    (success.value!=='')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("h6", _hoisted_5, _toDisplayString(success.value), 1)
        ]))
      : _createCommentVNode("", true),
    (!loader.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[0] || (_cache[0] = [
          _createElementVNode("div", {
            class: "spinner-grow text-danger",
            style: {"width":"3rem","height":"3rem","margin-top":"25vh","margin-left":"42%","margin-bottom":"5vh"},
            role: "status"
          }, [
            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (loader.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("select", {
                name: "country",
                id: "country",
                class: "form-select fs-md-15 text-black shadow-none",
                onChange: handleCountryChange,
                required: ""
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("option", { value: "" }, "Selectionnez un pays ", -1),
                _createElementVNode("option", { value: "SN" }, "Sénégal", -1),
                _createElementVNode("option", { value: "CI" }, " Côte d'Ivoire", -1)
              ]), 32),
              _cache[2] || (_cache[2] = _createTextVNode("       "))
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("input", {
                id: "fileUpload",
                type: "file",
                onChange: handleImageUpload
              }, null, 32),
              _createElementVNode("button", {
                class: "default-outline-btn bg-transparent position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0",
                type: "button",
                onClick: getModelExcel
              }, _cache[3] || (_cache[3] = [
                _createTextVNode(" Télécharger le fichier modèle "),
                _createElementVNode("i", { class: "flaticon-file-1 position-relative ms-5 top-2 fs-15" }, null, -1)
              ]))
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("table", _hoisted_13, [
                _cache[5] || (_cache[5] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                    }, " NOM "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " PRENOM(S) "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " TELEPHONE "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " MONTANT "),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " OPERATEUR ")
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(transferts.value, (transfert) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: transfert.mobile
                    }, [
                      _createElementVNode("th", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "form-check mb-0" }, [
                            _createElementVNode("input", {
                              class: "form-check-input shadow-none",
                              type: "checkbox"
                            })
                          ], -1)),
                          _createElementVNode("div", _hoisted_16, _toDisplayString(transfert.name), 1)
                        ])
                      ]),
                      _createElementVNode("td", _hoisted_17, _toDisplayString(transfert.lastname), 1),
                      _createElementVNode("td", _hoisted_18, _toDisplayString(transfert.mobile), 1),
                      _createElementVNode("td", _hoisted_19, _toDisplayString(transfert.amount.toLocaleString()), 1),
                      _createElementVNode("td", _hoisted_20, _toDisplayString(transfert.operator), 1)
                    ]))
                  }), 128))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("p", _hoisted_22, [
                _cache[6] || (_cache[6] = _createTextVNode(" Total: ")),
                _createElementVNode("span", _hoisted_23, _toDisplayString(total.value), 1)
              ]),
              _createElementVNode("nav", _hoisted_24, [
                _createElementVNode("ul", _hoisted_25, [
                  _createElementVNode("li", _hoisted_26, [
                    _cache[7] || (_cache[7] = _createTextVNode(" Montant Total :")),
                    _createElementVNode("span", _hoisted_27, _toDisplayString(totalTransfertAmount.value.toLocaleString()), 1),
                    _cache[8] || (_cache[8] = _createTextVNode(" XOF "))
                  ]),
                  _createElementVNode("li", { class: "page-item" }, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "d-block text-center w-100 border-0 box-shadow fw-medium text-white rounded-1 pt-12 pb-12 ps-25 pe-25",
                      style: {"background-color":"#EB9355"},
                      onClick: sendMoney
                    }, " Transferer l'argent ")
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})