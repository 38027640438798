import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "clearfix"
}
const _hoisted_2 = {
  key: 1,
  class: "card mb-25 border-0 rounded-0 bg-white letter-spacing"
}
const _hoisted_3 = { class: "card-body p-15 p-sm-20 p-md-25" }
const _hoisted_4 = { class: "table-responsive" }
const _hoisted_5 = { class: "table text-nowrap align-middle mb-0" }
const _hoisted_6 = {
  key: 0,
  scope: "col",
  class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
}
const _hoisted_7 = {
  key: 1,
  scope: "col",
  class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
}
const _hoisted_8 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_9 = {
  key: 0,
  class: "shadow-none lh-1 fw-medium text-black-emphasis"
}
const _hoisted_10 = {
  key: 1,
  class: "shadow-none lh-1 fw-medium text-black-emphasis"
}
const _hoisted_11 = {
  key: 2,
  class: "shadow-none lh-1 fw-medium text-black-emphasis"
}
const _hoisted_12 = {
  key: 3,
  class: "shadow-none lh-1 fw-medium text-black-emphasis"
}
const _hoisted_13 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_14 = {
  key: 4,
  class: "shadow-none lh-1 fw-medium text-black-emphasis"
}
const _hoisted_15 = {
  key: 5,
  class: "shadow-none lh-1 fw-medium text-black-emphasis"
}
const _hoisted_16 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_17 = { class: "shadow-none lh-1 fw-medium text-black-emphasis" }
const _hoisted_18 = { class: "shadow-none lh-1 fw-medium" }
const _hoisted_19 = {
  key: 0,
  class: "badge text-outline-info"
}
const _hoisted_20 = {
  key: 1,
  class: "badge text-outline-success"
}
const _hoisted_21 = {
  key: 2,
  class: "badge text-outline-danger"
}
const _hoisted_22 = { class: "shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0" }
const _hoisted_23 = { class: "dropdown" }
const _hoisted_24 = { class: "dropdown-menu" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { key: 1 }
const _hoisted_28 = ["onClick"]
const _hoisted_29 = {
  key: 0,
  class: "pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center"
}
const _hoisted_30 = {
  key: 0,
  class: "mb-0 text-paragraph"
}
const _hoisted_31 = { class: "fw-bold" }
const _hoisted_32 = { class: "fw-bold" }
const _hoisted_33 = {
  key: 1,
  class: "mb-0 text-paragraph"
}
const _hoisted_34 = { class: "fw-bold" }
const _hoisted_35 = { class: "fw-bold" }
const _hoisted_36 = { class: "mt-15 mt-md-0" }
const _hoisted_37 = { class: "pagination mb-0" }
const _hoisted_38 = {
  key: 0,
  class: "page-item"
}
const _hoisted_39 = {
  key: 0,
  class: "page-link active",
  href: "#"
}
const _hoisted_40 = ["onClick"]
const _hoisted_41 = {
  key: 1,
  class: "page-item"
}
const _hoisted_42 = {
  class: "modal fade",
  id: "companyModal",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_43 = { class: "modal-dialog modal-lg modal-dialog-centered" }
const _hoisted_44 = { class: "modal-content" }
const _hoisted_45 = { class: "modal-body" }
const _hoisted_46 = { style: {"margin-bottom":"5%"} }
const _hoisted_47 = {
  class: "text-black fw-bold mb-0 text-center",
  style: {"color":"red"}
}
const _hoisted_48 = {
  key: 0,
  style: {"margin-top":"5%"}
}
const _hoisted_49 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"red"}
}
const _hoisted_50 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"red"}
}
const _hoisted_51 = {
  key: 1,
  style: {"margin-top":"5%"}
}
const _hoisted_52 = {
  class: "text-red fw-bold mb-0 text-center",
  style: {"color":"green"}
}
const _hoisted_53 = { class: "form-group mb-15 mb-sm-20 mb-md-25" }
const _hoisted_54 = { class: "row" }
const _hoisted_55 = { class: "col-md-6" }
const _hoisted_56 = { class: "col-md-6" }
const _hoisted_57 = { class: "invalid-feedback" }
const _hoisted_58 = { class: "row" }
const _hoisted_59 = { class: "col-md-12" }
const _hoisted_60 = { class: "invalid-feedback" }

import {ref, onMounted,reactive,inject } from "vue";
import axios from 'axios';
import router from '../../router/index'
import { useRouter } from 'vue-router';
import * as yup from 'yup';
import { Country,Fee,Operator,Organization,TypeFree,Repayment, User } from "@/utils/Model";
import { getTypeFee } from "../../utils/services";
import {current_permissions} from '../../utils/permission';

export default /*@__PURE__*/_defineComponent({
  __name: 'RepaymentContent',
  setup(__props) {

const backApi=inject('back');
const page = ref(1);
const total = ref(0);
const pagesLeft = ref(0);
const currentPage = ref(0);
const numPages = ref(0);

const selectedOrganization = ref('');
const selectedOperator = ref('');
const selectedCountry = ref('');
const selectedType = ref('');
const selectedMode = ref('');

const loader = ref(false);

let token: string|null = localStorage.getItem('login_token');
let codeOrganization: string|null = localStorage.getItem('organization_code');
let codeUser: string|null = localStorage.getItem('code');


const countries =ref(new Array<Country>());
const repayments =ref(new Array<Repayment>());
let user = {} as User;
const operators =ref(new Array<Operator>());
  const collectBalance =ref(0);
  let balance:number = 0;
// const typefees =ref([]);
const typefees =ref(new Array<TypeFree>());
let operatorsTemp =new Array<Operator>();
let countriesTemp =new Array<Country>();
  let data = [];
 
  const errors = ref('');  
  const errorId = ref('');  
  const success = ref('');
  const errorsBis = {'amount':'','description':''};
  const pageLoad = ref(false);

  const formValues = reactive({    
    amount: "",  
    description: ""   
  });

  
  const schema = yup.object({    
      amount: yup.number().required('Le montant est requis'),  
      description: yup.string().required('La description est requise'),     
  });

  function handleAmountChange(event) {
    
      let withdrawAmount = event.target.value;
      if(parseFloat(withdrawAmount)>balance || parseFloat(withdrawAmount)<=0){
        formValues.amount=''
      }
    }

function handleOperatorChange(event) {
  selectedOperator.value = event.target.value;
}

function handleTypeChange(event) {
  selectedType.value = event.target.value;
}

function handleModeChange(event) {
  selectedMode.value = event.target.value;
}


function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}

const getRepayments = (arg=1) =>{
    // +arg
    axios.get(backApi+'/api/v1/secure/repayments/',{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        repayments.value = response.data;  //.data.repayments            
        data  = response.data;  
        // total.value=parseInt(response.data.pagination.total);
        // page.value=parseInt(response.data.pagination.page);
        // pagesLeft.value=parseInt(response.data.pagination.pagesLeft);
        // currentPage.value=parseInt(response.data.pagination.currentPage);
        // numPages.value=parseInt(response.data.pagination.numPages);

        total.value=10;
        page.value=1;
        pagesLeft.value=0;
        currentPage.value=1;
        numPages.value=1;
        
        // alert(total.value)
    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const getBalanceCollect = () =>{

axios.post(backApi+`/api/v1/secure/accounts/balance/available`,{//collect//collectBalance
          code: codeOrganization,         
        },{  
        headers: {
            
              Authorization: `Bearer ${token}`
        } 
})
.then((response) => {    
    collectBalance.value = response.data.balance;  //.data.users  availableBalance
    balance = response.data.balance;       
    console.log(response.data);
    
    
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            router.push('/')
            //window.location.href = '/';
            //router.push('/');
          }   
          
      }
});
//  loader.value=true;
}





const changeStatus =(status,code)=>{
 // alert(code)
  axios.put(`${backApi}/api/v1/secure/repayments/${code}/status`,{
          code: code,
          status:  status
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
      }       
      }).then((response) => {        
        router.go();        
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const storeRepayment =()=>{
try {  
  schema.validateSync(formValues);
  // alert(formValues)
  axios.post(`${backApi}/api/v1/secure/repayments/new`,{          
          amount:parseFloat(formValues.amount),
          description:formValues.description,
          organization:codeOrganization,//user.organization.code,         
          type:selectedType.value
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        if(response.status === 200){      
          router.go();  
        }      
        
    }).catch((error) => {
      // alert(error.response.data.errorId);
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              } 
              
          }
  });
} catch (error) {
        // console.log(error);        
         errors.value="une erreur inattendue est apparue"
         
              
}

// name:name.value,
// email:email.value,
// contact:contact.value,
// description:description.value
}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value=''
}

const exportExcel = () =>{
  
  import("../../utils/Export2Excel").then((excel)=>{
    // const data = transactions.value;
    const Header =["CODE REVERSEMENT","TYPE","MONTANT","TOTAL FRAIS","DATE","STATUT"];
    const Fields = ["code","type","amount","totalFees","createdAt","status"];//mnoName
    // console.log(data);
    const dataForExcel= formatJSon(Fields,data);
    // console.log(dataForExcel);
    excel.export_json_to_excel({     
      header:Header,
      data:dataForExcel,
      sheetName:"Feuil1",
      filename:"reversements",      
      autoWidth: true,
      bookType:"xlsx",
  });
  });
}

function formatJSon(FilterData,JsonData){
  return JsonData.map((v)=>FilterData.map(j=>{
      return v[j];
    }));
}


 onMounted(async () => { 

   getRepayments();
   getBalanceCollect();
  //  getOperators();
  //  getOrganizations();
  
   setTimeout(function () {
    pageLoad.value=true; 
         },1000)
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!pageLoad.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[7] || (_cache[7] = [
          _createElementVNode("div", {
            class: "spinner-grow text-danger",
            style: {"width":"3rem","height":"3rem","margin-top":"25vh","margin-left":"42%","margin-bottom":"5vh"},
            role: "status"
          }, [
            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (pageLoad.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", { class: "card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25" }, [
            _createElementVNode("div", { class: "d-sm-flex align-items-center" }, [
              _createElementVNode("button", {
                class: "default-outline-btn text-black bg-transparent position-relative transition fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0",
                type: "button",
                onClick: exportExcel
              }, _cache[8] || (_cache[8] = [
                _createTextVNode(" Excel "),
                _createElementVNode("i", { class: "flaticon-file-1 position-relative ms-5 top-2 fs-15" }, null, -1)
              ]))
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "d-flex align-items-center" }, [
              _createElementVNode("button", {
                class: "default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0",
                type: "button",
                "data-bs-toggle": "modal",
                "data-bs-target": "#companyModal",
                style: {"background-color":"#EB9355"}
              }, [
                _createTextVNode(" Faire une demande de réversement "),
                _createElementVNode("i", { class: "flaticon-plus position-relative ms-5 fs-12" })
              ])
            ], -1))
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("table", _hoisted_5, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _cache[10] || (_cache[10] = _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                    }, " Code ", -1)),
                    (_unref(current_permissions)('ADMIN'))
                      ? (_openBlock(), _createElementBlock("th", _hoisted_6, " Organisation "))
                      : _createCommentVNode("", true),
                    _cache[11] || (_cache[11] = _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                    }, " Type ", -1)),
                    _cache[12] || (_cache[12] = _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                    }, " initié par ", -1)),
                    (_unref(current_permissions)('ADMIN'))
                      ? (_openBlock(), _createElementBlock("th", _hoisted_7, " Validé par "))
                      : _createCommentVNode("", true),
                    _cache[13] || (_cache[13] = _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " Montant ", -1)),
                    _cache[14] || (_cache[14] = _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " Total frais ", -1)),
                    _cache[15] || (_cache[15] = _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                    }, " Status ", -1)),
                    _cache[16] || (_cache[16] = _createElementVNode("th", {
                      scope: "col",
                      class: "text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                    }, null, -1))
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(repayments.value, (repayment) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: repayment.code
                    }, [
                      _createElementVNode("td", _hoisted_8, _toDisplayString(repayment.code), 1),
                      (_unref(current_permissions)('ADMIN'))
                        ? (_openBlock(), _createElementBlock("td", _hoisted_9, _toDisplayString(repayment.organization.name), 1))
                        : _createCommentVNode("", true),
                      (repayment.type==='BANK')
                        ? (_openBlock(), _createElementBlock("td", _hoisted_10, _toDisplayString(' compte bancaire')))
                        : _createCommentVNode("", true),
                      (repayment.type==='CRYPTO')
                        ? (_openBlock(), _createElementBlock("td", _hoisted_11, _toDisplayString('Compte Criptomonaie')))
                        : _createCommentVNode("", true),
                      (repayment.type==='PAYOUT')
                        ? (_openBlock(), _createElementBlock("td", _hoisted_12, _toDisplayString('Compte pay out')))
                        : _createCommentVNode("", true),
                      _createElementVNode("td", _hoisted_13, _toDisplayString(repayment.createdBy.name), 1),
                      (_unref(current_permissions)('ADMIN') && repayment.validatedBy!==null)
                        ? (_openBlock(), _createElementBlock("td", _hoisted_14, _toDisplayString(repayment.validatedBy.name), 1))
                        : _createCommentVNode("", true),
                      (_unref(current_permissions)('ADMIN') && repayment.validatedBy===null)
                        ? (_openBlock(), _createElementBlock("td", _hoisted_15, _toDisplayString('')))
                        : _createCommentVNode("", true),
                      _createElementVNode("td", _hoisted_16, _toDisplayString(repayment.amount), 1),
                      _createElementVNode("td", _hoisted_17, _toDisplayString(repayment.totalFees), 1),
                      _createElementVNode("td", _hoisted_18, [
                        (repayment.status==='DRAFT')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_19, "En attente"))
                          : _createCommentVNode("", true),
                        (repayment.status==='VALIDATED')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_20, "Validé"))
                          : _createCommentVNode("", true),
                        (repayment.status==='REJECTED')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_21, "Rejeté"))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("td", _hoisted_22, [
                        _createElementVNode("div", _hoisted_23, [
                          _cache[19] || (_cache[19] = _createElementVNode("button", {
                            class: "dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition",
                            type: "button",
                            "data-bs-toggle": "dropdown",
                            "aria-expanded": "false"
                          }, [
                            _createElementVNode("i", { class: "flaticon-dots" })
                          ], -1)),
                          _createElementVNode("ul", _hoisted_24, [
                            (repayment.status==='DRAFT' && _unref(current_permissions)('ADMIN'))
                              ? (_openBlock(), _createElementBlock("li", _hoisted_25, [
                                  _createElementVNode("a", {
                                    class: "dropdown-item d-flex align-items-center",
                                    style: {"color":"red"},
                                    href: "javascript:void(0);",
                                    onClick: ($event: any) => (changeStatus('REJECTED',repayment.code))
                                  }, _cache[17] || (_cache[17] = [
                                    _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }, null, -1),
                                    _createElementVNode("span", { class: "" }, "Réjeté", -1)
                                  ]), 8, _hoisted_26)
                                ]))
                              : _createCommentVNode("", true),
                            (repayment.status==='DRAFT' && _unref(current_permissions)('ADMIN'))
                              ? (_openBlock(), _createElementBlock("li", _hoisted_27, [
                                  _createElementVNode("a", {
                                    class: "dropdown-item d-flex align-items-center",
                                    style: {"color":"green"},
                                    href: "javascript:void(0);",
                                    onClick: ($event: any) => (changeStatus('VALIDATED',repayment.code))
                                  }, _cache[18] || (_cache[18] = [
                                    _createElementVNode("i", { class: "flaticon-pen lh-1 me-8 position-relative top-1" }, null, -1),
                                    _createElementVNode("span", { class: "" }, "Validé", -1)
                                  ]), 8, _hoisted_28)
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ]),
            (total.value>0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                  (total.value>10)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_30, [
                        _cache[20] || (_cache[20] = _createTextVNode(" Showing ")),
                        _createElementVNode("span", _hoisted_31, _toDisplayString(page.value*10), 1),
                        _cache[21] || (_cache[21] = _createTextVNode(" out of ")),
                        _createElementVNode("span", _hoisted_32, _toDisplayString(total.value), 1),
                        _cache[22] || (_cache[22] = _createTextVNode(" resultats "))
                      ]))
                    : _createCommentVNode("", true),
                  (total.value<=10)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_33, [
                        _cache[23] || (_cache[23] = _createTextVNode(" Showing ")),
                        _createElementVNode("span", _hoisted_34, _toDisplayString(total.value), 1),
                        _cache[24] || (_cache[24] = _createTextVNode(" out of ")),
                        _createElementVNode("span", _hoisted_35, _toDisplayString(total.value), 1),
                        _cache[25] || (_cache[25] = _createTextVNode(" resultats "))
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("nav", _hoisted_36, [
                    _createElementVNode("ul", _hoisted_37, [
                      ((page.value-1)>0)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_38, [
                            _createElementVNode("a", {
                              class: "page-link",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (getRepayments(page.value-1))),
                              "aria-label": "Previous"
                            }, _cache[26] || (_cache[26] = [
                              _createElementVNode("i", { class: "flaticon-chevron-1" }, null, -1)
                            ]))
                          ]))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(numPages.value, (numPage) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: numPage,
                          class: "page-item"
                        }, [
                          (numPage==page.value)
                            ? (_openBlock(), _createElementBlock("a", _hoisted_39, _toDisplayString(numPage), 1))
                            : (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                class: "page-link",
                                onClick: ($event: any) => (getRepayments(numPage))
                              }, _toDisplayString(numPage), 9, _hoisted_40))
                        ]))
                      }), 128)),
                      ((page.value+1)<=numPages.value)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_41, [
                            _createElementVNode("a", {
                              class: "page-link",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (getRepayments(page.value+1))),
                              "aria-label": "Next"
                            }, _cache[27] || (_cache[27] = [
                              _createElementVNode("i", { class: "flaticon-chevron" }, null, -1)
                            ]))
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_42, [
      _createElementVNode("div", _hoisted_43, [
        _createElementVNode("div", _hoisted_44, [
          _createElementVNode("form", {
            onSubmit: _withModifiers(storeRepayment, ["prevent"])
          }, [
            _cache[33] || (_cache[33] = _createElementVNode("div", { class: "modal-header" }, [
              _createElementVNode("h1", { class: "modal-title fs-5" }, "Demande de réversement"),
              _createElementVNode("button", {
                type: "button",
                class: "btn-close",
                "data-bs-dismiss": "modal",
                "aria-label": "Close"
              })
            ], -1)),
            _createElementVNode("div", _hoisted_45, [
              _createElementVNode("div", _hoisted_46, [
                _cache[28] || (_cache[28] = _createElementVNode("h6", { class: "text-black fw-bold mb-0 text-center" }, _toDisplayString('SOLDE'), -1)),
                _createElementVNode("h6", _hoisted_47, _toDisplayString(collectBalance.value?.toLocaleString()) + " XOF", 1)
              ]),
              (errors.value!=='')
                ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                    _createElementVNode("h6", _hoisted_49, _toDisplayString(errorId.value), 1),
                    _createElementVNode("h6", _hoisted_50, _toDisplayString(errors.value), 1)
                  ]))
                : _createCommentVNode("", true),
              (success.value!=='')
                ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                    _createElementVNode("h6", _hoisted_52, _toDisplayString(success.value), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_53, [
                _createElementVNode("div", _hoisted_54, [
                  _createElementVNode("div", _hoisted_55, [
                    _cache[30] || (_cache[30] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Type de réversement ", -1)),
                    _createElementVNode("select", {
                      name: "mode",
                      id: "mode",
                      class: "form-select fs-md-15 text-black shadow-none",
                      onChange: handleTypeChange,
                      required: ""
                    }, _cache[29] || (_cache[29] = [
                      _createElementVNode("option", { value: "" }, "Selectionnez...", -1),
                      _createElementVNode("option", { value: "BANK" }, "Compte bancaire", -1),
                      _createElementVNode("option", { value: "CRYPTO" }, "Compte Cryptomonnaie", -1),
                      _createElementVNode("option", { value: "PAYOUT" }, " Compte pay out", -1)
                    ]), 32)
                  ]),
                  _createElementVNode("div", _hoisted_56, [
                    _cache[31] || (_cache[31] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Montant ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        onKeyup: handleAmountChange,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formValues.amount) = $event)),
                        type: "number",
                        class: _normalizeClass(["form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16", { 'is-invalid': errorsBis['amount'] }]),
                        id: "lead-calendar",
                        placeholder: "2",
                        onBlur: _cache[3] || (_cache[3] = ($event: any) => (validate('amount')))
                      }, null, 34), [
                        [_vModelText, formValues.amount]
                      ]),
                      _createElementVNode("span", _hoisted_57, _toDisplayString(errorsBis['amount']), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_58, [
                  _createElementVNode("div", _hoisted_59, [
                    _cache[32] || (_cache[32] = _createElementVNode("label", { class: "d-block text-black fw-semibold mb-10" }, " Description ", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("textarea", {
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((formValues.description) = $event)),
                        type: "text",
                        class: _normalizeClass(["form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16", { 'is-invalid': errorsBis['description'] }]),
                        id: "lead-calendar",
                        placeholder: "Saisir la description de l'entreprise ici",
                        onBlur: _cache[5] || (_cache[5] = ($event: any) => (validate('description'))),
                        onKeyup: _cache[6] || (_cache[6] = ($event: any) => (validate('description')))
                      }, "                                          ", 34), [
                        [_vModelText, formValues.description]
                      ]),
                      _createElementVNode("span", _hoisted_60, _toDisplayString(errorsBis['description']), 1)
                    ])
                  ])
                ])
              ])
            ]),
            _cache[34] || (_cache[34] = _createElementVNode("div", { class: "modal-footer" }, [
              _createElementVNode("button", {
                type: "submit",
                class: "btn text-white",
                style: {"background-color":"#EB9355"}
              }, [
                _createElementVNode("i", {
                  class: "flaticon-plus position-relative ms-5 fs-12",
                  style: {"color":"#FFF"}
                }),
                _createTextVNode(" Enregister ")
              ])
            ], -1))
          ], 32)
        ])
      ])
    ])
  ], 64))
}
}

})