import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white to-do-list-box" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "mb-15 mb-md-25 d-sm-flex align-items-center justify-content-between" }
const _hoisted_4 = { class: "to-do-list style-two ps-0 list-unstyled mb-0" }
const _hoisted_5 = ["id"]
const _hoisted_6 = ["for"]
const _hoisted_7 = { class: "d-block text-muted mt-5 ms-30" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("h5", { class: "card-title fw-bold mb-0" }, "To Do List", -1)),
        _createVNode(_component_router_link, {
          to: "/to-do-list",
          class: "card-link-btn text-decoration-none text-primary fw-medium position-relative d-inline-block mt-10 mt-sm-0"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Add A New Task ")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lists, (list) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "to-do-list-item d-flex align-items-center justify-content-between",
            key: list.id
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("div", {
                class: _normalizeClass([
                'form-check mb-0 fs-md-15 fs-lg-16 text-black fw-medium',
                list.class,
              ])
              }, [
                _createElementVNode("input", {
                  class: "form-check-input shadow-none",
                  type: "checkbox",
                  id: 'task1' + list.id
                }, null, 8, _hoisted_5),
                _createElementVNode("label", {
                  class: "form-check-label",
                  for: 'task' + list.id
                }, _toDisplayString(list.title), 9, _hoisted_6)
              ], 2),
              _createElementVNode("span", _hoisted_7, _toDisplayString(list.shortText), 1)
            ]),
            _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"dropdown\"><button class=\"dropdown-toggle position-relative top-2 lh-1 bg-transparent border-0 shadow-none p-0 transition\" type=\"button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\"><i class=\"flaticon-dots\"></i></button><ul class=\"dropdown-menu\"><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-view lh-1 me-8\"></i> View </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-pen lh-1 me-8\"></i> Edit </a></li><li><a class=\"dropdown-item d-flex align-items-center\" href=\"javascript:void(0);\"><i class=\"flaticon-delete lh-1 me-8\"></i> Delete </a></li></ul></div>", 1))
          ]))
        }), 128))
      ])
    ])
  ]))
}