import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "title position-relative mb-8 mb-md-10" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "mb-0 text-black fw-bold" }
const _hoisted_6 = { class: "fs-md-15 fs-lg-16 text-black-emphasis mb-15 fw-medium" }
const _hoisted_7 = { class: "list ps-0 mb-0 list-unstyled" }
const _hoisted_8 = { class: "d-inline-block bg-white fs-13 text-muted fw-medium" }
const _hoisted_9 = { class: "fw-bold text-primary" }
const _hoisted_10 = { class: "d-inline-block bg-white fs-13 text-muted fw-medium" }
const _hoisted_11 = { class: "fw-bold text-primary" }
const _hoisted_12 = { class: "d-inline-block bg-white fs-13 text-muted fw-medium" }
const _hoisted_13 = { class: "fw-bold text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courses, (course) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-lg-6",
        key: course.id
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([
          'card mb-25 border-0 rounded-0 letter-spacing courses-list-box',
          course.bgClass,
        ])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: _normalizeClass([
                'icon text-center rounded-circle position-relative',
                course.bgClassImage,
              ])
              }, [
                _createElementVNode("img", {
                  src: require(`../../../../assets/images/${course.image}`),
                  class: "position-absolute start-0 end-0 ms-auto me-auto",
                  alt: "icon"
                }, null, 8, _hoisted_4)
              ], 2),
              _createElementVNode("h6", _hoisted_5, _toDisplayString(course.title), 1)
            ]),
            _createElementVNode("p", _hoisted_6, _toDisplayString(course.shortDesc), 1),
            _createElementVNode("ul", _hoisted_7, [
              _createElementVNode("li", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(course.lessons), 1),
                _cache[0] || (_cache[0] = _createTextVNode(" Lessons "))
              ]),
              _createElementVNode("li", _hoisted_10, [
                _createElementVNode("span", _hoisted_11, _toDisplayString(course.students), 1),
                _cache[1] || (_cache[1] = _createTextVNode(" Students "))
              ]),
              _createElementVNode("li", _hoisted_12, [
                _createElementVNode("span", _hoisted_13, _toDisplayString(course.mins), 1),
                _cache[2] || (_cache[2] = _createTextVNode(" Mins "))
              ])
            ])
          ]),
          _createVNode(_component_router_link, {
            to: "/course-details",
            class: "link-btn d-block text-decoration-none text-primary fw-medium position-relative"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("span", { class: "d-inline-block position-relative" }, "View Details", -1),
              _createElementVNode("i", { class: "ph ph-arrow-right" }, null, -1)
            ])),
            _: 1
          })
        ], 2)
      ]))
    }), 128))
  ]))
}