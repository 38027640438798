import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "footer-area text-center text-md-start bg-white ps-15 pe-15 pt-20 pb-20 ps-md-40 pe-md-40 pt-md-25 pb-md-25"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex-grow-1" }, null, -1)),
    ($setup.stateStoreInstance.show==true)
      ? (_openBlock(), _createElementBlock("footer", _hoisted_1, _cache[0] || (_cache[0] = [
          _createStaticVNode("<div class=\"row align-items-center\"><div class=\"col-md-6 col-lg-6\"><p class=\"text-body-secondary fw-medium mb-0\"> © <span class=\"fw-bold text-primary\">Izypay</span>. Tous droits réservés à <a href=\"https://imoney.africa/\" class=\"text-decoration-none fw-bold transition\" target=\"_blank\">I-MONEY</a></p></div><div class=\"col-md-6 col-lg-6 text-md-end\"><ul class=\"footer-menu ps-0 mb-0 list-unstyled fw-medium\"></ul></div></div>", 1)
        ])))
      : _createCommentVNode("", true)
  ], 64))
}